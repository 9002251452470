@charset "UTF-8";

$baseColor : #04003b;

.main_topImg{

    margin-bottom : -1px;
    position      : relative;


    img{
        width : 100%;
    }


    ._big{

        @include break-point(640){

            letter-spacing : 3vw;
            font-size      : 20vw;

        }

    }


    p{

        @include break-point(640){

            font-size : 4.5vw;

        }

    }

}

.main_topImg_text{

    width      : 100%;
    text-align : center;
    position   : absolute;
    top        : 50%;
    transform  : translateY(-50%);


    ._big{

        color          : #fff;
        letter-spacing : 1.8vw;
        font-family    : "Renner*", sans-serif;
        font-size      : 9.4vw;
        font-weight    : bold;
        line-height    : 1;

    }


    p{

        color     : #fff;
        font-size : 1.9vw;

    }


    span{

        font-size      : 2.4rem;
        margin-top     : 37px;
        letter-spacing : 4px;

        display        : block;

        @include break-point(640){

            margin-top : 28px;
            font-size  : 3.7vw;

        }

    }

}

.main_img{

    margin-bottom : -1px;
    position      : relative;

    @include break-point(640){

        display : none;

    }


    img{
        width : 100%;
    }

}

.main_img_text{

    width       : 100%;
    text-align  : center;
    position    : absolute;
    top         : 50%;
    transform   : translateY(-50%);
    font-family : "Open Sans", sans-serif;


    ._big{

        color       : #fff;
        font-size   : 4.2vw;
        font-weight : 800;
        font-style  : italic;

        margin      : 0;

        @include break-point(640){

            font-size : 10.47vw;

        }

    }


    h1{

        color       : #fff;
        font-size   : 1.1vw;
        font-weight : bold;

        @include break-point(640){

            font-size : 3.73vw;

        }

    }

}


/*  グローバルメニュー- */

#header-inner{
    display : inline-block;
}

#header-nav{

    height          : 100px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    margin          : 0 auto;
    padding         : 0 30px;

    @include break-point(1200){

        padding : 0 20px;

    }

    @include break-point(1000){

        display : none;

    }


    #logo{

        width    : 450px;
        position : relative;

        @include break-point(1000){

            height        : 65px;
            width         : 240px;
            border-bottom : none;

        }


        a{
            width             : 100%;
            display           : -webkit-box;
            display           : flex;
            -webkit-box-pack  : start;
            justify-content   : flex-start;
            -webkit-box-align : center;
            align-items       : center;


            &:visited{
                color : #ffffff;
            }


            img{

                z-index : 1;
                width   : 100%;

                @include break-point(640){

                    width       : 100%;
                    padding-top : 15px;

                }

            }

        }


        & + ul{

            display     : flex;
            align-items : center;
            text-align  : center;

            @include break-point(1000){

                display : none;

            }


            & > li{

                display         : flex;
                align-items     : center;
                justify-content : center;

                height          : 100px;
                transition      : all 0.3s ease;

                position        : relative;


                &:hover{

                    background-color : #000;


                    ul{

                        display : block;

                    }

                }

            }

        }


        img{

            @include break-point(1000){

                width          : 30%;
                display        : block;
                margin         : 0 auto;
                padding-top    : 20px;
                padding-bottom : 0;
                position       : relative;
                top            : 0;
                left           : 0;

            }

        }

    }


    a{

        color : #fff;

    }


    li{

        text-align : center;
        cursor     : pointer;

        font-size  : 1vw;

        width      : 110px;

        @include break-point(1200){

            width : 100px;

        }


        &:hover{

            cursor : pointer;

        }


    }


    .menu-item-has-children{

        position : relative;


        ul{

            display : none;
            height  : 100%;

        }

    }


    .subtitle{

        font-size : 0.55vw;
        display   : inline-block;
        width     : 100%;
        color     : #fff;

    }


    .sub-menu{

        padding  : 0;

        position : absolute;
        bottom   : -100px;
        left     : 0;


        a{

            color     : $baseColor;
            font-size : 1.2rem;

            display   : block;
            padding   : 10px 5px;


            &:hover{

                text-decoration : none;

            }

        }


        li{

            background-color : #fff;
            border           : 1px solid #f1f1f1;
            border-top       : none;


            &:hover{

                border-bottom : 1px solid $baseColor;

            }

        }


        //display : none;

    }

}

.single-product #menu-item-900{
    border-bottom : 3px solid #041442;
}

#menu-item-1453{
    display : none !important;
}

.page-glossary_eng{
    #menu-item-100, #menu-item-832{
        border-bottom : 3px solid #041442;
    }
}

.icon_before{
    color              : #fff;
    -webkit-transition : 0.3s;
    transition         : 0.3s;


    &:hover{
        color : #041442;
    }
}

/*  ナビ検索バー */
.searchform{

    position : relative;

}

.searchfield{

    font-size        : 1em;
    width            : 175px;
    height           : 30px;
    border           : 0px;
    border-radius    : 15px;
    background-color : #A3A3A3;
    padding          : 5px 10px;
    outline          : none;
    color            : #fff;
    box-shadow       : none;

    @include break-point(1000){

        font-size        : 1.6rem;
        width            : 100%;
        height           : auto;
        border           : 0px;
        border-radius    : 15px;
        background-color : #A3A3A3;

    }


    &::placeholder{

        color : #fff;

    }

}

.searchsubmit{

    color       : #fff;
    font-family : FontAwesome;
    font-size   : 1.3em;

    width       : 30px;
    height      : 30px;

    padding     : 0;

    border      : none;
    background  : transparent;
    position    : absolute;
    top         : 0px;
    right       : 3px;
    left        : auto;
    bottom      : 0;

    cursor      : pointer;
    box-shadow  : none;

    @include break-point(1000){

        right : 18px;

    }


    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder{

        color        : #fff;
        padding-left : 0px;
        font-size    : 0.9em;

    }


    &:hover{
        opacity : .6;
    }

}

#search{

    h1{
        color      : #041442;
        padding    : 40px;
        text-align : center;
    }


    .search_not{

        margin-bottom : 70px;


        h1{
            color          : #041442;
            margin-top     : 40px;
            padding-bottom : 60px;
            text-align     : center;

            @include break-point(1000){

                color      : #F37321;
                margin-top : 0px;
                width      : 100%;
                padding    : 60px 0;
                text-align : center;
                font-size  : 1.8rem;

            }

        }


        .txt01{

            text-align    : center;
            margin-bottom : 40px;

        }
    }


    .product_list{

        margin-bottom : 100px;

        @include break-point(1000){


        }

    }


    .btn03{
        width : 350px;
    }

}

/*============================================================

下層ページナビ

============================================================*/

.about_nav,
.support_nav,
.product_nav,
.solution_nav{

    width            : 100%;
    background-color : #5D5D5D;

}

.about_nav ul, .support_nav ul, .product_nav ul{
    display      : table;
    table-layout : fixed;
    text-align   : center;
    margin       : 0 auto;
}

.solution_nav ul{
    display      : table;
    table-layout : fixed;
    text-align   : center;
    width        : 900px;
    margin       : 0 auto;
}

.product_nav_inner{
    text-align  : center;
    line-height : 0px;
}

.product_nav ul{
    width   : 680px;
    display : inline-table;
}

.product_nav_searchbox{
    display        : inline-table;
    width          : 220px;
    height         : 65px;
    text-align     : right;
    font-size      : 1.3rem;
    color          : #fff;
    vertical-align : top;
    padding-top    : 18px;
}

.solution_nav .current-menu-item{
    border-bottom : 3px solid #5D5D5D;


    &:hover{
        border-bottom : 3px solid #FFA500;
    }
}

.about_nav ul li,
.support_nav ul li,
.product_nav ul li,
.solution_nav ul li{
    display            : table-cell;
    vertical-align     : middle;
    text-align         : center;
    width              : 225px;
    height             : 65px;
    font-size          : 1.4rem;
    color              : #fff;
    font-weight        : bold;
    line-height        : 2rem;
    overflow           : hidden;
    -webkit-transition : 0.3s;
    transition         : 0.3s;
}

.support_nav ul li{
    width : 440px;
}

.product_nav ul li{
    width         : 110px;
    border-bottom : 3px solid #5D5D5D;


    &.current-menu-item, &:hover{
        border-bottom : 3px solid #FFA500;
    }
}

.about_nav ul li:hover,
.support_nav ul li:hover,
.product_nav ul li:hover,
.solution_nav ul li:hover{
    background-color : #464646;
}

.about_nav ul li a, .support_nav ul li a, .product_nav ul li a, .solution_nav ul li a{
    display     : block;
    color       : #fff;
    line-height : 65px;
}

.about_nav .current_page_item, .support_nav .current_page_item, .product_nav .current_page_item, .solution_nav .current_page_item, .about_nav .current_page_item:hover, .support_nav .current_page_item:hover, .product_nav .current_page_item:hover{
    border-bottom : 3px solid #FFA500;
    background    : #484848;
}

.solution_nav{
    .current_page_item:hover, .current_menu_item:hover{
        border-bottom : 3px solid #041442;
    }
}

.tax-video_surveillance_category{
    #menu-item-385, #menu-item-402{
        border-bottom : 3px solid #041442;
    }
}

.tax-peripherals_category{
    #menu-item-385, #menu-item-401{
        border-bottom : 3px solid #041442;
    }
}

.tax-accessory_category{
    #menu-item-385, #menu-item-400{
        border-bottom : 3px solid #041442;
    }
}

.tax-consumer_category{
    #menu-item-399, #menu-item-385{
        border-bottom : 3px solid #041442;
    }
}

.tax-video_surveillance_category #menu-item-900, .tax-peripherals_category #menu-item-900, .tax-accessory_category #menu-item-900, .tax-consumer_category #menu-item-900, .current_camera #menu-item-799, .current_video #menu-item-402, .current_storage_device #menu-item-403, .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399{
    border-bottom : 3px solid #041442;
}

.camera{
    #menu-item-799, #menu-item-900{
        border-bottom : 3px solid #041442;
    }
}

.tax-storage_device_category{
    #menu-item-900, #menu-item-403{
        border-bottom : 3px solid #041442;
    }
}

.search #menu-item-900{
    border-bottom : 3px solid #041442;
}

/*============================================================

ソート共通部分

============================================================*/

.select_area{

    display          : flex;
    align-items      : center;

    width            : 100%;
    height           : 80px;

    background-color : #f0f0f0;


    a{
        color : #505050;
    }


    ul{

        text-align : center;
        width      : 100%;
        position   : relative;

    }


    li{

        font-size     : 1.5rem;
        font-weight   : bold;

        display       : inline-block;
        padding-right : 30px;
        margin-right  : 30px;

        border-right  : 1px solid;


        &:nth-last-child(1){

            padding-right : 0;
            margin-right  : 0;
            border-right  : none;

        }

    }


    .inactive{
        background-color : #f0f0f0;
        padding          : 8px 10px;
        display          : inline-block;
        border-radius    : 15px;
    }


    .active{

        background-color : #fff;
        padding          : 8px 20px;
        border-radius    : 20px;
        display          : inline-block;

    }


    .all{

        background-color : #fff !important;
        padding          : 8px 10px;
        border-radius    : 15px;
        display          : inline-block;

    }


    #archive_video &,
    #archive_product &,
    #archive_accessory &,
    #archive_peripherals &{

        margin-bottom : 60px;

    }

}

.select_area_inner{
    margin : 0 auto;
}

#archive_case{

    .select_area ul li:not(:first-child),
    #single_case .select_area ul li:not(:first-child),
    #archive_video .select_area ul li:not(:first-child),
    #archive_product .select_area ul li:not(:first-child),
    #archive_accessory .select_area ul li:not(:first-child),
    #archive_peripherals .select_area ul li:not(:first-child){
        background-color : #f0f0f0;
        padding          : 8px 10px;
        display          : inline-block;
        border-radius    : 15px;
    }


    .select_area ul .current-cat,
    #single_case .select_area ul .current-cat,
    #archive_video .select_area ul .current-cat,
    #archive_product .select_area ul .current-cat,
    #archive_peripherals .select_area ul .current-cat,
    #archive_accessory .select_area ul .current-cat{
        background-color : #fff !important;
    }


    .select_area ul li:first-child a,
    #single_case .select_area ul li:first-child a,
    #archive_video .select_area ul li:first-child a,
    #archive_product .select_area ul li:first-child a,
    #archive_peripherals .select_area ul li:first-child a,
    #archive_accessory .select_area ul li:first-child a{
        background-color : #f0f0f0;
        padding          : 8px 10px;
        display          : inline-block;
        border-radius    : 15px;
    }


}

#archive_video .select_area_inner li:nth-of-type(2),
#archive_peripherals .select_area_inner li:nth-of-type(2),
.tax-video_surveillance_category .select_area_inner li:nth-of-type(2),
.tax-peripherals_category .select_area_inner li:nth-of-type(2),
.tax-accessory_category .select_area_inner li:nth-of-type(2),
.tax-consumer_category .select_area_inner li:nth-of-type(2){
    display : none !important;
}

#tax1 li a:hover,
#tax2 li a:hover,
#tax3 li a:hover,
#tax4 li a:hover{
    color : #041442;
}

.camera_nav{
    font-size : 0;
}

#tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a{
    font-size   : 1.3rem;
    color       : #505050;
    line-height : 2.5rem;
}

#tax1{
    display        : inline-block;
    width          : 286px;
    vertical-align : top;
    margin-right   : 17px;
}

#tax2{
    display        : inline-block;
    width          : 130px;
    vertical-align : top;
    margin-right   : 27px;
}

#tax3{
    display        : inline-block;
    vertical-align : top;
    width          : 286px;
    margin-right   : 17px;
}

#tax4{
    display        : inline-block;
    width          : 130px;
    vertical-align : top;
}

#tax1 li,
#tax3 li{
    display        : inline-block;
    width          : 50%;
    vertical-align : top;
}

.camera_nav li .current{
    color : #041442 !important;
}

.sp_net_typewrap, .analog_wrap, .net_wrap{
    display : inline-block;
}

.select_strage{
    width            : 100%;
    background-color : #f0f0f0;
    margin-bottom    : 60px;
    padding          : 30px 0;
}

.strage_title{
    font-size     : 0;
    margin-bottom : 20px;


    li:nth-of-type(3){
        margin-right : 0px;
    }
}

.strage_title02{
    font-size : 0;


    li:nth-of-type(3){
        margin-right : 0px;
    }
}

.strage_list_wrap{
    font-size : 0;


    a:hover{
        color : #041442;
    }
}

.nvr_wrap,
.dvr_wrap{

    display        : inline-block;
    width          : 280px;
    vertical-align : top;
    margin-right   : 30px;
    font-size      : 0;

}

.mobile_wrap{
    display        : inline-block;
    width          : 280px;
    vertical-align : top;
    margin-right   : 30px;
    font-size      : 0;
    margin-right   : 0;
}

.nvr_wrap li,
.dvr_wrap li,
.mobile_wrap li{
    width   : 50%;
    display : inline-block;
}

.nvr_wrap li a,
.dvr_wrap li a,
.mobile_wrap li a{
    font-size   : 1.2rem;
    color       : #505050;
    line-height : 2.5rem;
}

.strage_list_wrap .current-cat a{
    color : #041442;
}

/*============================================================

TOPページ

============================================================*/
/*新着ニュース------------------------------------------------*/

.top_news{

    display          : flex;
    justify-content  : center;

    width            : 100%;
    background-color : #393939;
    padding          : 25px 0;

    @include break-point(640){

        display : block;
        padding : 20px 15px;

    }


    article{

        display       : block;
        margin-bottom : 10px;


        &:nth-last-child(1){

            margin-bottom : 0;

        }

    }


    h2{

        color      : #fff;
        font-size  : 1.6rem;
        text-align : right;

        margin     : 0;

    }


    h3{

        font-size   : 1.5rem;
        font-weight : normal;
        display     : inline-block;

        margin      : 0;


        a{

            color : #fff;


            &:hover{

                text-decoration : underline;

            }

        }

    }


    time{

        color        : #fff;
        font-size    : 1.5rem;

        display      : inline-block;
        margin-right : 20px;

        @include break-point(640){

            width        : 80px;
            margin-right : 10px;

        }

    }


    &_left{

        width        : 200px;
        margin-right : 30px;

        @include break-point(640){

            width           : 100%;
            display         : flex;
            justify-content : space-between;

            padding-bottom  : 12px;
            margin-bottom   : 12px;

            border-bottom   : 1px solid #6f6e6e;

        }


        p{

            text-align : right;
            margin     : 10px 0 0 0;

            @include break-point(640){

                margin : 0;

            }


            a{

                color     : #fff;
                font-size : 1.3rem;
                display   : inline-block;


                &:hover{
                    text-decoration : underline;
                }

            }

        }

    }


    &_right{

        width : 600px;

        @include break-point(1000){

            flex  : 1;
            width : 100%;

        }

        @include break-point(640){

            display : block;

        }

    }

}

/*製品一覧-------------------------------------------*/

#top{


    .slick-list{

        @include break-point(414){

            width : 330px;

        }

        @include break-point(360){

            width : 230px;

        }

    }

}

.tab_product{

    width           : 100%;
    height          : 80px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    @include break-point(640){

        height     : 40px;
        margin-top : 5px;

    }


    li{

        cursor          : pointer;
        width           : 49%;
        height          : 80px;
        font-size       : 2.7rem;
        font-weight     : bold;
        display         : flex;
        justify-content : center;
        align-items     : center;
        color           : #464646;
        border-bottom   : 4px solid #464646;

        @include break-point(768){

            height    : 60px;
            font-size : 2.1rem;

        }

        @include break-point(640){

            height              : 50px;
            font-size           : 1.5rem;
            border-bottom-width : 1px;

        }


        &.ChangeElem_Btn.is-active{

            background-color : #f3f3f3;

        }


        &.tabActive{

            background : #f0f0f0;

        }

    }

}

.is-show{

    display : flex !important;

    @include break-point(640){

        display : block !important;

    }

}

.product_box{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
}

.product_list{

    h2{

        @include break-point(414){

            font-size     : 1.2rem;
            margin-bottom : 10px;

        }

    }


    li span{

        @include break-point(414){

            padding : 10px;

        }

    }

}

#product h3{
    font-size : 2.2rem;
    color     : #041442;
}

/*ソリューション----------------------------------------------*/

.top_solution_wrap{
    background : #f0f0f0;
}

.solution_box{

    display         : flex;
    align-items     : stretch;
    flex-wrap       : wrap;
    justify-content : center;


    &_meta{

        padding : 20px;

    }

}

.solution_box_item{

    display          : flex;
    flex-direction   : column;
    background-color : #ffffff;
    width            : 48%;
    position         : relative;
    z-index          : 1;


    &:nth-of-type(1){
        margin-right : 2%;
    }


    &:nth-of-type(2){
        margin-left : 2%;
    }


    img{
        width : 100%;
    }


    .txt01{

        color  : #464646;
        margin : 0;

    }


    .txt02{

        color       : #505050;
        font-size   : 1.3rem;
        line-height : 1.6;

        margin      : 10px 0 0 0;

        @include break-point(640){

            color       : #777;
            //padding     : 7px 15px 16px 15px;
            line-height : 1.7;

        }

    }


    a{

        transition : all 3s 0s ease;


        &:hover{

            background-color : #FFF;
            filter           : alpha(opacity=50);
            opacity          : 0.5;

        }

    }

}

/*お問い合わせ------------------------------------------------*/

#contact{

    input,
    textarea{

        padding       : 6px;
        border-radius : 0.5rem;
        width         : 100%;
        border        : 1px solid #aaa;

    }


    input{

        &::placeholder{

            color : #bbb;

        }


        &[type="radio"],
        &[type="checkbox"]{

            width : auto;

        }

    }


    textarea{

        height : 100px;

    }


    .form__row-left,
    .form__row-right{

        font-size : 1.5rem;

    }


    .form__row-left{

        width : 220px;
        
        @include break-point(640){

            width         : 100%;
            margin-bottom : 6px;

        }

    }


    .form__row-right{

        flex : 1;

    }


    .formtitle{

        display     : flex;
        align-items : center;


        p + &{

            display : none;

        }

    }


    .wpcf7-form__row{

        display       : flex;
        margin-bottom : 12px;

        @include break-point(640){

            display       : block;
            margin-bottom : 20px;

        }

    }


    .formprivacy{

        clear      : both;
        text-align : center;
        font-size  : 1.4rem;

        @include break-point(768){

            font-size : 1.2rem;

        }

    }


    .privasylink{

        font-size  : 1.4rem;
        text-align : center;
        margin     : 12px 0 30px;

    }


    input[type="submit"]{

        position         : relative;
        display          : -webkit-box;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        border-radius    : 5px;
        border           : none;
        box-shadow       : 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color : $btnColor;
        color            : #fff;
        margin           : 2rem auto;
        transition       : all 0.3s ease;
        outline          : none;
        width            : 30%;
        height           : 3.7vw;
        font-size        : 1.145vw;

        @include break-point(768){

            width     : 80%;
            height    : 5rem;
            font-size : 1.5rem;

        }

    }


    .wpcf7-list-item{

        @include break-point(1000){

            height : 0rem;
            width  : 100%;

        }

        @include break-point(640){

            display : inline-block;
            margin  : 15px 0 15px 6px;

        }

    }


    .form_company,
    .name, .address{
        border        : 1px solid #C8C8C8;
        border-radius : 5px;
        height        : 3rem;
        width         : 50%;
        font-size     : 1.4rem;
        padding       : 5px;
    }


    .phone_number{
        border        : 1px solid #C8C8C8;
        border-radius : 5px;
        height        : 3rem;
        width         : 10%;
        font-size     : 1.4rem;
        padding       : 5px;
    }


    .form_area{
        tr{
            width         : 100%;
            font-size     : 1.6rem;
            display       : block;
            margin-bottom : 20px;
        }


        th{
            width          : 200px;
            text-align     : left;
            font-weight    : normal;
            vertical-align : top;
            color          : #505050;
        }


        td{
            width : 650px;
        }


        .required{
            color            : #fff;
            background-color : #F37321;
            padding          : 3px 10px;
            border-radius    : 3px;
            font-size        : 1rem;
            vertical-align   : 2px;
            margin-right     : 5px;
        }


        label{
            display       : inline-block;
            margin-bottom : 10px;
        }
    }


    .content{
        border        : 1px solid #C8C8C8;
        border-radius : 5px;
        height        : 20rem;
        width         : 50%;
        font-size     : 1.4rem;
        padding       : 5px;
    }


    .phone .tell{

        font-size   : 5rem;
        color       : #505050;
        font-weight : bold;
        text-align  : center;
        margin      : 40px 0 20px;

        @include break-point(640){

            margin : 40px 0 10px;

        }

    }


    .phone,
    .form_area{
        padding : 60px 0;
    }


    .phone .txt01{

        margin-bottom : 20px;

    }


    .txt01{

        font-size   : 1.6rem;
        line-height : 1.6;
        text-align  : center;
        margin      : 0;

        @include break-point(640){

            font-size : 1.4rem;

        }


        &:nth-of-type(1){

            @include break-point(1000){

                margin-bottom : 15px;

            }

        }

    }


    .inner02,
    .inner03{

        padding : 50px 0 40px;

        @include break-point(1000){

            padding : 30px 0 40px;

        }

    }

}

.top_contact{

    p{

        color      : #464646;
        font-size  : 1.7rem;
        text-align : center;

        margin     : 40px 0;

        @include break-point(640){

            margin : 0 0 35px;

        }

    }

}

.top_contact_wrap{

    padding : 50px 0 80px 0;

    @include break-point(640){

        padding : 40px 0 40px 0;

    }

}


.headstyle01{

    &_inner{

        margin-bottom : 60px;
        padding       : 0 0 10px 0;
        border-bottom : 3px #041442 solid;

        @include break-point(640){

            display       : inline-block;
            font-size     : 2.4rem;
            margin-bottom : 30px;
            padding       : 0 0 10px 0;

        }


        .top_contact_wrap &,
        .history &{

            margin-bottom : 0;

            @include break-point(640){

                margin-bottom : 30px;

            }

        }


        #contact &{

            margin-bottom : 40px;

            @include break-point(640){

                margin-bottom : 30px;

            }

        }

    }

}


/*============================================================

product

============================================================*/
/*共通-------------------------------------------------------*/

.notpost{
    font-size   : 2.4rem;
    font-weight : bold;
    margin      : 100px 0;
}

#archive_camera .notpost{
    margin : 40px 0 100px;
}

.product_select_wrap{

    width            : 100%;
    background-color : #f0f0f0;
    padding          : 30px 0;

}

.product_select_inner{

    width  : 900px;
    margin : 0 auto;


    .product_2ndnavi{

        display         : flex;
        align-items     : stretch;
        flex-wrap       : wrap;
        justify-content : space-between;
        margin-bottom   : 20px;

    }

}

.product_2ndnavi{

    .two_item{

        display        : flex;
        flex-direction : column;
        color          : #fff;
        width          : 440px;

    }


    .three_item{

        display        : flex;
        flex-direction : column;
        color          : #fff;
        width          : 285px;

    }


    .two_item span,
    .three_item span{

        display          : inline-block;
        text-align       : center;
        font-size        : 1.4rem;
        flex-direction   : column;
        background-color : #fff;
        border-radius    : 25px;
        line-height      : 1;
        font-weight      : bold;
        color            : #505050;
        width            : 100%;
        border           : solid 1px #F0F0F0;
        padding          : 14px 10px;

    }

}

.camera_select_area02{
    .left_area{
        width : 440px;
        float : left;
    }


    .right_area{
        width : 440px;
        float : right;
    }
}

.left_area_inner ul{
    &:nth-of-type(1){
        width        : 160px;
        display      : inline-block;
        margin-right : 20px;
    }


    &:nth-of-type(2){
        width          : 70px;
        display        : inline-block;
        margin-right   : 70px;
        vertical-align : top;
    }


    &:nth-of-type(3){
        width          : 100px;
        display        : inline-block;
        vertical-align : top;
    }
}

.right_area_inner ul{
    &:nth-of-type(1){
        width        : 160px;
        display      : inline-block;
        margin-right : 20px;
    }


    &:nth-of-type(2){
        width          : 100px;
        display        : inline-block;
        margin-right   : 20px;
        vertical-align : top;
    }


    &:nth-of-type(3){
        width          : 100px;
        display        : inline-block;
        vertical-align : top;
    }
}

.left_area_inner ul li a, .right_area_inner ul li a{
    font-size   : 1.4rem;
    color       : #505050;
    line-height : 2.5rem;
}

.left_area_inner ul li a:hover, .right_area_inner ul li a:hover{
    color : #041442;
}

.subtitle_selectarea{
    margin-bottom : 10px;


    li{
        display        : inline-block;
        font-size      : 1.4rem;
        font-weight    : bold;
        color          : #505050;
        border-bottom  : 1px solid #A3A3A3;
        padding-bottom : 5px;
        position       : relative;


        span{
            position  : absolute;
            right     : 0;
            top       : 0;
            font-size : 1.2rem;


            a{
                color     : #A3A3A3;
                right     : 0;
                top       : 0px;
                font-size : 1.2rem;
                color     : #A3A3A3;


                &:hover{
                    color : #041442;
                }
            }
        }


        &:nth-of-type(1){
            display      : inline-block;
            width        : 286px;
            margin-right : 15px;
        }


        &:nth-of-type(2){
            display : inline-block;
            width   : 130px;
        }
    }
}

.product_list{

    display         : flex;
    justify-content : space-between;
    flex-wrap       : wrap;

    margin          : 60px 0 30px;

    @include break-point(1000){

        margin : 40px 0 0;

    }


    li{

        width            : 215px;
        height           : auto;
        background-color : #fff;
        position         : relative;
        border-radius    : 5px;
        transition       : 0.3s;

        @include break-point(640){

            width  : 48%;
            height : auto;

        }


        span{
            display            : block;
            position           : absolute;
            top                : 0;
            left               : 0;
            width              : 100%;
            height             : 100%;
            text-align         : center;
            box-sizing         : border-box;
            -moz-box-sizing    : border-box;
            -webkit-box-sizing : border-box;
            -o-box-sizing      : border-box;
            -ms-box-sizing     : border-box;
        }


        a{

            &:hover{

                background-color : #FFF;
                filter           : alpha(opacity=50);
                opacity          : 0.5;

            }

        }


        .thumb_inner{
            height            : 60%;
            display           : -webkit-box;
            display           : flex;
            -webkit-box-pack  : center;
            justify-content   : center;
            -webkit-box-align : center;
            align-items       : center;
        }

    }


    &::before{
        content                   : "";
        display                   : block;
        width                     : 210px;
        -webkit-box-ordinal-group : 2;
        order                     : 1;
    }


    &::after{
        content : "";
        display : block;
        width   : 210px;
    }


    img{
        text-align : center;
        display    : block;
    }


    h2{
        color         : #707070;
        font-size     : 1.3rem;
        text-align    : center;
        margin-bottom : 10px;
        margin-top    : 20px;
    }


    #archive_consumer &{

        margin-top : 60px;

    }

}

.archive_subtitle01{
    color      : #a7a7a7;
    text-align : center;
    font-size  : 1.1rem;
}

.product_new{
    position                   : absolute;
    top                        : 0px;
    left                       : 0px;
    height                     : 25px;
    width                      : 40px;
    text-align                 : center;
    background-color           : #041442;
    color                      : #ffffff;
    padding-top                : 5px;
    font-size                  : 1em;
    border-top-left-radius     : 4px;
    border-bottom-right-radius : 4px;
}

/*ここから製品詳細ページ--------------------------------------*/
/*============================================================

SOLUTION

============================================================*/

.product_detail{

    margin-top    : 60px;
    margin-bottom : 60px;


    h1, h3{

        color  : $baseColor;
        margin : 0;

    }


    h1{

        font-size     : 3rem;
        border-left   : 10px solid $baseColor;

        margin-bottom : 30px;
        padding       : 7px 10px 7px 16px;

        @include break-point(1000){

            margin-bottom : 0;

        }

    }


    h3{

        font-size     : 2rem;
        border-left   : 9px solid $baseColor;

        margin-bottom : 20px;
        padding       : 7px 10px 7px 12px;

        @include break-point(640){

            margin-bottom : 12px;

        }

    }


    table{

        width      : 100%;
        text-align : left;

    }


    tr{

        border-top : 1px solid #e4e4e4;

    }


    th, td{

        padding   : 20px 15px;
        font-size : 1.5em;

        @include break-point(640){

            font-size : 0.9em;
            padding   : 12px;

        }

    }


    th{

        background-color : #ebebeb;

    }


    td{

        background-color : #fff;

    }


    p{

        clear       : both;
        font-size   : 1.2rem;
        margin      : 12px 0;
        line-height : 1.4rem;

    }


    img{

        width  : 100%;
        margin : 0;

    }


    dl, ul, ol{

        font-size : 1.6rem;

    }


    dt{

        font-weight : 600;

    }


    &_row{

        margin-bottom : 40px;

        @include break-point(640){

            margin-bottom : 30px;

        }

    }


    &_left{

        @include break-point(1000){

            width      : 60%;
            text-align : center;
            float      : none;
            margin     : 0 auto;

        }

    }


    &_right{

        @include break-point(1000){

            width      : 100%;
            text-align : center;
            float      : none;
            margin-top : 30px;

        }

    }


    .main{

        img{

            @include break-point(1000){

                width : 100%;

            }

        }


        .item2,
        .item3,
        .item4{

            @include break-point(1000){

                display : none;

            }

        }
    }


    .catalog,
    .btn01{

        width     : 320px;
        font-size : 1.145vw;
        padding   : 20px 10px 19px;

    }


    .catalog{

        background-color : #F5F5F5;
        margin           : 0 auto 20px;
        position         : relative;

        border-radius    : 5px;
        border           : none;
        box-shadow       : 0 3px 6px rgba(0, 0, 0, 0.16);
        color            : #555;
        font-weight      : bold;

        display          : flex;
        align-items      : center;
        justify-content  : center;

    }


    .table-left,
    .table-right{

        width : 45%;
        float : left;

    }


    .table-left{

        margin-right : 5%;

    }


    .top_slider_dots{

        display         : flex !important;
        justify-content : center;
        margin-top      : 25px;

        @include break-point(640){

            display : none !important;

        }


        button{

            font-size        : 0;
            width            : 20px;
            height           : 20px;
            border-radius    : 10px;
            border           : none;
            background-color : #aaa;


            &:focus{

                outline : 0;

            }

        }


        li{

            margin-right : 10px;

        }

    }


    .slick-active{

        button{

            background-color : $accentColor;

        }

    }


    .btn-area{

        margin-top : 90px;

        @include break-point(640){

            margin : 40px 0;

        }

    }


}


/*選ばれる理由------------------------------------------------*/

#reason{
    .headstyle03{
        font-size     : 2.8rem;
        text-align    : left;
        margin-bottom : 10px;
    }


    h3{
        margin-top    : 20px;
        margin-bottom : 20px;
        font-size     : 1.8rem;
        color         : #041442;
    }


    .txt01{
        margin-bottom : 40px;
    }


    .block01{
        margin-bottom : 80px;
    }


    .block02{
        margin-bottom : 60px;
    }
}

.reason_box{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    margin-bottom     : 20px;
}

.reason_box_item{
    display               : -webkit-box;
    display               : flex;
    -webkit-box-orient    : vertical;
    -webkit-box-direction : normal;
    flex-direction        : column;
    margin-bottom         : 18px;
    color                 : #fff;
    width                 : 100px;
    margin-bottom         : 40px;


    img{
        margin-bottom : 10px;
    }


    span{
        color       : #041442;
        font-weight : bold;
        font-size   : 1.6rem;
        text-align  : center;
    }
}

/*製品情報一覧-----------------------------*/

.top_product{

    @include break-point(640){

        &.inner{

            width : 100%;

        }

    }

}

.top_product_wrap{

    @include break-point(640){

        overflow    : hidden;
        padding-top : 40px;

    }


    .btn01{

        margin-top : 40px;

    }

}

.page_product_box{

    display         : none;
    align-items     : stretch;
    flex-wrap       : wrap;
    justify-content : space-between;
    margin-top      : 40px;

    @include break-point(1000){

        margin-bottom : 0px;

    }


    &.pageActive{
        display : -webkit-box;
        display : flex;
    }


    &_meta{

        padding : 14px 20px 20px;


        .txt01{

            @include break-point(640){

                margin : 0;

            }

        }


        .txt02{

            @include break-point(640){

                margin : 6px 0 0px;

            }

        }

    }


    #product &{

        margin-bottom : 40px;

    }


    &_item{

        background-color : #f5f5f5;
        width            : 32%;
        margin-bottom    : 20px;
        position         : relative;


        img{

            width : 100%;

            @include break-point(640){

                object-fit : cover;
                height     : 120px;

            }

        }


        a{

            &:hover{

                background-color : #FFF;
                filter           : alpha(opacity=50);
                opacity          : 0.5;

            }

        }

    }


    .txt01{

        color       : #464646;
        font-size   : 1.9rem;
        line-height : 1.5;
        margin      : 0 0 10px 0;

    }


    .txt02{

        color     : #505050;
        font-size : 1.3rem;

        margin    : 0;

    }


    &::after{
        content : "";
        display : block;
        width   : 32%;
    }


}

.top_product_pc{
    display : block;
}

.top_product_sp{
    display : none;
}

#x_series,
#p_series,
#q_series,
#t_series,
#for_home{
    margin-top  : -120px;
    padding-top : 120px;
}

#product{
    .eye_catch{
        display       : block;
        width         : 900px;
        margin        : 0 auto;
        margin-bottom : 80px;
    }


    h3{
        color          : #041442;
        font-weight    : bold;
        vertical-align : top;
        text-align     : left;
        margin-bottom  : 20px;
    }


    h4{
        font-size     : 2rem;
        color         : #707070;
        font-weight   : bold;
        text-align    : left;
        margin-bottom : 10px;
    }
}

.series_detail p{
    font-size   : 1.6rem;
    color       : #505050;
    text-align  : justify;
    line-height : 3rem;
}

.series_block00{
    margin-bottom : 40px;
}

.series_block01,
.series_block03,
.series_block05,
.series_block06{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    margin-bottom     : 60px;
}

#t_series .series_block01{
    margin-bottom : 0;
}

.series_block05, .series_block06{
    margin-bottom : 20px;
}

.series_block01_item, .series_block05_item{
    width                 : 47%;
    display               : -webkit-box;
    display               : flex;
    -webkit-box-orient    : vertical;
    -webkit-box-direction : normal;
    flex-direction        : column;
}

.series_block01_item p, .series_block05_item p{
    margin-bottom : 60px;
}

.series_block03_item p{
    margin-bottom : 20px;
}

.under_img, .img_wrap{
    margin-top    : auto;
    margin-bottom : 0px !important;
}

.series_block06_item{
    width : 30%;
}

.series_block03_item{
    &:nth-of-type(1){
        width                 : 25%;
        display               : -webkit-box;
        display               : flex;
        -webkit-box-orient    : vertical;
        -webkit-box-direction : normal;
        flex-direction        : column;
    }


    &:nth-of-type(2){
        width                 : 70%;
        display               : -webkit-box;
        display               : flex;
        -webkit-box-orient    : vertical;
        -webkit-box-direction : normal;
        flex-direction        : column;
    }
}

.series_block02{
    font-size     : 0;
    margin-bottom : 60px;


    .lb{
        display      : inline-block;
        width        : 35%;
        margin-right : 5%;
        font-size    : 1.8rem;
    }


    .rb{
        display        : inline-block;
        width          : 60%;
        vertical-align : top;


        img{
            padding-top : 5px;
        }
    }
}

.series_block04{
    font-size : 0;


    .lb{
        display      : inline-block;
        width        : 28%;
        margin-right : 5%;
        font-size    : 1.8rem;
    }


    .rb{
        display        : inline-block;
        width          : 67%;
        vertical-align : top;


        img{
            padding-top : 5px;
        }
    }
}

.detail_link{
    padding : 60px 0 80px 0;
}

#company .detail_link{
    padding : 40px 0 120px 0;
}

#t_series .detail_link{
    margin-bottom : 100px;
}

.img100{
    width         : 100%;
    margin-bottom : 40px;
}

/*プロジェクト事例---------------------------------------------*/

#archive_case{
    h1{
        margin-top    : 40px;
        margin-bottom : 60px;
    }


    h2{
        color         : #041442;
        font-size     : 2.2rem;
        margin-bottom : 5px;


        a{
            color     : #041442;
            font-size : 2.2rem;
        }
    }
}

.project_list li{
    margin-bottom : 20px;


    img{
        width   : 240px;
        height  : 180px;
        display : inline-block;
    }
}

.case_item{
    overflow         : hidden;
    background-color : #f0f0f0;
}

.case_lb{
    width : 240px;
    float : left;


    img{
        -webkit-transition : 0.3s;
        transition         : 0.3s;


        &:hover{
            opacity : 0.8;
        }
    }
}

.case_rb{
    width   : 660px;
    float   : right;
    height  : 180px;
    padding : 20px;


    a:hover{
        text-decoration : underline;
    }
}

.case_term{
    display       : inline-block;
    margin-bottom : 10px;
    margin-right  : 10px;


    a{
        color     : #707070;
        font-size : 1.6rem;
    }
}

.case_place{
    display       : inline-block;
    margin-bottom : 10px;
    margin-right  : 10px;
    color         : #707070;
    font-size     : 1.6rem;
}

.case_time{
    display       : inline-block;
    margin-bottom : 10px;
    color         : #707070;
    font-size     : 1.6rem;
}

.read_txt{
    font-size : 1.8rem;
}

#single_case{
    h1{
        font-size     : 2.6rem;
        color         : #041442;
        margin-top    : 40px;
        margin-bottom : 15px;
    }


    .main{
        display       : block;
        width         : 100%;
        margin-bottom : 20px;


        .item2, .item3, .item4{
            display : none;
        }


        img{
            width      : 100%;
            max-width  : 100%;
            max-height : auto;
        }
    }


    .thumb{
        width             : 100%;
        display           : -webkit-box;
        display           : flex;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        margin-bottom     : 60px;


        li{
            width              : 23.5%;
            cursor             : pointer;
            -webkit-transition : 0.3s;
            transition         : 0.3s;
            margin-right       : 2%;


            &:nth-of-type(4){
                margin-right : 0%;
            }


            &:hover{
                opacity : 0.7;
            }
        }
    }


    .read_txt{
        margin-bottom : 80px;
    }


    .detail{
        margin-bottom : 40px;


        h2{
            font-size     : 2.4rem;
            color         : #041442;
            margin-bottom : 40px;
        }


        p{
            font-size     : 1.8rem;
            margin-bottom : 80px;
        }
    }


    .delivery_products_wrap{
        background-color : #f0f0f0;
        padding          : 80px 0;
        padding-bottom   : 120px;
    }
}

.delivery_products_wrap h2, .download_wrap h2, #single_product .movie_wrap h2{
    font-size     : 3rem;
    text-align    : center;
    color         : #041442;
    margin-bottom : 60px;
}

/*カルーセル---------------------------------------------*/

.slider{
    max-width    : 900px;
    margin-left  : auto;
    margin-right : auto;
    display      : none;


    &.slick-initialized{
        display : block;
    }


    img{
        width   : 100%;
        height  : auto;
        height  : 120px;
        width   : auto;
        margin  : 0 auto;
        display : block;
    }
}

.relation_p{
    text-align  : center;
    padding     : 20px 0;
    color       : #707070;
    font-size   : 1.4rem;
    font-weight : bold;
}

.slider{
    .slick-slide a{
        display          : block;
        margin           : 0 10px;
        background-color : #fff;
        padding          : 20px;
        height           : 217px;
    }


    .slick-prev,
    .slick-next{

        z-index          : 1;
        width            : 32px;
        height           : 214px;
        background-color : #E5E5E5;
        color            : white;
        transition       : all 0.3s ease;

    }


    .slick-prev:hover,
    .slick-next:hover{
        background-color : #9a9a9a;
    }


    .slick-prev:before{
        font-size : 30px;
        color     : #fff;
    }


    .slick-next{
        right : -32px;


        &:before{
            font-size : 30px;
            color     : #fff;
        }
    }


    .slick-prev{
        left : -32px;


        &::before{
            content          : '';

            /*何も入れない*/
            display          : inline-block;
            width            : 15px;

            /*画像の幅*/
            height           : 75px;

            /*画像の高さ*/
            background-image : url(img/arrow_right.svg);
            background-size  : contain;
            vertical-align   : middle;
            transform        : scale(-1, 1);
        }
    }


    .slick-next:before{
        content          : '';

        /*何も入れない*/
        display          : inline-block;
        width            : 15px;

        /*画像の幅*/
        height           : 75px;

        /*画像の高さ*/
        background-image : url(img/arrow_right.svg);
        background-size  : contain;
        vertical-align   : middle;
    }
}

#single_case .slick-dots{
    bottom : -45px !important;
}

/*============================================================

ニュース

============================================================*/
/*一覧ページ--------------------------------------------------*/

#archive_news{

    margin-bottom : 80px;


    h1{

        color      : $baseColor;
        font-size  : 3.1rem;
        text-align : center;

        margin     : 60px 0 40px;


        &.headstyle01_inner{

            @include break-point(1000){

                margin  : 50px 0;
                padding : 0 0 10px 0;

            }

        }

    }


    h2{

        margin-top    : 5px;
        margin-bottom : 0;

        @include break-point(1000){

            margin-top : 7px;

        }


        a{

            color       : #555;
            line-height : 1.6;

            @include break-point(1000){

                color : #B16D30;

            }

        }

    }


    article{

        border-top : 1px #F0F0F0 solid;
        padding    : 12px 0;


        &:nth-last-child(1){

            border-bottom : 1px #F0F0F0 solid;

        }

    }


    .news_type_text{

        font-size      : 1.8rem;
        font-weight    : bold;
        padding-bottom : 10px;
        margin         : 0;
        color          : #505050;

    }


    time,
    .news_term{

        display : inline-block;
        margin  : 0;

    }


    time{

        font-size    : 1.4rem;
        margin-right : 6px;

    }


    .news_term{

        a{

            color : #555;

        }

    }

}

.wp-pagenavi{

    text-align : center;
    margin     : 60px 0 80px;
    transition : all 0.3s ease;
    position   : relative;


    a, span{

        text-decoration : none;
        border          : none;
        border-radius   : 7px;
        padding         : 8px 10px;
        margin          : 2px;
        font-size       : 1.6rem;
        color           : $baseColor;
        transition      : all 0.3s ease;

    }


    a:hover,
    span.current{
        background-color : #041442;
        color            : #fff;
    }


    .first, .last, .nextpostslink, .previouspostslink{
        color              : #505050;
        background-color   : #fff;
        -webkit-transition : all 0.3s ease;
        transition         : all 0.3s ease;
    }


    .first{
        position : absolute;
        left     : 0;
        bottom   : -10px;
    }


    .last{
        position : absolute;
        right    : 0;
        bottom   : -10px;
    }


    .first:hover, .last:hover, .nextpostslink:hover, .previouspostslink:hover{
        color            : #041442;
        background-color : #fff;
    }

}

/*投稿ページ--------------------------------------------------*/

#single_news{

    h1{

        font-size : 2.4rem;
        margin    : 0 0 30px 0;

    }


    h2{

        font-size : 2.1rem;

    }


    h1, h2{

        margin : 0 0 20px 0;

    }


    article{

        margin : 60px 0;

    }


    time{

        display    : inline-block;
        font-size  : 1.8rem;
        text-align : center;

    }


    p{

        font-size   : 1.6rem;
        font-weight : normal;
        line-height : 1.75;

        margin      : 0 0 30px 0;

    }


    .news_term{

        font-size : 1.2rem;
        margin    : 0 0 30px 0;


        a{

            color : #505050;


            &:hover{
                text-decoration : underline;
            }

        }

    }


    .btn02{

        margin-bottom : 90px;

        @include break-point(1000){

            margin-bottom : 60px;

        }

    }


    .another_link p{
        font-size       : 1.8rem;
        text-decoration : underline;
        margin-bottom   : 10px;
        margin          : 0;


        &:last-child{
            margin-bottom : 30px;
        }


        a{
            color              : #505050;
            -webkit-transition : all 0.3s ease;
            transition         : all 0.3s ease;


            &:hover{
                color : #041442;
            }
        }
    }

}

/*============================================================

SUPPORTページ

============================================================*/
/*サポート---------------------------------------------------*/

#support{

    .headstyle01_inner{

        margin-bottom : 60px;

    }

}

.support_box{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    margin-bottom     : 60px;
}

.support_box_item{
    display               : -webkit-box;
    display               : flex;
    -webkit-box-orient    : vertical;
    -webkit-box-direction : normal;
    flex-direction        : column;
    color                 : #fff;
    background-color      : #f0f0f0;
    width                 : 48%;
    margin-bottom         : 40px;
    padding-bottom        : 20px;


    h2{
        width            : 100%;
        text-align       : center;
        display          : block;
        color            : #fff;
        font-size        : 3rem;
        font-weight      : bold;
        height           : 160px;
        line-height      : 158px;
        margin-bottom    : 20px;
        background-image : url("../img/support/support/support1.png");
        background-size  : cover;
    }


    &:nth-of-type(2) h2{
        background-image : url("../img/support/support/support2.png");
    }
}

.support_box{
    .txt01{
        font-size   : 1.5rem;
        line-height : 2.7rem;
        font-weight : normal;
        color       : #505050;
        padding     : 0px 20px 20px 20px;
        text-align  : left;
    }


    .link01{
        display            : block;
        width              : 80%;
        text-align         : center;
        background-color   : #041442;
        font-size          : 1.6rem;
        color              : #fff;
        padding            : 15px 20px 15px 20px;
        margin             : 0 auto;
        margin-top         : auto;
        border-radius      : 5px;
        position           : relative;
        -webkit-transition : all 0.3s ease;
        transition         : all 0.3s ease;


        &:hover{
            background-color : #fdac63;
        }
    }
}

#support{
    .btn01{
        margin-bottom : 120px;
        width         : 360px;
    }


    .tell{
        font-size   : 4rem;
        color       : #505050;
        font-weight : bold;
        text-align  : center;
    }


    .time{
        font-size  : 1.6rem;
        padding    : 10px 0;
        color      : #505050;
        text-align : center;
    }
}

/*FAQ---------------------------------------------------*/

#faq .txt01{
    margin-bottom : 80px;
    text-align    : center;
}

.faq_box{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    margin-bottom     : 60px;
}

.faq_box_item{
    margin-bottom    : 18px;
    color            : #fff;
    background-color : #f0f0f0;
    width            : 23.5%;
    height           : 120px;
    z-index          : 1;
    position         : relative;
}

.faq_box{
    img{
        display : block;
        margin  : 0 auto;
        padding : 15px 0;
        width   : 50px;
    }


    .txt01{
        text-align  : center;
        font-size   : 1.6rem;
        font-weight : bold;
        color       : #505050;
        padding     : 0px 15px 10px 15px;
    }
}

.faq_box_item a{
    position           : absolute;
    top                : 0;
    left               : 0;
    width              : 100%;
    height             : 100%;
    text-indent        : -999px;
    z-index            : 2;
    -webkit-transition : 0.3s;
    transition         : 0.3s;


    &:hover{
        background-color : #FFF;
        filter           : alpha(opacity=50);
        -moz-opacity     : 0.5;
        opacity          : 0.5;
    }
}

.faq_content{
    margin-bottom : 120px;


    h2{
        color          : #041442;
        font-size      : 3rem;
        padding-bottom : 30px;
        border-bottom  : 1px #707070 solid;
    }
}

.switchbox{
    padding-top : 9px;
    width       : 100%;


    label{
        display      : block;
        font-weight  : bold;
        cursor       : pointer;
        font-size    : 1.8rem;
        color        : #041442;
        padding-left : 1.7em;
        text-indent  : -1.7em;
        padding-top  : 11px;
    }


    .answer{
        font-size    : 1.4rem;
        line-height  : 2.8rem;
        color        : #505050;
        padding-left : 29px;
        padding-top  : 6px;
    }


    input{
        display : none;
    }


    div{
        height             : 0;
        overflow-y         : hidden;
        -webkit-transition : 0.8s;
        transition         : 0.8s;
        opacity            : 0;
    }


    input:checked + label + div{
        height  : auto;
        opacity : 1;
    }


    label::before{
        content       : "\f067";
        font-weight   : 900;
        font-family   : "Font Awesome 5 Free";
        padding-right : 8px;
    }


    input:checked + label::before{
        content : '\f068';
    }


    &::after{
        display    : block;
        content    : "";
        height     : 1px;
        width      : 100%;
        margin-top : 16px;
        border-top : 1px solid #505050;
    }
}

.faq_second{
    margin-top : 60px;
}

#faq01{
    margin-top  : -120px;
    padding-top : 120px;
}

/*無料ソフトウェア--------------------------------------------*/

#free{
    .flex{
        margin-bottom : 120px;


        .item{
            &:nth-of-type(1){
                height                : 200px;
                font-weight           : bold;
                width                 : 30%;
                vertical-align        : middle;
                background            : #041442;

                /* Old browsers */
                /* FF3.6-15 */
                /* Chrome10-25,Safari5.1-6 */
                background            : -webkit-gradient(linear, left top, left bottom, from(#041442), to(#e2996b));
                background            : linear-gradient(to bottom, #041442 0%, #e2996b 100%);

                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter                : progid:DXImageTransform.Microsoft.gradient(startColorstr='$mainColor', endColorstr='#e2996b', GradientType=0);

                /* IE6-9 */
                -webkit-box-orient    : vertical;
                -webkit-box-direction : normal;
                flex-direction        : column;

                /* 子要素をflexboxにより縦方向に揃える */
                -webkit-box-pack      : center;
                justify-content       : center;

                /* 子要素をflexboxにより中央に配置する */
                -webkit-box-align     : center;
                align-items           : center;

                /* 子要素をflexboxにより中央に配置する */
            }


            &:nth-of-type(2){
                width       : 65%;
                text-align  : left;
                font-size   : 1.6rem;
                color       : #505050;
                padding     : 0;
                line-height : 3rem;
            }


            &:nth-of-type(1) p{
                color     : #fff;
                font-size : 2.4rem;
                width     : 100%;
            }
        }
    }


    .flow img{
        margin-bottom : 120px;
    }


    .flow_inner{
        width     : 750px;
        margin    : 0 auto;
        font-size : 0;


        dl{
            position : relative;
        }


        dt{
            width            : 60px;
            height           : 60px;
            display          : inline-block;
            text-align       : center;
            padding-top      : 10px;
            color            : #fff;
            font-weight      : bold;
            font-size        : 3rem;
            background-color : #FF9857;
            border-radius    : 50%;
            position         : absolute;
            top              : 0;
            left             : 0;
        }


        dl{
            &:nth-of-type(3) dt, &:nth-of-type(4) dt{
                position : absolute;
                top      : -13px;
                left     : 0;
            }
        }


        dd{
            width          : 650px;
            display        : inline-block;
            text-align     : left;
            padding-bottom : 30px;
            color          : #505050;
            font-size      : 1.6rem;
            line-height    : 3rem;
            border-bottom  : #707070 1px solid;
            margin-bottom  : 30px;
            margin-left    : 100px;
        }


        dl{
            &:nth-of-type(2) dd{
                margin-bottom : 45px;
            }


            &:nth-of-type(3) dd{
                margin-bottom  : 45px;
                padding-bottom : 45px;
            }


            &:nth-of-type(4) dd{
                padding-bottom : 45px;
            }
        }
    }


    .center{
        padding-top    : 40px;
        padding-bottom : 80px;
    }
}

/*用語集-----------------------------------------------------*/

#glossary{
    .txt01{
        margin-bottom : 60px;
        text-align    : center;
    }


    h2{
        color         : #041442;
        padding       : 10px 0;
        font-size     : 2rem;
        text-align    : left;
        border-bottom : 1px #041442 solid;
        margin-bottom : 20px;
    }
}

.kashira_link{
    font-size     : 0;
    margin-right  : -10.5px;
    overflow      : hidden;
    margin-bottom : 80px;
}

.kashira01{
    margin-bottom : 20px;
}

.kashira_link a{
    font-size        : 1.6rem;
    font-weight      : bold;
    text-align       : center;
    line-height      : 5rem;
    color            : #555555;
    border-radius    : 5px;
    background-color : #efefef;
    display          : inline-block;
    width            : 50px;
    margin-right     : 10.5px;
    margin-bottom    : 10px;


    &:hover{
        background-color : #041442;
        color            : #fff;
    }
}

.kashira_wrap{
    margin-top : 40px;
}

.kashira{
    display       : inline;
    text-align    : center;
    border-bottom : 3px solid #505050;
    font-weight   : bold;
    color         : #505050;
    font-size     : 2.4rem;
    padding-top   : 120px;
}

#glossary{
    th{
        text-align    : left;
        font-size     : 1.4rem;
        padding       : 10px 0;
        width         : 25%;
        color         : #041442;
        padding-right : 25px;
    }


    td{
        font-size  : 1.4rem;
        padding    : 10px 0;
        color      : #505050;
        text-align : justify;
    }


    table{
        margin-bottom : 60px;
    }
}

.PageTopBtn{
    position           : fixed;
    bottom             : 0px;
    right              : 20px;
    font-size          : 1.1rem;
    margin-bottom      : 20px;
    opacity            : 0;
    -webkit-transition : 0.3s;
    transition         : 0.3s;
}

.pageTopBtn-in{
    opacity        : 1;
    pointer-events : auto;
}

.pageTopBtn-out{
    right : -999px;
}

.PageTopBtn a{
    display            : block;
    text-decoration    : none;
    color              : #fff;
    background         : #cecece;
    text-align         : center;
    border-radius      : 15px;
    outline            : none;
    width              : 80px;
    height             : 80px;
    padding            : 15px 0;
    font-size          : 1.2rem;
    font-weight        : bold;
    -webkit-transition : all 0.3s ease;
    transition         : all 0.3s ease;


    &:hover{
        text-decoration : none;
        background      : #4b4b4b;
    }
}

#sakuin{
    margin-top  : -120px;
    padding-top : 120px;
}

.fa-bookmark{
    font-size      : 3rem !important;
    padding-bottom : 5px;
}

/*販売パートナー様募集-----------------------------------------*/

#partner{
    .txt01{
        color       : #505050;
        font-size   : 1.4em;
        line-height : 3rem;
        text-align  : left;
    }


    .small{
        color         : #505050;
        font-size     : 1.4rem;
        text-align    : right;
        margin-bottom : 60px;
    }


    .merit{
        margin-bottom : 60px;


        li{
            width            : 48.5%;
            background-color : #f5f5f5;
            font-size        : 0;
            margin-bottom    : 20px;


            .merit_img{
                display           : block;
                width             : 100%;
                color             : #fff;
                line-height       : 170px;
                margin            : 0 auto;
                text-align        : center;
                font-size         : 2rem;
                font-weight       : bold;
                background-image  : url("../img/support/partner/01.jpg");
                background-repeat : no-repeat;
                background-size   : cover;
                height            : 180px;
            }


            &:nth-of-type(2) .merit_img{
                background-image : url("../img/support/partner/02.jpg");
            }


            &:nth-of-type(3) .merit_img{
                background-image : url("../img/support/partner/03.jpg");
            }


            &:nth-of-type(4) .merit_img{
                background-image : url("../img/support/partner/04.jpg");
            }


            .merit_txt{
                display   : block;
                width     : 100%;
                padding   : 30px;
                font-size : 1.6rem;
            }
        }
    }


    .btn03{
        margin-bottom : 120px;
    }


    .btn02{
        width  : 400px;
        margin : 20px auto;
    }


    .partner_flow_box{
        padding   : 30px;
        border    : #A3A3A3 1px solid;
        font-size : 0;
    }
}

.partner_flow{
    margin-bottom : 40px;
}

.partner_left_box{
    display      : block;
    width        : 100%;
    margin-right : 0px;
    margin       : 0 auto;
}

#partner{
    .number{
        color            : #fff;
        width            : 40px;
        height           : 40px;
        margin-right     : 15px;
        padding-left     : 0px;
        padding-top      : 4px;
        font-size        : 2.6rem;
        display          : inline-block;
        background-color : #FF9857;
        border-radius    : 50%;
        font-weight      : normal;
    }


    .arrow{
        width      : 100%;
        text-align : center;
        padding    : 20px;


        img{
            width : 50px;
        }
    }
}

.partner_right_box{
    display    : block;
    width      : 100%;
    font-size  : 1.4rem;
    color      : #505050;
    text-align : center;
}

#partner .mail{
    color           : #505050;
    font-weight     : bold;
    font-size       : 1.3em;
    margin-left     : 20px;
    text-decoration : underline;
}

.partner_left_box p:nth-of-type(1){
    color            : #041442;
    width            : 100%;
    height           : auto;
    padding-left     : 0px;
    padding-right    : 30px;
    padding-top      : 0px;
    font-size        : 2rem;
    font-weight      : bold;
    display          : inline-block;
    background-color : #FFF;
    border-radius    : 50%;
    margin           : 0 auto;
    margin-bottom    : 10px;
    text-align       : center;
}

.partner_notice{
    font-size     : 1.4rem;
    line-height   : 1.5em;
    margin-bottom : 50px;
}

/*ダウンロード------------------------------------------------*/

#download{
    h2{
        color : #041442;
    }


    table{
        margin-bottom : 120px;
        table-layout  : fixed;
        word-break    : break-all;
        word-wrap     : break-all;
    }
}

.app_area{
    width         : 100%;
    margin-bottom : 60px;
}

.app_img{
    float         : left;
    width         : 20%;
    border        : 1px #f0f0f0 solid;
    border-radius : 10px;
}

.app_link{
    float : right;
    width : 75%;


    h2{
        margin-top    : 5px;
        margin-bottom : 15px;
        color         : #505050 !important;
    }


    p{
        margin-bottom : 5px;
        font-size     : 1.4rem;
    }


    a{
        color     : #041442;
        font-size : 1.4rem;


        &:hover{
            text-decoration : underline;
        }
    }
}

.app_left{
    float : left;
    width : 48%;
}

.app_right{
    float : right;
    width : 48%;
}

/*============================================================

CONTACTページ

============================================================*/

.fa-headset{
    margin-right : 10px;
}


.transmission_btn{
    display : block;
}

#contact .transmission{
    display            : block;
    width              : 280px;
    height             : 50px;
    margin             : 0 auto;
    color              : #fff;
    font-size          : 2rem;
    font-weight        : bold;
    background-color   : #041442;
    border             : none;
    cursor             : pointer;
    border-radius      : 5px;
    -webkit-transition : all 0.3s ease;
    transition         : all 0.3s ease;


    &:hover{
        background-color : #ffa76e;
    }
}

.mw_wp_form .horizontal-item + .horizontal-item{
    margin-left  : 0px !important;
    margin-right : 0px;
}

.mwform-checkbox-field input, .mwform-radio-field input{
    margin-right : 0px !important;
}

.privacy_check{
    text-align : center;
    font-size  : 1.4rem;
}

.policy_link{
    display       : block;
    width         : 150px;
    margin        : 0 auto;
    text-align    : center;
    font-size     : 1.4rem;
    margin-bottom : 40px;
    color         : #041442;


    &:hover{
        text-decoration : underline;
    }
}

.mwform-checkbox-field-text{
    margin-left  : 0px;
    margin-right : 10px;
}

/*確認画面---------------------------------------------------*/

#confilm{
    .policy_link, .privacy_check, .required{
        display : none;
    }


    th{
        width          : 200px;
        font-size      : 1.6rem;
        font-weight    : normal;
        line-height    : 2.4rem;
        margin-bottom  : 20px;
        display        : inline-block;
        margin-right   : 30px;
        vertical-align : top;
    }


    tbody{
        margin-bottom : 60px;
        display       : block;
    }


    td{
        width          : 640px;
        font-size      : 1.6rem;
        font-weight    : normal;
        line-height    : 2.4rem;
        margin-bottom  : 20px;
        display        : inline-block;
        vertical-align : top;
    }


    .back{
        display            : block;
        width              : 280px;
        height             : 50px;
        margin             : 0 auto;
        color              : #fff;
        font-size          : 2rem;
        font-weight        : bold;
        background-color   : #041442;
        border             : none;
        cursor             : pointer;
        border-radius      : 5px;
        -webkit-transition : all 0.3s ease;
        transition         : all 0.3s ease;
        margin-bottom      : 100px;
    }


    .transmission{
        display            : block;
        width              : 280px;
        height             : 50px;
        margin             : 0 auto;
        color              : #fff;
        font-size          : 2rem;
        font-weight        : bold;
        background-color   : #041442;
        border             : none;
        cursor             : pointer;
        border-radius      : 5px;
        -webkit-transition : all 0.3s ease;
        transition         : all 0.3s ease;
        margin-bottom      : 20px;


        &:hover{
            background-color : #ffa76e;
        }
    }


    .back:hover{
        background-color : #ffa76e;
    }
}

/*完了画面---------------------------------------------------*/

#complete{
    p{
        font-size     : 1.6rem;
        line-height   : 3rem;
        margin-bottom : 20px;
        text-align    : center;
    }


    .btn03{
        width         : 240px;
        margin-bottom : 100px;
        margin-top    : 60px;
    }
}

/*============================================================

404ページ

============================================================*/

#error{
    .txt01{
        text-align    : center;
        margin-bottom : 40px;
    }


    .btn03{
        margin-bottom : 80px;
    }
}

/*============================================================

フッター

============================================================*/

#footer{
    width            : 100%;
    background-color : #041442;
    color            : #fff;
    text-align       : left;


    .footer_inner{
        width          : 900px;
        margin         : 0 auto;
        padding-bottom : 20px;
        padding-top    : 40px;
    }
}

#footer_nav{

    a{
        color : #fff;
    }


    & > ul > li{

        width        : calc(15% - 20px);
        float        : left;
        margin-right : 10px;
        font-size    : 1.1rem;
        border-left  : 2px #fff solid;
        padding-left : 14px;

        @include break-point(1000){

            border-left   : 1px #1b2c5b solid;

            width         : 47%;
            margin-right  : 5%;
            margin-bottom : 20px;
            padding-left  : 10px;

        }


        > a:first-child{

            font-size      : 1.9rem;
            padding-bottom : 0px;
            margin-bottom  : 8px;
            display        : block;

        }


        &:last-child{

            border-right  : 2px #fff solid;
            padding-right : 20px;
            margin-right  : 0;

        }

    }


    .sub-menu{

        li{

            padding-bottom : 8px;

        }

    }

}

.sub-menu a:hover{
    text-decoration : underline;
}

.footer_logo{
    width         : 100%;
    font-size     : 3rem;
    margin-bottom : 40px;
}

.footer_nav02{
    p{
        width      : 690px;
        display    : inline-block;
        font-size  : 1.1rem;
        margin-top : 30px;
        color      : #fff;
    }


    ul{
        width      : 200px;
        display    : inline-block;
        text-align : right;


        li{
            display      : inline-block;
            font-size    : 1.1rem;
            padding-left : 10px;
        }
    }


    a{
        color : #fff;


        &:hover{
            text-decoration : underline;
        }
    }
}

#footer p:last-child{

    color            : #fff;
    font-size        : 1.2rem;
    text-align       : center;

    background-color : #252525;

    margin           : 0;
    padding          : 10px 0;

}

/*フッタープロダクトナビ------------------------------------------------*/

.footer_product_nav_wrap{

    width            : 100%;
    background-color : #393939;
    padding          : 30px 0;
    font-size        : 0px;
    line-height      : 1.6rem;


    a{

        color         : #fff;
        border-bottom : 1px solid #fff;
        width         : 100%;

    }


    p{

        font-size     : 3.1rem;
        color         : #fff;
        margin-bottom : 30px;

    }


    .sub-menu{

        a{

            color            : #fff;
            font-size        : 1.3rem;
            padding          : 7px 0;

            border-bottom    : none;
            background-image : none;

            display          : block;


            &:hover{

                color           : #fff;
                opacity         : 0.8;
                text-decoration : none;

            }

        }

    }


    .menu-item-has-children{

        & > a{

            font-size         : 2rem;

            display           : block;
            margin-bottom     : 20px;
            padding           : 0.8rem 0.8rem 14px 3.8rem;

            background-image  : url("../img/cog.png");
            background-repeat : no-repeat;
            background-size   : 30px;

        }

    }


    .fp_nav_title{
        font-size           : 1.6rem;
        color               : #fff;
        border-bottom       : solid 1px #777777;
        padding-bottom      : 13px;
        padding-left        : 32px;

        /*  background-image: url("../img/product/list/camera.svg");*/
        background-repeat   : no-repeat;
        background-size     : 24px;
        background-position : 0 0px;
        margin-bottom       : 15px;
        padding-top         : 3px;
    }


    #menu-item-829 > ul > li,
    #menu-item-855 > ul > li{
        display        : inline-block;
        width          : 140px;
        vertical-align : top;
        margin-bottom  : 10px;
    }


    #menu-item-873 .sub-menu li,
    #menu-item-876 .sub-menu li,
    #menu-item-884 .sub-menu li,
    #menu-item-889 .sub-menu li{
        width   : 140px;
        display : inline-block;
    }


    #menu-item-95 li{
        width : 50%;
        float : left;
    }

}

#footer_product_nav{

    #menu-item-833 > a, #menu-item-834 > a, #menu-item-854 > a, #menu-item-864 > a, #menu-item-869 > a{
        color          : #A3A3A3;
        display        : block;
        font-size      : 1.2rem;
        padding-bottom : 10px;
    }


    & > ul > li{

        display        : inline-block;
        width          : 418px;
        vertical-align : top;
        margin-bottom  : 20px;


        &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
            margin-bottom : 0px;
        }


        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5){
            margin-right : 30px;
        }

    }

}

.footer_product_nav_wrap_sp{

    display : none;

    @include break-point(1000){

        width            : 100%;
        background-color : #393939;

    }

}

/*============================================================

プライバシーポリシー,免責事項

============================================================*/

#privacy-policy h2{
    color         : #B16D30;
    font-size     : 2rem;
    margin-bottom : 30px;
}

#disclaimer{
    h2{
        color         : #B16D30;
        font-size     : 2rem;
        margin-bottom : 30px;
    }


    .txt01{
        margin-bottom : 60px;
    }
}

#privacy-policy{
    p, li{
        font-size   : 1.6rem;
        line-height : 3rem;
        color       : #505050;
    }
}

#disclaimer{
    p, li{
        font-size   : 1.6rem;
        line-height : 3rem;
        color       : #505050;
    }
}

#privacy-policy li, #disclaimer li{
    text-indent  : -1.6rem;
    padding-left : 1.6rem;
}

#privacy-policy{
    span{
        display : block;
    }


    ul{
        margin : 20px 0;
    }
}

#disclaimer{
    ul{
        margin : 20px 0;


        li ul{
            margin : 5px 0;


            li{
                text-indent  : 0rem;
                padding-left : 0rem;
            }
        }
    }


    .enactment{
        text-align : right;
        margin     : 40px 0 100px 0;
    }
}

#single_product .strong{
    margin-top : 20px;


    p{
        font-size        : 1.6rem;
        margin-bottom    : 20px;
        display          : inline-block;
        padding          : 5px 10px;
        color            : #fff;
        font-weight      : bold;
        background-color : #707070;
    }


    li{
        font-size   : 1.4rem;
        color       : #707070;
        line-height : 2.8rem;
    }
}

.notcamera{
    margin-bottom : -80px !important;
}

.sp{
    display : none !important;
}

.pc{
    display : block !important;
}

@media screen and (max-width : 1000px){
    #main_img img{
        width         : 100%;
        height        : 383px;
        -o-object-fit : cover;
        object-fit    : cover;
    }
}

@media screen and (max-width : 960px){
    .pc_inner, .inner{
        width : 90%;
    }

    .pc{
        display : none !important;
    }

    .sp{
        display : block !important;
    }

    .inner02, .inner03{
        width       : 90%;
        padding-top : 40px;
    }

    .inner04, .inner05{
        width : 90%;
    }

    .container{
        padding-top : 65px;
    }

    .mb120{
        margin-bottom : 60px;
    }

    .mb80{
        margin-bottom : 40px;
    }

    /*============================================================

  ヘッダー

  ============================================================*/
    /*グローバルメニュー----------------------------------------------*/

    #responsive-menu-container{
        margin-top       : 65px !important;
        background-color : rgba(0, 0, 0, 0.7) !important;
    }

    #responsive-menu{
        .subtitle{
            display : none;
        }


        li{
            background-color : #A3A3A3 !important;
        }
    }

    .responsive-menu-item-link{
        cursor : pointer;
        top    : auto !important;
    }

    #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow{
        line-height   : 63px !important;
        padding-right : 35px;
    }

    .responsive-menu-item-link .title{
        font-size : 2rem;
    }

    button#responsive-menu-button{
        width  : 61px !important;
        height : 61px !important;
        top    : 0px !important;
        right  : 0px !important;
    }


    #logo .inner{
        width : 100%;
    }

    .logo_wrap, .partner_link_tb{
        display : none;
    }

    #responsive-menu-container{
        #responsive-menu{
            li{
                &.responsive-menu-current-item > .responsive-menu-item-link, &.responsive-menu-item a{
                    border-color   : #505050 !important;
                    vertical-align : top;
                }
            }


            ul.responsive-menu-submenu li.responsive-menu-item .responsive-menu-item-link{
                font-size        : 16px;
                background-color : #2f2f2f;
            }
        }


        li.responsive-menu-item a{
            padding-left : 25px !important;
        }


        #responsive-menu-item-1453 a{
            padding-left        : 65px !important;
            background-color    : #3B3B3B !important;
            font-size           : 1.4rem !important;
            background-image    : url("../img/shake_hands.svg");
            background-repeat   : no-repeat;
            background-size     : 35px;
            background-position : 18px 12px;
        }
    }

    #footer_nav > ul > li:last-child{
        border-right  : none;
        padding-right : 20px;
        margin-right  : 100%;
    }

    /*============================================================

  ナビ検索バー

  ============================================================*/

    .product_nav_searchbox{
        display        : inline-table;
        width          : 90%;
        height         : auto;
        font-size      : 1.3rem;
        color          : #fff;
        vertical-align : top;
        padding        : 15px;
        position       : relative;
    }

    /*============================================================

  下層ページナビ

  ============================================================*/

    .about_nav, .solution_nav, .product_nav{
        width            : 100%;
        background-color : #5D5D5D;
        position         : relative;
    }

    .about_nav ul, .solution_nav ul, .product_nav ul{
        width  : 100%;
        margin : 0;
    }

    .about_nav ul li{
        border-right   : solid 1px #707070 !important;


        &:nth-of-type(2n){
            border-right : none;
        }


        &:nth-of-type(1), &:nth-of-type(2){
            border-bottom : solid 1px #707070 !important;
        }


        display        : inline-block;
        vertical-align : middle;
        border         : none;
        width          : 50%;
        height         : 60px;
        font-size      : 1.3rem;
        color          : #fff;
        font-weight    : bold;
        line-height    : 2rem;
        overflow       : hidden;
        border-bottom  : 2px solid #5D5D5D;
    }

    .solution_nav ul li, .product_nav ul li{
        display        : inline-block;
        vertical-align : middle;
        border         : none;
        width          : 50%;
        height         : 60px;
        font-size      : 1.3rem;
        color          : #fff;
        font-weight    : bold;
        line-height    : 2rem;
        overflow       : hidden;
        border-bottom  : 2px solid #5D5D5D;
    }

    .about_nav ul li:hover,
    .solution_nav ul li:hover{
        background-color : #464646;
    }

    .product_nav ul li{
        &:hover{
            background-color : #464646;
        }


        width : 33.3%;


        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
            border-bottom : solid 1px #707070;
        }


        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5){
            border-right : solid 1px #707070;
        }


        &:nth-of-type(7){
            border-bottom : none;
        }
    }

    /*  .product_nav ul li:nth-of-type(3) a, .product_nav ul li:nth-of-type(6) a {
  padding-top: 11px;
}*/

    .about_nav ul li a, .solution_nav ul li a, .product_nav ul li a{
        display     : block;
        color       : #fff;
        line-height : 1.5;
        height      : 100%;
        padding-top : 20px;

        /*border-bottom: 2px solid #a0a0a0;*/
    }

    .tax-video_surveillance_category{
        #menu-item-385, #menu-item-402{
            background-color : #a0a0a0;
            border-bottom    : none;
        }
    }

    .tax-peripherals_category{
        #menu-item-385, #menu-item-401{
            background-color : #a0a0a0;
            border-bottom    : none;
        }
    }

    .tax-accessory_category{
        #menu-item-385, #menu-item-400{
            background-color : #a0a0a0;
            border-bottom    : none;
        }
    }

    .tax-consumer_category{
        #menu-item-402, #menu-item-385{
            background-color : #a0a0a0;
            border-bottom    : none;
        }
    }

    .tax-peripherals_category #menu-item-401, .tax-accessory_category #menu-item-400{
        border-bottom : 1px solid #707070;
    }

    .tax-video_surveillance_category{
        #menu-item-385, #menu-item-402, #menu-item-385:hover, #menu-item-402:hover{
            border-bottom : 1px solid #707070;
        }
    }

    .support_nav{
        width            : 100%;
        background-color : #5D5D5D;
        position         : relative;


        ul{
            width  : 100%;
            margin : 0;


            li{
                display        : inline-block;
                vertical-align : middle;
                border         : none;
                width          : 50%;
                height         : 60px;
                font-size      : 1.3rem;
                color          : #fff;
                font-weight    : bold;
                line-height    : 2rem;
                overflow       : hidden;


                &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4){
                    border-bottom : solid 1px #707070;
                }


                &:nth-of-type(2n+1){
                    border-right : solid 1px #707070;
                }


                &:hover{
                    background-color : #a0a0a0;
                    border-top       : none;
                    border-bottom    : 1px solid #707070;
                }


                &:nth-of-type(5){
                    border-bottom : none;
                    width         : 100%;
                    border-right  : none;
                }


                a{
                    display     : block;
                    color       : #fff;
                    line-height : 1.5;
                    height      : 100%;
                    padding-top : 20px;
                }


                &:nth-of-type(4) a{
                    padding-top : 18px;
                }
            }
        }


        li:hover{
            background-color : #464646 !important;
        }


        .current_page_item:hover{

            background-color : #464646;

        }

    }

    #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow{
        right            : 0;
        height           : 50px;
        line-height      : 50px;
        width            : 20px;
        color            : #ffffff;
        border-left      : none !important;
        background-color : #212121;
    }

    .solution_nav{
        li:nth-of-type(4).current-menu-item{
            border-bottom : none;
        }


        .current-menu-item{
            border-right : solid 1px #707070;


            &:hover{
                border-bottom    : 1px solid #707070;
                background-color : #464646;
            }
        }
    }

    .camera #menu-item-799, .tax-storage_device_category #menu-item-403{
        border-bottom    : 1px solid #707070;
        background-color : #a0a0a0;
    }

    .current_camera #menu-item-799, .current_video #menu-item-402, .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399, .current_storage_device #menu-item-403{
        border-bottom    : none;
        background-color : #a0a0a0;
    }

    .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399{
        border-bottom : 1px solid #707070;
    }

    /*============================================================

  ソート共通部分

  ============================================================*/

    #archive_news section p,
    #single_news section p,
    #archive_case section p,
    #single_case section p,
    #archive_video section p, #archive_product section p, #archive_peripherals section p, #archive_accessory section p{
        background-color : #f5f5f5;
        padding          : 15px 20px;
        color            : #505050;
        font-weight      : bold;
        font-size        : 1.4rem;
        cursor           : pointer;
        position         : relative;
        height           : 50px;
    }

    .trigger01, .trigger02{
        position : relative;
    }

    #archive_news section p:before, #single_news section p:before, #archive_case section p:before, #single_case section p:before, #archive_video section p:before, #archive_product section p:before, #archive_peripherals section p:before, #archive_accessory section p:before, .trigger01:before, .trigger02:before{
        display           : block;
        content           : "";
        position          : absolute;
        -webkit-transform : rotate(45deg);
        transform         : rotate(45deg);
        top               : 50%;
        right             : 20px;
        width             : 10px;
        height            : 10px;
        margin-top        : -8px;
        background        : #505050;
    }

    #archive_news section p:after, #single_news section p:after, #archive_case section p:after, #single_case section p:after, #archive_video section p:after, #archive_product section p:after, #archive_peripherals section p:after, #archive_accessory section p:after, .trigger01:after, .trigger02:after{
        display           : block;
        content           : "";
        position          : absolute;
        -webkit-transform : rotate(45deg);
        transform         : rotate(45deg);
        top               : 50%;
        right             : 20px;
        width             : 10px;
        height            : 10px;
        margin-top        : -13px;
        background-color  : #f5f5f5;
    }

    .trigger01:after, .trigger02:after{
        background-color : #dedede;
    }

    #archive_news section p.select_active:before, #single_news section p.select_active:before, #archive_case section p.select_active:before, #single_case section p.select_active:before, #archive_video section p.select_active:before, #archive_product section p.select_active:before, #archive_peripherals section p.select_active:before, #archive_accessory section p.select_active:before, .sp_net_typewrap p.select_active:before{
        margin-top : -2px;
    }

    #archive_news section p.select_active:after, #single_news section p.select_active:after, #archive_case section p.select_active:after, #single_case section p.select_active:after, #archive_video section p.select_active:after, #archive_product section p.select_active:after, #archive_peripherals section p.select_active:after, #archive_accessory section p.select_active:after, .sp_net_typewrap p.select_active:after{
        margin-top : 3px;
    }

    .accoedion_inner{
        font-size        : 0;
        background-color : #f5f5f5;
    }

    #archive_case .accoedion_inner, #single_case .accoedion_inner, #archive_product .accoedion_inner, #single_product .accoedion_inner, #archive_video .accoedion_inner{
        padding-bottom : 10px;
        font-size      : 0;
        width          : 95%;
        margin         : 0 auto;
    }

    #archive_case .select_area a, #single_case .select_area a, #archive_video .select_area a, #archive_peripherals .select_area a, #archive_accessory .select_area a, #archive_product .select_area a{
        font-size : 1.3rem;
        display   : block;
        width     : 100%;
        padding   : 15px 0;
    }

    #archive_case .select_area li:nth-of-type(1), #single_case .select_area li:nth-of-type(1), #archive_video .select_area li:nth-of-type(1), #archive_product .select_area li:nth-of-type(1), #archive_peripherals .select_area li:nth-of-type(1), #archive_accessory .select_area li:nth-of-type(1){
        margin-right : 0px;
        border-right : none;
    }

    #archive_news section li, #single_news section li, #archive_video section li, #archive_product section li, #archive_peripherals section li, #archive_accessory section li{
        background-color : #f5f5f5;
        padding          : 8px 24px;
        display          : inline-block;
        width            : 50%;
        box-sizing       : border-box;
        border           : none;
        font-size        : 1.4rem;
        text-align       : center;
        font-weight      : bold;
    }

    #archive_case section li, #single_case section li{
        background-color : #f5f5f5;
        padding          : 8px 24px;
        display          : inline-block;
        width            : 33.333%;
        box-sizing       : border-box;
        border           : none;
        font-size        : 1.4rem;
        text-align       : center;
        font-weight      : bold;
    }

    #archive_news section li:nth-of-type(1), #single_news section li:nth-of-type(1), #archive_case section li:nth-of-type(1), #single_case section li:nth-of-type(1), #archive_video section li:nth-of-type(1), #archive_product section li:nth-of-type(1), #archive_peripherals section li:nth-of-type(1), #archive_accessory section li:nth-of-type(1){
        width : 100%;
    }

    .accordion_ul{
        .inactive{
            background-color : #f5f5f5;
            padding          : 8px 10px;
            display          : inline-block;
            border-radius    : 15px;
            display          : inline-block;
            color            : #505050;


            a{
                color : #505050;
            }
        }


        .active, .current-cat, .all{
            background-color : #fff;
            padding          : 8px 10px;
            border-radius    : 15px;
            display          : inline-block;
            color            : #505050;
        }
    }

    #archive_case .select_area, #single_case .select_area, #archive_video .select_area, #archive_peripherals .select_area, #archive_accessory .select_area, #archive_product .select_area{
        height : auto;
    }

    #archive_case .select_area ul, #single_case .select_area ul, #archive_video .select_area ul, #archive_product .select_area ul, #archive_peripherals .select_area ul, #archive_accessory .select_area ul{
        padding-top : 0;
    }

    #archive_case .select_area ul li:not(:first-child), #single_case .select_area ul li:not(:first-child), #archive_video .select_area ul li:not(:first-child), #archive_peripherals .select_area ul li:not(:first-child), #archive_accessory .select_area ul li:not(:first-child), #archive_product .select_area ul li:not(:first-child){
        padding : 0px 0px;
    }

    #archive_video .select_area, #archive_product .select_area, #archive_peripherals .select_area, #archive_accessory .select_area{
        margin-bottom : 20px;
    }

    /*============================================================

  TOPページ

  ============================================================*/

    /*利用シーンから探す-------------------------------------------*/

    .product_box{
        margin-bottom : 30px;
    }

    .product_box_item{
        width  : 48.5%;
        height : 325px;
    }

    .product_box{
        img{
            width : 100%;
        }


        .txt01{
            text-align : center;
        }


        .txt02{
            text-align : center;
            padding    : 0 10px;
        }
    }

    /*お問い合わせ------------------------------------------------*/
    /*============================================================

  ABOUTページ

  ============================================================*/
    /*会社概要----------------------------------------------------*/

    .rbox01{
        float : none;
        width : 100%;
    }

    .lbox01{
        float         : none;
        width         : 100%;
        margin-bottom : 40px;
    }

    .message img{
        max-width : 250px;
        height    : auto;
        display   : block;
        margin    : 0 auto;
    }

    /*沿革------------------------------------------------------*/

    #history{
        .inner02{
            width       : 420px;
            padding-top : 40px;
        }


        .txt01{
            text-align : left;
        }
    }

    .flow_box:before{
        content           : "";
        position          : absolute;
        top               : 54px;
        left              : 0px;
        background-repeat : repeat-y;
        width             : 0px;
        height            : 0px;
        background-image  : none;
        background-repeat : none;
        background-size   : none;
    }

    .flow_box_left:before{
        content           : "";
        position          : absolute;
        top               : 54px;
        left              : 0px;
        background-repeat : repeat-y;
        width             : 48px;
        height            : 2154px;
        background-image  : url(img/about/line.png);
        background-repeat : repeat-y;
        background-size   : 50%;
    }

    .flow_box_item:after{
        content           : "";
        position          : absolute;
        top               : 54px;
        left              : -18%;
        background-repeat : repeat-y;
        width             : 48px;
        height            : 85%;
        background-image  : url("../img/about/flow_icon_sp.png");
        background-repeat : no-repeat;
        background-size   : 50%;
    }

    .flow_box img{
        max-height    : 100%;
        max-width     : 100%;
        position      : absolute;
        bottom        : 0;
        height        : 160px;
        -o-object-fit : cover;
        object-fit    : cover;
    }

    .flow_box_left{
        float      : left;
        width      : 15%;
        margin-top : 0px;
    }

    .flow_box_right{
        float : right;
        width : 85%;
    }

    .flow_box_item{
        width            : 100%;
        height           : 280px;
        background-color : #f5f5f5;
        margin-bottom    : 41px;
        display          : inline-block;
        position         : relative;
    }

    /*受賞歴-----------------------------------------------------*/

    #awards{
        h2{
            font-size     : 2rem;
            color         : #505050;
            margin-bottom : 40px;
            text-align    : center;
        }


        .lbox01{
            display      : inline-block;
            width        : 100%;
            margin-right : 0px;
        }


        .rbox01{
            display       : inline-block;
            width         : 100%;
            margin-bottom : 40px;
        }


        .lbox01 img, .rbox01 img{
            width : 100%;
        }


        .mb80{
            margin-bottom : 40px;
        }
    }

    .awards_h{
        color         : #505050;
        font-size     : 2rem;
        margin-bottom : 20px;
        text-align    : center;
    }

    .awards_txt{
        color         : #505050;
        font-size     : 1.4rem;
        line-height   : 2.4rem;
        margin-bottom : 40px;
    }

    .awards_detail{
        padding-bottom : 40px;
    }

    .awards_list ul li{
        display       : table;
        width         : 100%;
        border-bottom : 1px solid #707070;
        padding       : 20px 0;


        p{
            display        : table-cell;
            font-size      : 1.2rem;
            font-weight    : bold;
            color          : #505050;
            width          : 100%;
            vertical-align : middle;
        }


        &:nth-of-type(2n+1) p, &:nth-of-type(6) p{
            vertical-align : middle;
            padding        : 0 0;
            width          : 70%;
            margin-right   : 2%;
        }


        img{
            display : table-cell;
            width   : 100%;
        }
    }

    /*ハンファーグループについて-------------------------------------*/

    #group{
        .headstyle01_inner{
            font-size : 2rem;
        }


        h3{
            font-size  : 2rem;
            text-align : center;
        }


        .rbox{
            display      : block;
            font-size    : 1.4rem;
            color        : #4C4C4E;
            width        : 100%;
            line-height  : 2.2rem;
            padding-left : 0px;
        }


        .lbox{
            display        : inline-block;
            font-size      : 1.4rem;
            color          : #4C4C4E;
            width          : 100%;
            vertical-align : top;
            line-height    : 2.2rem;
            padding-left   : 0px;
            margin-top     : 40px;


            img{
                display : block;
                margin  : 0 auto;
            }
        }
    }

    .sales_box{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        -webkit-box-pack  : justify;
        justify-content   : space-between;
        width             : 100%;
    }

    .sales_box_item{
        color   : #fff;
        width   : 32%;
        height  : auto;
        z-index : 1;


        p{
            font-size      : 3rem;
            font-weight    : bold;
            letter-spacing : 0.2rem;
            text-align     : center;
            padding-top    : 50px;
        }


        span{
            &.big{
                font-size   : 5rem;
                font-weight : bold;
            }


            &.small{
                font-size   : 1.4rem;
                display     : block;
                margin-top  : 5px;
                line-height : 2.5rem;
            }
        }
    }

    .techwin img{
        width         : 80%;
        display       : block;
        margin        : 0 auto;
        margin-bottom : 40px;
    }

    #group{
        .bgcolor_grey02{
            padding : 50px 0;
        }


        .pt100{
            padding-top : 50px;
        }


        .group_history_term{
            .year{
                width            : 150px;
                padding          : 10px 3px;
                text-align       : center;
                background-color : #F37321;
                font-weight      : bold;
                border-radius    : 20px;
                font-size        : 1.4rem;
                color            : #fff;
                margin           : 0 auto;
            }


            h4{
                font-weight : bold;
                text-align  : center;
                padding     : 10px;
                margin-top  : 10px;
                font-size   : 2.2rem;
                color       : #505050;
            }


            p{
                text-align    : center;
                font-size     : 1.4rem;
                line-height   : 2.6rem;
                margin-bottom : 40px;
            }
        }
    }

    #group_flow{
        margin-bottom : 60px;


        .group_flow01{
            .flow_box_left:before{
                height : 324px;
                left   : -1px;
            }


            .flow_box:before{
                height : 183px;
            }
        }


        .group_flow02{
            .flow_box_left:before{
                height : 650px;
                left   : -1px;
            }


            .flow_box:before{
                height : 504px;
            }
        }


        .group_flow03{
            .flow_box_left:before{
                height : 1290px;
                left   : -1px;
            }


            .flow_box:before{
                height : 704px;
            }
        }


        .group_flow04{
            .flow_box_left:before{
                height : 5460px;
                left   : -1px;
            }


            .flow_box:before{
                height : 704px;
            }
        }
    }

    #group .flow_box{
        position      : relative;
        width         : 80%;
        margin        : 0 auto;
        padding-right : 5%;
    }

    .flow_box_item p:nth-of-type(2), .flow_box_right p:nth-of-type(2){
        font-size : 1.4rem;
        color     : #505050;
        padding   : 0px 15px;
    }

    /*============================================================

  PLODUCT

  ============================================================*/
    /*共通-------------------------------------------------------*/

    .product_select_wrap{
        width            : 100%;
        background-color : #FFF;
        padding          : 0 0;
        margin-bottom    : 20px;
    }

    .product_select_inner{
        width  : 90%;
        margin : 0 auto;
    }

    .analog_wrap{
        margin-bottom : 20px;
    }

    .sp_net_typewrap{
        width  : 100%;
        cursor : pointer;
    }

    #tax1, #tax2, #tax3, #tax4, .nvr_wrap{
        display          : none;
        width            : 100%;
        margin-right     : 0px;
        font-size        : 0;
        background-color : #f5f5f5;
    }

    #tax1 li, #tax2 li, #tax3 li, #tax4 li, .nvr_wrap li{
        display      : inline-block;
        width        : 50%;
        margin-right : 0px;
        text-align   : center;
        border-top   : 1px solid #fff;
    }

    #tax1 li:nth-of-type(6), #tax3 li:nth-of-type(8){
        border-bottom : 1px solid #fff;
    }

    #tax1 li:nth-of-type(2n+1), #tax2 li:nth-of-type(2n+1), #tax3 li:nth-of-type(2n+1), #tax4 li:nth-of-type(2n+1), .nvr_wrap li:nth-of-type(2n+1){
        border-right : 1px solid #fff;
    }

    .sp_net_typewrap:nth-of-type(2) li:nth-of-type(4), #tax3 li:nth-of-type(6), #tax4 li:nth-of-type(2){
        border-bottom : 1px solid #fff;
    }

    #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a, .nvr_wrap li a{
        display : block;
        padding : 10px;
    }

    #tax1 li a.current, #tax2 li a.current, #tax3 li a.current, #tax4 li a.current, .nvr_wrap .current-cat a{
        background-color : #F37321;
        color            : #fff !important;
    }

    .analog_wrap, .net_wrap{
        display : block;
        width   : 100%;
    }

    .trigger02{
        border-top : 1px solid #fff;
    }

    .camera_sp_head{
        width            : 100%;
        background-color : #A3A3A3;
        color            : #FFFFFF;
        font-size        : 1.4rem;
        font-weight      : bold;
        text-align       : center;
        padding          : 5px 0;
        margin-top       : 20px;
    }

    .sp_net_typewrap > p{
        background-color : #DEDEDE;
        font-size        : 1.4rem;
        padding          : 15px 20px;
        color            : #505050;
    }

    .product_list{

        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-between;


        li{
            width  : 32%;
            height : auto;
        }


        &::before{
            content                   : "";
            display                   : none;
            width                     : 32%;
            -webkit-box-ordinal-group : 2;
            order                     : 1;
        }


        &::after{
            content : "";
            display : block;
            width   : 32%;
        }


        h2{
            font-size : 1.5em;
        }
    }

    .archive_subtitle01{
        font-size : 1.3em;
    }

    .strage_list_wrap{
        margin-bottom : 20px;
    }

    .wp-pagenavi{
        .first, .last{
            bottom : -7px;
        }
    }

    /*ここから製品詳細ページ--------------------------------------*/

    .spec_a{
        li{
            width            : 21%;
            margin-bottom    : 5%;
            height           : 0;
            padding-bottom   : 21%;
            background-color : #f5f5f5;
            border-radius    : 10px;
            text-align       : center;
        }


        &::after{
            content : "";
            display : block;
            width   : 47%;
        }
    }


    #single_product .subtitle{
        font-size : 1.2rem;
    }

    .spec_b li{
        width            : 48%;
        margin-bottom    : 10px;
        background-color : #f5f5f5;
        border-radius    : 5px;
        color            : #A3A3A3;
        font-weight      : bold;
        font-size        : 1.7rem;
        text-align       : center;
        padding          : 10px 0;
    }

    #spec_b_on{
        background-color : #ff8c00;
        color            : #ffffff;
    }

    #single_product{
        .delivery_products_wrap{
            padding        : 60px 0;
            padding-bottom : 80px;
        }


        .slick-dots{
            bottom : -45px !important;
        }


        .download_wrap{
            table{
                width     : 100%;
                color     : #505050;
                font-size : 1.4rem;
            }


            tbody{
                border : 1px solid #CCCCCC;
            }


            table{
                th{
                    font-weight    : normal;
                    padding        : 20px 10px;
                    border-right   : 1px solid #B1B1B1;
                    vertical-align : middle;


                    &:nth-of-type(1){
                        width : 1%;
                    }


                    &:nth-of-type(2), &:nth-of-type(4){
                        width : 15%;
                    }


                    &:nth-of-type(5){
                        width : 17%;
                    }
                }


                td{
                    text-align     : center;
                    padding        : 20px;
                    border-bottom  : 1px solid #CCCCCC;
                    border-right   : 1px solid #CCCCCC;
                    vertical-align : middle;
                }
            }
        }


        tbody tr:nth-of-type(1){
            padding          : 10px;
            background-color : #C7C6C6;
            background-color : #C7C6C6;
        }


        .slider{
            width : 100%;
        }


        .slick-list{
            width  : 90%;
            margin : 0 auto;
        }
    }

    .spec_a{
        margin-top : 35px;
    }

    .consumer_table_wrap table{
        display        : inline-block;
        width          : 100%;
        vertical-align : top;
        margin-bottom  : 60px;
        margin-right   : 2%;


        th{
            width : 15%;
        }


        &:nth-of-type(1){
            margin-bottom : 10px;
        }


        td{
            background-color : #fff;
        }
    }

    /*============================================================

  SOLUTION

  ============================================================*/
    /*選ばれる理由------------------------------------------------*/

    .reason_box{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        -webkit-box-pack  : justify;
        justify-content   : space-between;
        margin-bottom     : 20px;
    }

    .reason_box_item{
        display               : -webkit-box;
        display               : flex;
        -webkit-box-orient    : vertical;
        -webkit-box-direction : normal;
        flex-direction        : column;
        margin-bottom         : 18px;
        color                 : #fff;
        width                 : 30%;
        margin-bottom         : 40px;


        img{
            margin        : 0 auto;
            margin-bottom : 10px;
            max-width     : 100px;
        }


        span{
            color       : #F37321;
            font-weight : bold;
            font-size   : 1.6rem;
            text-align  : center;
        }
    }

    /*製品情報一覧-----------------------------*/

    #product{
        .bgcolor_grey01{
            background-color : #fff;
            width            : 100%;
        }


        .mb70{
            margin-bottom : 0;
        }
    }

    #x_series, #p_series, #q_series, #t_series, #for_home{
        margin-top  : -65px;
        padding-top : 65px;
    }

    #product{
        .eye_catch{
            width         : 100%;
            margin-bottom : 40px;
        }


        h3{
            font-size      : 2rem;
            vertical-align : top;
        }


        h4{
            font-size     : 2rem;
            color         : #707070;
            font-weight   : bold;
            text-align    : left;
            margin-bottom : 10px;
        }
    }

    .series_detail p{
        font-size   : 1.4rem;
        color       : #505050;
        text-align  : justify;
        line-height : 2.5rem;
    }

    .series_block00{
        margin-bottom : 40px;
    }

    .series_block01, .series_block03, .series_block05, .series_block06{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        -webkit-box-pack  : justify;
        justify-content   : space-between;
        margin-bottom     : 60px;
    }

    .series_block05, .series_block06, .series_block01_item p, .series_block05_item p{
        margin-bottom : 20px;
    }

    .series_block06_item{
        width : 30%;
    }

    .series_block02{
        font-size     : 0;
        margin-bottom : 40px;


        .lb{
            display      : inline-block;
            width        : 35%;
            margin-right : 5%;
            font-size    : 1.8rem;
        }


        .rb{
            display        : inline-block;
            width          : 60%;
            vertical-align : top;


            img{
                padding-top : 5px;
            }
        }
    }

    .series_block04{
        font-size : 0;


        .lb{
            display      : inline-block;
            width        : 28%;
            margin-right : 5%;
            font-size    : 1.8rem;
        }


        .rb{
            display        : inline-block;
            width          : 67%;
            vertical-align : top;


            img{
                padding-top : 5px;
            }
        }
    }

    #p_series .detail_link{
        padding-bottom : 0px;
    }

    #t_series .detail_link{
        padding-top    : 0px;
        margin-bottom  : 45px;
        padding-bottom : 20px;
    }

    .img100{
        width         : 100%;
        margin-bottom : 40px;
    }

    .series_block05{
        margin-bottom : 0;
    }

    /*無料ソフトウェア--------------------------------------------*/

    #free{
        .flex{
            margin-bottom : 60px;
        }


        .pt80{
            padding-top : 40px;
        }


        .flex .item{
            &:nth-of-type(1){
                height        : 100px;
                font-weight   : bold;
                width         : 100%;
                margin-bottom : 20px;
            }


            &:nth-of-type(2){
                width       : 100%;
                text-align  : left;
                font-size   : 1.4rem;
                color       : #505050;
                padding     : 0;
                line-height : 3rem;
            }


            &:nth-of-type(1) p{
                color     : #fff;
                font-size : 1.8rem;
                width     : 100%;
            }
        }


        .flow img{
            margin-bottom : 40px;
        }


        .flow_sp{
            dl{
                width : 100%;
            }


            dt{
                width            : 100%;
                height           : auto;
                display          : inline-block;
                text-align       : center;
                padding          : 5px 0;
                color            : #fff;
                font-weight      : bold;
                font-size        : 2rem;
                background-color : #FF9857;
                border-radius    : 5px;
                margin-bottom    : 10px;
            }


            dd{
                text-align     : center;
                width          : 100%;
                padding-bottom : 20px 0;
                color          : #505050;
                font-size      : 1.4rem;
                line-height    : 3rem;
                margin-bottom  : 30px;
            }
        }


        .center{
            padding-top    : 20px;
            padding-bottom : 50px;
        }
    }

    /*プロジェクト事例---------------------------------------------*/

    #archive_case h2{
        font-size     : 1.8rem;
        margin-bottom : 5px;


        a{
            font-size : 1.8rem;
        }
    }

    .project_list{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        -webkit-box-pack  : justify;
        justify-content   : space-between;


        li{
            &:nth-of-type(2n+1), &:nth-of-type(2n){
                margin-bottom : 40px;
                width         : 47%;
                display       : -webkit-box;
                display       : flex;
            }


            img{
                width   : 100%;
                height  : auto;
                display : inline-block;
            }
        }
    }

    .case_item{
        overflow         : hidden;
        background-color : #f5f5f5;
        position         : relative;
    }

    .link_whole{
        position           : absolute;
        top                : 0;
        left               : 0;
        width              : 100%;
        height             : 100%;
        text-indent        : -999px;
        z-index            : 2;
        -webkit-transition : 0.3s;
        transition         : 0.3s;
        white-space        : nowrap;
        overflow           : hidden;
        cursor             : pointer;


        &:hover{
            background-color : #fff;
            filter           : alpha(opacity=50);
            -moz-opacity     : 0.5;
            opacity          : 0.5;
        }
    }

    .case_lb{
        width : 100%;
        float : none;
    }

    .case_rb{
        width   : 100%;
        float   : none;
        height  : auto;
        padding : 20px;
    }

    .case_term a{
        color     : #707070;
        font-size : 1.4rem;
    }

    .case_place, .case_time{
        font-size : 1.4rem;
    }

    #archive_case .read_txt{
        font-size  : 1.4rem;
        text-align : justify;
    }

    .slider .slick-slide a{
        margin : 0px 0px;
    }

    /*============================================================

  ニュース

  ============================================================*/
    /*投稿ページ--------------------------------------------------*/

    #single_news{
        time{
            display        : inline-block;
            font-size      : 1.2rem;
            text-align     : center;
            padding-bottom : 20px;
            margin-top     : 20px;
            margin-right   : 10px;
        }


        .news_term{
            display       : inline-block;
            font-size     : 1.2rem;
            margin-bottom : 20px;
        }


        p{
            font-size     : 1.4rem;
            line-height   : 2.4rem;
            margin-bottom : 0px;
        }


        h1, h2{
            font-size     : 1.4rem;
            color         : #B16D30;
            margin-bottom : 20px;
        }


        .another_link p{
            font-size       : 1.8rem;
            text-decoration : underline;
            margin-bottom   : 10px;
            margin          : 0;


            &:last-child{
                margin-bottom : 30px;
            }


            a{
                font-size : 1.4rem;


                &:hover{
                    color : #F37321;
                }
            }
        }


        .news_content{
            margin-bottom : 40px;
        }
    }

    /*============================================================

  SUPPORT

  ============================================================*/
    /*サポート---------------------------------------------------*/
    /*FAQ---------------------------------------------------*/

    #faq .faq_box .txt01{
        font-size : 1.1rem;
    }

    .faq_box_item{
        margin-bottom : 18px;
        width         : 47%;
        height        : 90px;
    }

    .faq_box img{
        width : 30px;
    }

    /*用語集-----------------------------------------------------*/

    #glossary{
        .txt01{
            margin-bottom : 60px;
        }


        h2{
            color         : #F37321;
            padding       : 10px 0;
            font-size     : 2rem;
            text-align    : center;
            border-bottom : 1px #F37321 solid;
            margin-bottom : 20px;
        }
    }

    .kashira_link{
        font-size     : 0;
        margin-right  : -2%;
        overflow      : hidden;
        margin-bottom : 80px;
    }

    .kashira01{
        margin-bottom : 20px;
    }

    .kashira_link a{
        font-size     : 1.6rem;
        width         : 18%;
        margin-right  : 2%;
        margin-bottom : 10px;


        &:hover{
            background-color : #F37321;
            color            : #fff;
        }
    }

    .kashira_wrap{
        margin-top : 0px;
    }

    .kashira{
        display     : inline;
        font-size   : 2rem;
        padding-top : 80px;
    }

    #glossary{
        tr{
            width : 100%;
        }


        th{
            display       : block;
            text-align    : left;
            font-size     : 1.4rem;
            padding       : 5px 0;
            color         : #D87E2E;
            padding-right : 20px;
            width         : 100%;
        }


        td{
            display       : block;
            font-size     : 1.4rem;
            padding       : 5px 0;
            color         : #505050;
            width         : 100%;
            margin-bottom : 10px;
        }


        table{
            margin-bottom : 60px;
            width         : 100%;
            table-layout  : fixed;
        }


        tbody{
            width : 100%;
        }
    }

    /*============================================================

  CONTACT

  ============================================================*/

    #contact{

        .phone{

            padding : 40px 0;


            .txt01{
                font-size     : 1.4rem;
                line-height   : 2.7rem;
                text-align    : center;
                margin-bottom : 20px;
            }


            .tell{
                font-size : 3rem;
            }
        }


        .headstyle03{
            margin-bottom : 20px;
        }


        .form_area{
            padding : 40px 0;


            th{
                width         : 100%;
                text-align    : left;
                font-weight   : normal;
                display       : block;
                margin-bottom : 10px;
            }
        }


        .form_company, .name, .address, .content{
            width     : 100%;
            font-size : 1.4rem;
        }


        .phone_number{
            width : 20%;
        }

    }

    /*確認画面---------------------------------------------------*/

    #confilm{
        .policy_link, .privacy_check, .required{
            display : none;
        }


        tbody{
            margin-bottom : 60px;
            display       : table-row-group;
        }


        th{
            width          : 20%;
            text-align     : left;
            margin-right   : 10%;
            font-size      : 1.6rem;
            font-weight    : bold;
            line-height    : 2.4rem;
            margin-bottom  : 20px;
            display        : inline-block;
            vertical-align : top;
        }


        td{
            width          : 70%;
            text-align     : left;
            font-size      : 1.6rem;
            line-height    : 2.4rem;
            margin-bottom  : 30px;
            display        : inline-block;
            vertical-align : top;
        }


        .back{
            display            : block;
            width              : 280px;
            height             : 50px;
            margin             : 0 auto;
            color              : #fff;
            font-size          : 2rem;
            font-weight        : bold;
            background-color   : #F37321;
            border             : none;
            cursor             : pointer;
            border-radius      : 5px;
            -webkit-transition : all 0.3s ease;
            transition         : all 0.3s ease;
            margin-bottom      : 100px;
        }


        .transmission{
            display            : block;
            width              : 280px;
            height             : 50px;
            margin             : 0 auto;
            color              : #fff;
            font-size          : 2rem;
            font-weight        : bold;
            background-color   : #F37321;
            border             : none;
            cursor             : pointer;
            border-radius      : 5px;
            -webkit-transition : all 0.3s ease;
            transition         : all 0.3s ease;
            margin-bottom      : 20px;


            &:hover{
                background-color : #ffa76e;
            }
        }


        .back:hover{
            background-color : #ffa76e;
        }


        .mailform-tbl{
            width : 100%;
        }
    }

    /*============================================================

  フッター

  ============================================================*/

    #footer .footer_inner{
        width          : 90%;
        margin         : 0 auto;
        padding-bottom : 30px;
    }

    .footer_logo{
        /*width: 120px;*/
        margin-bottom : 20px;
        font-size     : 2rem;
    }

    .footer .fa{
        font-size : initial;
    }

    #footer_nav > ul > li{

        &:nth-child(even){
            margin-right : 0%;
        }

    }

    .footer_nav02{
        text-align : center;


        p{
            width         : 100%;
            display       : inline-block;
            font-size     : 1.1rem;
            margin-top    : 15px;
            margin-bottom : 15px;
            text-align    : center;
        }


        ul{
            width : auto;
        }
    }

    .footer_product_nav_wrap{
        display : none;
    }

    #bellows-main-239{
        display : block;
    }

    .footer_product_nav_wrap_sp{
        display : block;


        p{
            text-align  : center;
            font-size   : 2rem;
            color       : #fff;
            padding-top : 20px;
        }
    }

    .bellows.bellows-skin-blue-material{

        background-color : #4C4C4E !important;
        box-shadow       : none !important;

    }

    .fp_nav_title{
        font-size : 1.6rem;
    }

    .bellows{
        .bellows-nav .bellows-target:nth-of-type(1){
            border-top : 1px solid #4c4c4c;
        }


        &.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target{
            border-bottom : 1px solid #818181 !important;
        }
    }

    #footer .bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target{
        border-bottom : none !important;
    }

    .bellows-submenu .bellows-target{
        font-size : 1.6rem !important;
        color     : #fff;
    }

    #bellows-main-239 > ul > li:nth-of-type(6){
        border-bottom : 1px solid #A3A3A3;
    }

    .bellows-nav > li{
        /*    background-image: url("../img/product/list/camera.svg") !important;*/
        background-repeat   : no-repeat !important;
        background-size     : 35px !important;
        background-position : 0 12px !important;
        background-color    : #393939 !important;
    }

    .bellows .bellows-nav{
        .bellows-menu-item-has-children > .bellows-target{
            padding-right : 90px;
            padding-left  : 50px;
        }


        .bellows-subtoggle{
            height : 100%;
            width  : 100% !important;
        }
    }

    .fa-chevron-down:before, .fa-chevron-up:before{
        font-size : 1.6rem;
        color     : #CCCCCC;
    }

    .bellows .bellows-nav{
        .bellows-subtoggle .fa{
            display    : inline-block;
            position   : absolute;
            top        : 50%;
            right      : 15px !important;
            left       : auto !important;
            width      : 16px !important;
            margin-top : -7px !important;
        }


        .bellows-submenu{
            .bellows-subtoggle .fa{
                margin-top : -10px !important;
            }


            .bellows-item-level-2 .bellows-target{
                padding-left : 0px !important;
            }
        }
    }

    a.bellows-target{
        height : 60px !important;
    }

    .bellows-custom-content.bellows-custom-content-padded{
        padding : 0px !important;
    }

    .bellows .bellows-nav .bellows-target{
        padding : 0px !important;


        &.bellows-target-w-image >{
            .bellows-image.bellows-image-padded, .bellows-target-text{
                padding : 0px !important;
            }
        }


        > .bellows-target-text{
            padding      : 19px 0 !important;
            padding-left : 50px !important;
        }
    }

    /*============================================================

  プライバシーポリシー,免責事項

  ============================================================*/

    #privacy-policy h2, #disclaimer h2{
        color         : #B16D30;
        font-size     : 2rem;
        margin-bottom : 30px;
    }

    #privacy-policy{
        p, li{
            font-size   : 1.4rem;
            line-height : 2.4rem;
            color       : #505050;
        }
    }

    #disclaimer{
        p, li{
            font-size   : 1.4rem;
            line-height : 2.4rem;
            color       : #505050;
        }
    }

    #privacy-policy li, #disclaimer li{
        text-indent  : -1.4rem;
        padding-left : 1.4rem;
    }

    #privacy-policy{
        ul{
            margin : 20px 0;
        }


        .mb60{
            margin-bottom : 30px;
        }
    }

    #disclaimer{
        .mb60{
            margin-bottom : 30px;
        }


        .mb120{
            margin-bottom : 30px;
            margin-bottom : 50px;
        }
    }

    #single_product .strong{
        margin-top : 20px;
        text-align : left;


        p{
            font-size        : 1.6rem;
            margin-bottom    : 20px;
            display          : inline-block;
            padding          : 5px 10px;
            color            : #fff;
            font-weight      : bold;
            background-color : #707070;
        }


        li{
            font-size   : 1.2rem;
            text-align  : left;
            color       : #707070;
            line-height : 2.4rem;
        }
    }

    .notcamera{
        margin-bottom : -60px !important;
    }
}

@media screen and (max-width : 770px){
    .product_detail{
        table{
            &.table-left{
                width : 100%;
            }


            &.table-right{
                width : 100%;


                th:first-of-type, td:first-of-type{
                    border-top : 0;
                }
            }
        }


        .catalog, .btn01{
            width     : 80%;
            height    : 5rem;
            font-size : 1.5rem;
        }
    }
}

@media screen and (max-width : 640px){
    .top_news h2{
        font-size   : 1.7rem;
        font-weight : normal;
    }

    .product_list{
        li img .thumb_inner{
            width       : 50%;
            padding-top : 0px;
        }


        h2{
            margin-top : 5px;
        }
    }

    .archive_subtitle01{
        margin-bottom : 5px;
    }

    .product_new{
        height    : 25px;
        width     : 40px;
        font-size : 0.9em;
    }
}

@media screen and (max-width : 360px){
    .product_list{
        li .thumb_inner{
            height        : 50%;
            padding-top   : 0px;
            margin-bottom : 10px;
        }


        h2{
            margin-bottom : 0px;
        }
    }
}

.product_new{
    height      : 22px;
    width       : 32px;
    font-size   : 0.9em;
    padding-top : 3px;
}

@media screen and (max-width : 600px){
    .mb80{
        margin-bottom : 40px;
    }

    #top .mb80{
        margin-bottom : 60px;
    }

    .mb40{
        margin-bottom : 20px;
    }

    .pb80{
        padding-bottom : 0px;
    }


    .headstyle03{
        font-size : 2rem;
    }

    .txt01{
        font-size   : 1.4rem;
        line-height : 2.4rem;
    }

    #awards .txt02{
        font-size   : 1.2rem;
        line-height : 2.4rem;
    }

    .inner05{
        width : 100%;
    }

    /*============================================================

  ヘッダー

  ============================================================*/

    /*============================================================

  下層ページナビ

  ============================================================*/
    /*============================================================

  TOPページ

  ============================================================*/
    /*新着ニュース------------------------------------------------*/

    .top_news_right_container{
        display        : block;
        overflow       : auto;
        padding-bottom : 10px;
    }

    .top_slider_dots{
        display : none !important;
    }

    /*製品情報一覧-------------------------------------------*/

    #top{

        .page_product_box_slider{
            margin-top    : 20px;
            margin-bottom : 40px;
        }


        .ChangeElem_Panel{
            visibility : hidden;
            position   : absolute;
            overflow   : hidden;


            &.is-show{
                visibility : visible;
                position   : relative;
                overflow   : visible;
            }
        }


        .slick-list{
            width  : 400px;
            margin : auto;
        }

    }

    .slick-prev{

        left : 0 !important;

    }

    .slick-next{

        right : 0 !important;

    }

    .slick-prev, .slick-next{

        position   : absolute !important;
        display    : block !important;
        width      : 35px !important;
        height     : auto !important;
        transform  : translate(0, 0) !important;
        background : #d9d9d9 !important;
        top        : 0 !important;
        bottom     : 0 !important;
        z-index    : 100;

    }

    .top_product_pc{
        display : none;
    }

    .top_product_sp{
        display : block;
    }

    /*ソリューション-------------------------------------------*/

    .top_solution_wrap{
        padding : 40px 0;
    }

    .solution_box_item{
        width : 100%;


        &:nth-of-type(1){
            margin-right : 0;
        }


        .solution_box_item{
            .txt01{
                font-size : 1.5rem;
            }


            .txt02{
                font-size   : 1.2rem;
                line-height : 1.5rem;
            }
        }
    }

    .home .solution_box_item .txt01{
        font-size : 1.7rem;
    }

    /*============================================================

  ABOUTページ

  ============================================================*/
    /*沿革------------------------------------------------------*/

    #history .inner02{
        width       : 90%;
        margin      : 0 auto;
        padding-top : 40px;
    }

    .flow_box_item p:nth-of-type(2), .flow_box_right p:nth-of-type(2){
        font-size : 1.4rem;
    }

    /*受賞歴------------------------------------------------------*/

    #awards .rbox01{
        width     : 100%;
        font-size : 1.2rem;
    }

    /*ハンファーグループについて-------------------------------------*/

    .sales_box_item{
        p{
            font-size   : 2rem;
            padding-top : 20px;
        }


        span{
            &.big{
                font-size : 3rem;
            }


            &.small{
                font-size   : 1.4rem;
                display     : block;
                margin-top  : 0px;
                line-height : 2.5rem;
                padding     : 0 5px;
            }
        }
    }

    .award_sp_parts img{
        width     : 80%;
        max-width : none;
    }

    #group .flow_box{
        position      : relative;
        width         : 100%;
        margin        : 0 auto;
        padding-right : 0%;
    }

    /*============================================================

  ソート共通部分

  ============================================================*/
    /*============================================================

  PRODUCT

  ============================================================*/


    #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a{
        font-size : 1.2rem;
    }

    #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a, .nvr_wrap li a{
        padding : 10px 5px;
    }

    /*ここから製品詳細ページ--------------------------------------*/

    .product_detail{
        margin-top    : 20px;
        margin-bottom : 20px;


        .main{
            img{
                width : 100%;
            }


            .item2, .item3, .item4{
                display : none;
            }
        }
    }

    .product_detail_left{
        width      : 100%;
        text-align : center;
        float      : none;
        margin     : 0 auto;
    }

    .product_detail h1{
        font-size : 2.4rem;
    }

    .product_detail_right{
        width : 100%;
    }

    .spec_a{
        li{
            width            : 30%;
            margin-bottom    : 5%;
            height           : 0;
            padding-bottom   : 30%;
            background-color : #f5f5f5;
            border-radius    : 10px;
            text-align       : center;
        }


        &::after{
            content : "";
            display : block;
            width   : 30%;
        }
    }

    .spec_b{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-pack  : justify;
        justify-content   : space-between;
        -webkit-box-align : stretch;
        align-items       : stretch;
        flex-wrap         : wrap;
        margin-bottom     : 50px;


        li{
            margin-bottom : 10px;
            font-size     : 1.4rem;
            text-align    : center;
            padding       : 7px 0;
            width         : 48%;
        }
    }

    #spec_b_on{
        background-color : #ff8c00;
        color            : #ffffff;
    }

    #single_product{
        .delivery_products_wrap{
            padding        : 40px 0;
            padding-bottom : 65px;
        }


        .download_wrap h2, .movie_wrap h2{
            font-size     : 2rem;
            margin-bottom : 40px;
        }


        .slick-dots{
            bottom : -45px !important;
        }


        .download_wrap{
            margin     : 0 0;
            margin-top : 0px;


            table{
                width     : 100%;
                color     : #505050;
                font-size : 1.4rem;
            }


            tbody{
                border : 1px solid #CCCCCC;
            }


            table{
                th{
                    font-weight    : normal;
                    padding        : 5px 5px;
                    border-right   : 1px solid #B1B1B1;
                    font-size      : 1rem;
                    vertical-align : middle;


                    &:nth-of-type(1){
                        width : 1%;
                    }


                    &:nth-of-type(2), &:nth-of-type(4){
                        width : 15%;
                    }


                    &:nth-of-type(5){
                        width : 17%;
                    }
                }


                td{
                    text-align     : center;
                    padding        : 5px;
                    border-bottom  : 1px solid #CCCCCC;
                    border-right   : 1px solid #CCCCCC;
                    font-size      : 1rem;
                    vertical-align : middle;
                }
            }
        }


        tbody tr:nth-of-type(1){
            padding          : 10px;
            background-color : #C7C6C6;
            background-color : #C7C6C6;
        }


        .download_wrap table{
            margin-bottom : 20px;
        }
    }

    button.download_button{
        font-size          : 1.3rem;
        color              : white;
        display            : block;
        padding            : 10px 20px;
        -webkit-appearance : none;
        -moz-appearance    : none;
        appearance         : none;
        margin-top         : 15px;
        border-radius      : 5px;
        width              : 100%;
        margin             : 0 auto;
    }

    #single_product{
        .movie_wrap .video_item{
            width  : 100%;
            margin : 0 auto;
        }


        .delivery_products_wrap, .movie_wrap, .download_wrap{
            padding          : 40px 0;
            background-color : none;
        }


        .movie_wrap{
            padding-bottom : 30px;
        }


        .download_wrap{
            padding-bottom : 80px;
        }


        .product_detail02 .product_grey:nth-of-type(odd){
            background-color : #f0f0f0;
        }
    }

    .single-product .container{
        margin-bottom : 0px;
    }

    .consumer_titlebox{
        margin-top    : 20px;
        margin-bottom : 30px;
        color         : #707070;


        li{
            font-size : 1.2rem;
        }
    }

    /*============================================================

  SOLUTION

  ============================================================*/
    /*選ばれる理由------------------------------------------------*/

    .reason_box{
        width  : 90%;
        margin : 0 auto;
    }

    .reason_box_item{
        width : 26%;


        span{
            font-size  : 1.2rem;
            text-align : center;
        }
    }

    #reason{
        .headstyle03{
            font-size     : 1.8rem;
            margin-bottom : 10px;
        }


        h3{
            margin-top    : 15px;
            margin-bottom : 10px;
            font-size     : 1.4rem;
        }


        .txt01{
            margin-bottom : 40px;
            font-size     : 1.4rem;
        }


        .block01, .block02{
            margin-bottom : 20px;
        }


        .pt80{
            padding-top : 40px;
        }


        .mb95{
            margin-bottom : 40px;
        }
    }

    .reason_box_item img{
        margin-bottom : 10px;
        max-width     : 100%;
    }

    /*製品情報一覧-----------------------------*/

    .page_product_box_item{
        width  : 80%;
        margin : 0 auto;
    }

    .series_block01_item, .series_block05_item{
        width         : 100%;
        margin-bottom : 40px;
    }

    .series_block01, .series_block03, .series_block06{
        margin-bottom : 0px;
    }

    .series_block02 .lb, .series_block04 .lb{
        width         : 100%;
        margin-right  : 0%;
        margin-bottom : 20px;
    }

    .series_block02 .rb, .series_block04 .rb{
        width : 100%;
    }

    .series_block03_item{
        &:nth-of-type(1), &:nth-of-type(2){
            width         : 100%;
            margin-bottom : 40px;
        }
    }

    #t_series .series_block01{
        margin-bottom : 20px;
    }

    #x_series{
        margin-top : -120px;
    }

    .detail_link{
        padding : 60px 0 30px 0;
    }

    /*プロジェクト事例---------------------------------------------*/

    .project_list li{
        margin-bottom : 20px;
        width         : 100%;


        &:nth-of-type(2n+1), &:nth-of-type(2n){
            margin-bottom : 20px;
            width         : 48%;
        }
    }

    .read_txt{
        font-size : 1.4rem;
    }

    #single_case{
        h1{
            font-size     : 2rem;
            color         : #F37321;
            margin-top    : 30px;
            margin-bottom : 5px;
        }


        .case_term{
            text-decoration : underline;
            margin-bottom   : 15px;
        }


        .thumb{
            margin-bottom : 40px;
        }


        .read_txt{
            color         : #505050;
            line-height   : 2.7rem;
            margin-bottom : 60px;
        }


        .detail{
            margin-bottom : 40px;


            h2{
                font-size     : 1.6rem;
                color         : #D87E2E;
                margin-bottom : 20px;
            }


            p{
                font-size     : 1.4rem;
                margin-bottom : 40px;
                line-height   : 2.7rem;
                color         : #505050;
            }
        }


        .delivery_products_wrap{
            background-color : #f0f0f0;
            padding          : 40px 0 70px 0;
        }
    }

    .delivery_products_wrap h2{
        font-size     : 2rem;
        text-align    : center;
        color         : #F37321;
        margin-bottom : 40px;
    }

    #single_case.container{
        margin-bottom : 0;
    }

    .case_rb{
        padding : 12px;
    }

    #archive_case{
        .read_txt{
            font-size   : 1.3rem;
            text-align  : justify;
            line-height : 2rem;
        }


        h2{
            margin-bottom : 4px;


            a{
                font-size     : 1.4rem;
                margin-bottom : 5px;
            }
        }


        .case_term{
            margin-bottom : 0px;


            a{
                font-size : 1.3rem;
            }
        }


        .case_place{
            font-size     : 1.3rem;
            margin-bottom : 4px;
        }


        .case_time{
            font-size     : 1.3rem;
            margin-bottom : 8px;
        }


        .wp-pagenavi{
            margin-top : 15px;
        }
    }

    /*カルーセル---------------------------------------------*/
    /* slickの初期化完了まで非表示 */

    .slider{
        display : none;


        &.slick-initialized{
            display : block;
        }


        img{
            width  : 100%;
            height : auto;
        }
    }

    /* カルーセル画像 */

    .slick-dots{
        bottom : -50px;
    }

    .slider img{
        width   : 70%;
        padding : 0 0;
        margin  : 0 auto;
        display : block;
    }

    .relation_p{
        text-align  : center;
        padding     : 10px 0;
        color       : #707070;
        font-size   : 1.1rem;
        font-weight : bold;
    }

    .slider{
        .slick-prev:before, .slick-next:before{
            font-size : 1.4rem;
            color     : #fff;
            padding   : 0 20%;
        }


        .slick-slide a, .slick-prev, .slick-next{
            height : 180px;
        }
    }

    .consumer_table_wrap table{
        th{
            width     : 15%;
            font-size : 1.2rem;
        }


        &:nth-of-type(1){
            margin-bottom : 10px;
        }


        td{
            background-color : #fff;
            font-size        : 1.2rem;
        }
    }

    /*============================================================

  ニュース

  ============================================================*/
    /*============================================================

  SUPPORTページ

  ============================================================*/
    /*サポート---------------------------------------------------*/

    #support{
        .headstyle01_inner{
            margin-bottom : 50px;
        }


        .txt01{
            font-size : 1.4rem;
        }
    }

    .support_box{
        margin-bottom : 30px;
    }

    .support_box_item{
        width         : 100%;
        margin-bottom : 20px;


        h2{
            font-size   : 2.2rem;
            height      : 120px;
            line-height : 120px;
        }
    }

    #support .support_box .txt01{
        font-size   : 1.2rem;
        line-height : 2.2rem;
    }

    .support_box .link01{
        font-size : 1.4rem;
    }

    #support{
        .btn01{
            margin-bottom : 80px;
        }


        .tell{
            font-size   : 2.8rem;
            color       : #505050;
            font-weight : bold;
            text-align  : center;
        }


        .time{
            font-size  : 1.2rem;
            padding    : 10px 0;
            color      : #505050;
            text-align : center;
        }
    }

    /*FAQ---------------------------------------------------*/

    #faq .txt01{
        font-size     : 1.4rem;
        margin-bottom : 30px;
    }

    .faq_box{
        margin-bottom : 40px;
    }

    #faq .faq_box .txt01{
        font-size : 1.1rem;
    }

    .faq_content{
        margin-bottom : 60px;


        h2{
            font-size : 1.8rem;
        }
    }

    .switchbox{
        padding-top : 9px;
        width       : 100%;


        label{
            line-height : 2.4rem;
            font-size   : 1.4rem;
        }


        .answer{
            font-size    : 1.4rem;
            line-height  : 2.4rem;
            padding-left : 25px;
        }
    }

    #faq01{
        margin-top  : -100px;
        padding-top : 100px;
    }

    #faq02{
        margin-top  : -40px;
        padding-top : 40px;
    }

    /*用語集-----------------------------------------*/

    .PageTopBtn a:hover{
        text-decoration  : none;
        background-color : #cecece;
    }

    /*============================================================

  CONTACT

  ============================================================*/
    /*確認画面---------------------------------------------------*/

    #confilm{
        .policy_link, .privacy_check, .required{
            display : none;
        }


        tbody{
            margin-bottom : 60px;
            display       : table-row-group;
        }


        th{
            width         : 100%;
            text-align    : left;
            margin-right  : 0%;
            font-size     : 1.4rem;
            font-weight   : bold;
            line-height   : 2.4rem;
            margin-bottom : 10px;
        }


        td{
            width         : 100%;
            text-align    : left;
            font-size     : 1.4rem;
            line-height   : 2.4rem;
            margin-bottom : 20px;
        }


        .back{
            width         : 100%;
            color         : #fff;
            margin-bottom : 40px;
        }


        .transmission{
            display       : block;
            width         : 100%;
            margin-bottom : 20px;
            margin-top    : 40px;


            &:hover{
                background-color : #ffa76e;
            }
        }


        .back:hover{
            background-color : #ffa76e;
        }


        .mailform-tbl{
            width : 100%;
        }
    }

    /*完了画面---------------------------------------------------*/

    #complete{
        p{
            font-size     : 1.4rem;
            line-height   : 3rem;
            margin-bottom : 10px;
            text-align    : left;
        }


        .btn03{
            width         : 240px;
            margin-bottom : 40px;
            margin-top    : 60px;
        }
    }

    /*============================================================

  フッター

  ============================================================*/

    #footer p:last-child{
        line-height : 2rem;
        font-size   : 1.2rem;
    }

    .product_list h2{
        font-size     : 3vw;
        margin-bottom : 15px;
    }

    .archive_subtitle01{
        font-size : 2vw;
    }

    /*============================================================

  プライバシーポリシー,免責事項

  ============================================================*/

    #disclaimer .enactment{
        margin : 40px 0 40px 0;
    }
}

.headstyleCo{

    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 400px;
    height          : 100px;
    margin          : 0 auto;
    background      : #041442;


    @include break-point(640){

        width  : 200px;
        height : 80px;

    }


    h2{

        color       : #fff;
        font-weight : 300;
        font-size   : 1.6rem;
        margin      : 4px 0 0 0;

    }


    &-wrap{
        width : 100%;
    }


    &-inner{

        display        : block;
        color          : #e7e018;
        font-weight    : 700;
        font-size      : 3rem;
        letter-spacing : 0.1rem;

        @include break-point(640){

            font-size : 2rem;

        }

    }

}

@media screen and (max-width : 600px){

    #company .container{
        padding       : 65px 0% 0 0%;
        margin-bottom : 0;
    }

}

.philosophy{

    padding-top : 130px;
    text-align  : center;

    @include break-point(640){

        background : #FAFAFA;
        padding    : 35px 6px 60px 6px;
        margin-top : 10vw;
        width      : 90%;
        margin     : 30px auto 0;

    }


    &-txt{

        font-family : "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        color       : $baseColor;
        font-size   : 3.125vw;
        font-weight : bold;
        line-height : 1.7;
        margin-top  : 70px;

        @include break-point(640){

            font-size   : 4.6vw;
            line-height : 8vw;
            margin-top  : 25px;

        }

    }


    &-list{

        font-family : "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        width       : 55vw;
        margin      : 100px auto 0;
        text-align  : center;

        @include break-point(640){

            width          : 100%;
            margin-top     : 40px;
            padding-bottom : 35px;

        }

    }


    &-item{

        display         : flex;
        justify-content : flex-start;
        align-items     : center;
        margin-bottom   : 60px;

        @include break-point(640){

            display       : block;

            margin-bottom : 25px;

        }


        dt{

            position    : relative;
            width       : 35%;
            color       : #505050;
            font-size   : 1.8vw;
            font-weight : bold;
            text-align  : left;

            @include break-point(640){

                position      : relative;
                width         : 100%;
                font-size     : 1.6rem;
                margin-bottom : 7px;

            }


            &::after{

                content    : "";
                width      : 95%;
                border-top : 1px solid #e0e0e0;
                position   : absolute;
                z-index    : 1;
                top        : 51%;
                left       : 0;

                @include break-point(640){

                    border-top : none;

                }

            }


            .mr5{

                margin-right : 10px;

                @include break-point(640){

                    margin-right : 5px;

                }

            }
        }


        dd{

            width       : 65%;
            color       : #505050;
            font-size   : 1vw;
            font-weight : normal;
            text-align  : left;

            @include break-point(640){

                width     : 100%;
                font-size : 1.5rem;
                margin    : 0;

            }

        }

    }


    &-line{

        position      : relative;
        z-index       : 10;
        background    : #fff;
        padding-right : 5%;

        @include break-point(640){

            background    : #FAFAFA;
            padding-right : 0;

        }

    }


    &-hr{
        width      : 90%;
        height     : 1px;
        background : #cccccc;
    }

}

.quality{

    background : #FAFAFA;
    text-align : center;
    margin     : auto;

    @include break-point(640){

        padding : 0 0 40px 0;

    }


    .inner{

        @include break-point(640){

            width : 100%;

        }

    }


    &-txt{

        color       : #041442;
        font-family : "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        font-size   : 3.125vw;
        font-weight : bold;
        line-height : 6vw;
        margin-top  : 50px;

        @include break-point(640){

            font-size   : 4.6vw;
            line-height : 8vw;
            margin-top  : 25px;

        }

    }


    &-list{

        font-family : "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        width       : 95%;
        margin      : 50px auto 0;
        padding     : 60px;
        background  : #fff;
        text-align  : center;

        @include break-point(640){

            width      : 100%;
            margin-top : 40px;
            padding    : 0;
            background : #FAFAFA;

        }

    }


    &-itemWrap{
        display : inline-block;
    }


    &-item{

        margin-bottom : 32px;

        @include break-point(640){

            margin-bottom : 24px;

        }


        &:nth-last-child(1){
            margin-bottom : 0;
        }


        p{

            position    : relative;
            color       : #464646;
            font-size   : 2.1rem;
            font-weight : bold;
            text-align  : left;

            margin      : 0;

            @include break-point(640){

                font-size   : 1.4rem;
                font-weight : normal;

            }


            .mr5{

                margin-right : 15px;

                @include break-point(640){

                    margin-right : 5px;
                    font-weight  : bold;

                }

            }

        }

    }

}


/*会社概要----------------------------------------------------*/

.companyInfo{

    .inner{

        width       : 900px;
        padding-top : 0;

        @include break-point(1000){

            width : 90%;

        }

    }

}

.company_information{

    font-size : 1.6rem;
    color     : #505050;


    img{
        width         : 100%;
        border-radius : 10px;
        margin-bottom : 100px;
    }


    a{
        color : #505050;


        &:hover{
            color           : #041442;
            text-decoration : underline;
        }
    }


    dl{

        margin-bottom : 20px;
        display       : inline-block;


        dt, dd{

            display        : inline-block;
            vertical-align : top;

        }


        dt{

            color         : #464646;
            font-weight   : 500;
            width         : 140px;
            margin-bottom : 1rem;

            @include break-point(640){

                width         : 25%;
                margin-bottom : 0;
                line-height   : 2.4rem;
                font-size     : 1.2rem;

            }

        }


        dd{

            font-weight   : 400;
            width         : 78%;
            margin-left   : 0;
            margin-bottom : 1rem;
            line-height   : 3rem;

            @include break-point(640){

                width         : 72%;
                margin-bottom : 20px;
                line-height   : 2.4rem;
                font-size     : 1.2rem;

            }

        }

    }

}

@media screen and (max-width : 600px){

    .message p{
        font-size   : 1.4rem;
        line-height : 2.4rem;
    }

    #company .detail_link{
        padding : 20px 0 20px 0;
    }

}

.access{

    background : #FAFAFA;

    @include break-point(640){

        padding : 35px 0 70px 0;

    }

}

#map{

    width            : 100%;
    height           : 400px;
    background-color : grey;
    text-align       : center;

    @include break-point(640){

        height        : 150px;
        margin-bottom : 0px;

    }


    iframe{
        margin-bottom : 25px;
    }

}

.history{

    dt{
        display        : inline-block;
        width          : 20%;
        vertical-align : top;
        padding        : 2.5rem 5%;
        background     : #E2E2E2;
        border-bottom  : 1px solid #C6C6C6;


        &:nth-of-type(1){
            border-top : 1px solid #C6C6C6;
        }
    }


    dd{
        display        : inline-block;
        width          : 80%;
        vertical-align : top;
        padding        : 2rem 3%;
        border-bottom  : 1px solid #C6C6C6;


        &:nth-of-type(1){
            border-top : 1px solid #C6C6C6;
        }
    }


    &-list{

        color       : #464646;
        font-size   : 1.6rem;
        line-height : 2rem;
        text-align  : left;
        display     : flex;
        flex-wrap   : wrap;
        margin-top  : 6vw;

    }

}

@media screen and (max-width : 1400px){
    .history dt{
        padding : 2rem 3%;
    }
}

@media screen and (max-width : 600px){
    .history-list{
        font-size   : 1.1rem;
        line-height : 2rem;
    }

    .history{
        dt{
            width      : 23%;
            min-height : 35px;
            padding    : 0.75rem 1%;
            text-align : center;
        }


        dd{
            width   : 77%;
            padding : 0.75rem 3%;
        }
    }
}

.strength-first{

    color       : #464646;
    width       : 50%;
    margin      : 0 auto 5.2vw;
    font-size   : 1.145vw;
    line-height : 2.5vw;

    @include break-point(640){

        margin-bottom : 9.6vw;
        font-size     : 2.94vw;
        line-height   : 5vw;

    }
}

.strength-list{

    width : 100%;

    @include break-point(640){

        background : #f5f5f5;
        padding    : 30px 5% 10px;

    }

}

.strength-item{

    position   : relative;
    width      : 100%;
    height     : 41.6vw;
    background : #FAFAFA;

    @include break-point(640){

        position      : relative;
        width         : 100%;
        height        : auto;
        background    : #FFFFFF;
        margin-bottom : 13.33vw;

    }

}

.strength-img{

    position : absolute;
    right    : 0;
    top      : 0;
    height   : 41.6vw;

    @include break-point(640){

        position : relative;
        width    : 100%;
        height   : auto;

    }


    &._even{
        left : 0;
    }
}

.strength-wrap{

    position   : relative;
    top        : 50%;
    width      : 50%;
    text-align : left;
    transform  : translateY(-50%);

    @include break-point(640){

        position  : relative;
        top       : 0;
        width     : 100%;
        padding   : 5vw 5.5556% 11vw;
        transform : translateY(0%);

    }


    &._even{

        text-align : right;
        float      : right;

        @include break-point(640){

            text-align : left;
            float      : none;

        }

    }

}

.strength-head{

    display     : inline-block;
    position    : relative;
    font-weight : bold;
    color       : $baseColor;
    font-size   : 2.6vw;
    line-height : 5vw;
    background  : #E6E6E6;
    margin      : 0;
    padding     : 4px 10% 0;
    text-align  : right;

    @include break-point(640){

        font-size     : 4.8vw;
        line-height   : 6vw;
        background    : none;
        margin-bottom : 3.2vw;
        padding       : 0;
        text-align    : left;

    }


    &::after{

        content       : '';
        position      : absolute;
        top           : 0;
        right         : 0;
        border        : none;
        border-right  : solid 2.5vw #FAFAFA;
        border-bottom : solid 5vw transparent;

        @include break-point(640){

            content : '';
            border  : none;

        }

    }


    & + p{

        margin-top : 30px;

        @include break-point(1000){

            margin-top : 20px;

        }

        @include break-point(640){

            margin-top : 0;

        }

    }


    &._even{

        padding-right : 10%;
        padding-left  : 5%;
        text-align    : left;

        @include break-point(640){

            padding : 0;
            display : inline;

        }


        &::after{

            top           : 0;
            left          : 0;
            border        : none;
            border-left   : solid 2.5vw #FAFAFA;
            border-bottom : solid 5vw transparent;

            @include break-point(640){

                border : none;

            }
        }
    }
}

.strength-txt{

    position    : relative;
    width       : 80%;
    margin      : 30px auto 0;
    color       : #464646;
    font-size   : 1.145vw;
    line-height : 2.3vw;
    text-align  : justify;

    @include break-point(1000){

        margin-top : 20px;

    }

    @include break-point(640){

        width       : 100%;
        margin      : 0 auto;
        font-size   : 2.9333vw;
        line-height : 5.5vw;

    }

}

.strength-num{

    @include break-point(640){

        color       : rgba(97, 139, 173, 0.2);

        margin      : 0;

        position    : absolute;
        z-index     : -1;
        bottom      : -10px;
        right       : 5.5556%;
        font-family : "Renner*", sans-serif;
        font-size   : 21.33vw;
        font-weight : bold;
        font-style  : italic;

    }


    &._even{

        @include break-point(640){

            left : 0;

        }

    }

}

.sp_strength-head{

    @include break-point(640){

        margin-bottom : 3.2vw;


    }

}

@media screen and (max-width : 960px){
    .strength-first{
        width : 80%;
    }
}

.achievement{

    &-list{

        background : #f5f5f5;
        width      : 100%;
        margin-top : 6vw;

    }


    &-item{

        width           : 90%;
        margin          : 0 auto;
        padding         : 40px 20px;
        display         : flex;
        justify-content : flex-start;
        align-items     : center;
        border-bottom   : 1px solid rgba(198, 198, 198, 0.75);

        @include break-point(640){

            padding : 40px 20px;

        }


        &:nth-last-of-type(1){

            border-bottom : none;

        }

    }


    &-img{

        width        : 25%;
        height       : auto;
        margin-right : 5%;

    }


    &-txt{

        color       : #707070;
        font-size   : 2rem;
        font-weight : bold;

        margin      : 0;

        @include break-point(640){

            font-size : 1.6rem;

        }

    }


    .headstyle01_inner{

        margin-bottom : 0;

    }

}

.solution{
    padding-bottom : 0;
    text-align     : center;
}

.solution-firstImg{

    width         : 70%;
    height        : auto;
    margin-bottom : 2.6vw;

    @include break-point(640){

        width         : 90%;
        margin-bottom : 4vw;

    }

}

.solution-first{

    color         : #464646;
    font-size     : 1.145vw;
    line-height   : 2.5vw;
    text-align    : justify;

    width         : 70%;
    margin        : 0 auto;
    margin-bottom : 5.3vw;

    @include break-point(640){

        width         : 90%;
        margin-bottom : 9.6vw;
        font-size     : 2.94vw;
        line-height   : 5vw;

    }


    p{
        margin-bottom : 1vw;
    }

}

.solution-list{

    width : 100%;

    @include break-point(640){

        background : #f5f5f5;
        padding    : 16vw 5%;

    }

}

.solution-item{

    position   : relative;
    width      : 100%;
    height     : 41.6vw;
    background : #FAFAFA;
    overflow   : hidden;

    @include break-point(640){

        position   : relative;
        width      : 100%;
        height     : auto;
        background : #FFFFFF;

    }


    &:nth-of-type(1){

        @include break-point(640){

            margin-bottom : 13.33vw;

        }

    }

}

.solution-img{

    position : absolute;
    right    : 0;
    top      : 0;
    height   : 41.6vw;

    @include break-point(640){

        position : relative;
        width    : 100%;
        height   : auto;

    }


    &._even{
        left : 0;
    }

}

.solution-wrap{

    position   : relative;
    z-index    : 9999;
    top        : 50%;
    width      : 48.5%;
    text-align : left;
    transform  : translateY(-50%);

    @include break-point(640){

        overflow  : hidden;
        top       : 0;
        width     : 100%;
        padding   : 5vw 5.5556% 9vw;
        transform : translateY(0%);

    }


    &._even{

        text-align : right;
        float      : right;

        @include break-point(640){

            text-align : left;
            float      : none;

        }

    }

}

.solution-num{

    overflow    : hidden;
    color       : rgba(255, 255, 255, 0.5);
    position    : absolute;
    z-index     : 1;
    bottom      : -4vw;
    right       : 0;
    font-family : "Renner*", sans-serif;
    font-size   : 11vw;
    font-weight : bold;
    font-style  : italic;
    margin      : 0;

    @include break-point(640){

        color     : rgba(192, 223, 223, 0.34);
        z-index   : -1;
        bottom    : -7.7vw;
        right     : 0;
        font-size : 21vw;

    }


    &._even{

        left       : 0;
        text-align : left;

        @include break-point(640){

            left : 0;

        }

    }

}

.solution-head{

    color         : $baseColor;
    font-size     : 2.6vw;
    font-weight   : bold;
    line-height   : 5vw;
    text-align    : right;

    display       : inline-block;
    position      : relative;

    background    : #E6E6E6;

    margin-bottom : 2.6vw;
    padding-left  : 25%;
    padding-right : 8%;

    @include break-point(640){

        color         : #fff;
        font-size     : 4vw;
        line-height   : 9vw;

        margin        : 0;
        padding-left  : 5%;
        padding-right : 5%;

        background    : rgba(4, 20, 66, 0.9);

        position      : absolute;
        top           : 0;
        left          : 0;

    }


    &::after{

        content       : '';
        position      : absolute;
        top           : 0;
        right         : 0;
        border        : none;
        border-right  : solid 2.5vw #FAFAFA;
        border-bottom : solid 5vw transparent;

        @include break-point(640){

            right       : -13%;
            border      : none;
            border-left : solid 4.5vw rgb(16, 35, 74);
            border-top  : solid 9vw transparent;

        }

    }


    &._even{

        padding-right : 25%;
        padding-left  : 8%;
        text-align    : left;

        @include break-point(640){

            padding : 0;

        }


        &::after{

            top           : 0;
            left          : 0;
            border        : none;
            border-left   : solid 2.5vw #FAFAFA;
            border-bottom : solid 5vw transparent;

            @include break-point(640){

                border : none;

            }

        }

    }


    &.pc_none02{

        display : none;

        @include break-point(640){

            display : block;

        }

    }


    &.sp_none02{

        @include break-point(640){

            display : none;

        }

    }

}

.solution-head2,
.solution-txt{
    width  : 70%;
    margin : 0 5% 0 25%;
    color  : #464646;
}

.solution-head2._even, .solution-txt._even{
    margin : 0 25% 0 7%;
}

.solution-head2{
    font-size     : 1.5625vw;
    font-weight   : bold;
    margin-bottom : 1.56vw;


    &._even{
        margin-bottom : 1.56vw;
    }
}

.solution-txt{

    font-size   : 1vw;
    line-height : 2.3vw;
    text-align  : justify;

    @include break-point(640){

        width       : 100%;
        margin      : 0 auto;
        font-size   : 2.9333vw;
        line-height : 5.5vw;

    }


    br{

        @include break-point(640){

            display : none;

        }

    }


    &._even{

        @include break-point(640){

            margin-left : 0;

        }

    }

}

.solution .btn01{
    width     : 30%;
    height    : 3.7vw;
    font-size : 1.145vw;
    margin    : 2.6vw 25% 0 45%;


    &::after{
        font-size         : 1.4vw;
        -webkit-transform : translateY(0);
        transform         : translateY(0);
    }


    &._even{
        margin : 2.6vw 45% 0 25%;
    }
}

.product{

    color      : #464646;
    text-align : justify;

}

.product_list{

    img{

        @include break-point(1000){

            width : 100%;

        }

        @include break-point(768){

            margin-bottom : 10px;

        }

    }


    span{

        @include break-point(768){

            padding : 15px;


        }

    }

}

.product-txt{

    font-size     : 1.8rem;
    line-height   : 3.2rem;
    margin-bottom : 28px;

    @include break-point(640){

        font-size     : 2.933vw;
        line-height   : 4vw;
        margin-bottom : 2.4vw;

    }

}

.product-firstImg{

    width         : 100%;
    margin-bottom : 80px;

    @include break-point(640){

        margin-bottom : 7.46vw;

    }

}

.product-feature{

    font-size     : 1.8rem;
    margin-bottom : 12px;

    @include break-point(640){

        font-size     : 3.2vw;
        margin-bottom : 2.4vw;

    }


    &:nth-last-of-type(2){

        margin-bottom : 80px;

        @include break-point(640){

            margin-bottom : 7.46vw;

        }

    }

}

.product-detail{

    font-size     : 1.8rem;
    line-height   : 3.2rem;
    margin-bottom : 80px;

    @include break-point(640){

        font-size     : 2.93vw;
        line-height   : 5vw;
        margin-bottom : 7.46vw;

    }

}

.product-detailListWrap{

    display         : flex;
    justify-content : space-between;
    flex-wrap       : wrap;
    margin-bottom   : 80px;

    @include break-point(640){

        margin-bottom : 7.46vw;

    }

}

.product-detailList{

    width       : 47%;
    color       : #464646;
    font-size   : 2.2rem;
    line-height : 4rem;
    text-align  : left;
    display     : flex;
    flex-wrap   : wrap;

    @include break-point(640){

        width       : 100%;
        font-size   : 2.93vw;
        line-height : 5vw;

    }


    &:last-of-type{

        dt, dd{

            @include break-point(640){

                border-top : none;

            }

        }

    }


    dt{

        display        : inline-block;
        width          : 40%;
        min-height     : 90px;
        vertical-align : top;
        padding        : 2.5rem 5.5%;
        background     : #E2E2E2;
        border-bottom  : 1px solid #C6C6C6;

        @include break-point(640){

            padding    : 3vw 5%;
            min-height : fit-content;

        }


        &:nth-of-type(1){
            border-top : 1px solid #C6C6C6;
        }

    }


    dd{

        display        : inline-block;
        width          : 60%;
        vertical-align : top;
        padding        : 2rem 3%;
        border-bottom  : 1px solid #C6C6C6;

        @include break-point(640){

            padding : 3vw 3%;

        }


        &:nth-of-type(1){
            border-top : 1px solid #C6C6C6;
        }

    }
}

.product-sliderItem{
    width : 90%;


    img{
        width  : 100%;
        height : auto;
    }
}

#recruit{

    .main_img{

        display : block;

    }

}

.headstyle06-en{

    position    : relative;
    display     : inline-block;
    font-family : "Open Sans", sans-serif;
    color       : #25B2DB;
    font-size   : 6.4rem;
    font-weight : bold;
    line-height : 9rem;
    margin      : 0 0 0 1.4%;
    z-index     : 1;

    @include break-point(1000){

        font-size   : 6vw;
        line-height : 7.5vw;

    }

    @include break-point(640){

        font-size   : 11.2vw;
        line-height : 15.2vw;

    }


    &::before{

        content       : '';
        position      : absolute;
        top           : 0;
        left          : -5%;
        background    : #feec01;
        border-radius : 50%;
        width         : 40px;
        height        : 40px;
        z-index       : -1;

    }


    &::after{
        content       : '';
        position      : absolute;
        bottom        : 0;
        right         : -5%;
        background    : #feec01;
        border-radius : 50%;
        width         : 40px;
        height        : 40px;
        z-index       : -1;
    }


    &::before,
    &::after{

        @include break-point(1000){

            width  : 3.4vw;
            height : 3.4vw;

        }

        @include break-point(640){

            width  : 7.15vw;
            height : 7.15vw;

        }


    }

}

.headstyle06-jp{

    color        : #464646;
    font-weight  : normal;
    font-size    : 1.6rem;

    margin       : 0;
    padding-left : 30px;

}

.slogan{

    padding : 110px 0 50px;

    @include break-point(1000){

        padding : 60px 0 50px;

    }

    @include break-point(640){

        padding : 13.6vw 0 13.6vw 0;

    }


    .headstyle06{

        margin-bottom : 60px;

        @include break-point(640){

            margin-bottom : 5vw;

        }

    }


    &-img{

        width         : 100%;
        height        : auto;
        margin-bottom : 70px;

        @include break-point(640){

            margin-bottom : 2.935vw;

        }

    }


    &-txt{

        font-size     : 5rem;
        color         : #25B2DB;
        background    : rgba(37, 178, 219, 0.15);
        line-height   : 9rem;
        padding-left  : 3%;
        margin-bottom : 30px;

        @include break-point(640){

            font-size     : 8vw;
            line-height   : 14.13vw;
            margin-bottom : 2.24vw;

        }


        &:last-of-type{
            margin-bottom : 0;
        }
    }

}

.value{

    background : rgba(37, 178, 219, 0.15);
    padding    : 70px 0 80px 0;

    @include break-point(640){

        padding : 10.67vw 0;

    }


    &-first{

        color       : #464646;
        font-size   : 1.8rem;
        line-height : 2.4;
        font-weight : 600;
        text-align  : center;

        @include break-point(640){

            font-size     : 1.9rem;
            line-height   : 2;
            margin-bottom : 50px;

        }


        br{

            display : none;


            &.pc{

                display : block;

                @include break-point(640){

                    display : none;

                }

            }


            @include break-point(640){

                display : block;

            }

        }

    }


    &-item{

        margin-bottom : 80px;

        @include break-point(640){

            margin-bottom : 8.53vw;

        }


        &:last-of-type{
            margin-bottom : 0;
        }

    }


    &-txt{

        font-family   : "Open Sans", sans-serif;
        font-style    : italic;
        font-size     : 6.5rem;
        font-weight   : bold;
        color         : #25B2DB;
        line-height   : 9rem;
        margin-bottom : 12px;

        @include break-point(640){

            font-size     : 5.53vw;
            line-height   : 7.47vw;
            margin-bottom : 10px;

        }


        span{
            margin-right : 2.5%;
        }

    }


    &-detail{

        width       : 70%;
        color       : #464646;
        font-size   : 1.6rem;
        font-weight : 300;
        line-height : 3rem;
        margin      : 0;

        @include break-point(640){

            width     : 100%;
            font-size : 1.4rem;

        }

    }

}

.interview{

    background : #FAFAFA;
    padding    : 100px 0;

    @include break-point(640){

        height  : auto;
        padding : 14.93vw 0 21.33vw 0;

    }


    &-first{

        font-size   : 1.6rem;
        color       : #464646;
        line-height : 3rem;

        @include break-point(640){

            font-size : 1.4rem;

        }


        br{

            @include break-point(640){

                display : none;

            }

        }

    }


    &-list{

        width           : 100%;
        padding         : 7.14% 0;
        background      : linear-gradient(#FAFAFA 50%, rgba(37, 178, 219, 0.1) 50%);
        display         : flex;
        justify-content : space-evenly;
        align-items     : center;
        flex-wrap       : wrap;

        @include break-point(640){

            background     : none;
            padding-bottom : 0;

        }

    }


    &-item{

        width    : 28%;
        position : relative;

        @include break-point(640){

            width         : 100%;
            margin-bottom : 12.8%;

        }


        &:last-of-type{

            @include break-point(640){

                margin-bottom : 0;

            }

        }

    }


    &-img{
        position : relative;
        width    : 100%;
        height   : auto;
        top      : 0;
        left     : 0;
    }


    &-work{

        color       : #fff;
        font-size   : 1.2rem;
        font-weight : bold;
        width       : 35%;
        line-height : 3.6rem;
        text-align  : center;
        position    : absolute;
        top         : 0;
        right       : 0;

        @include break-point(640){

            width       : 35%;
            font-size   : 3.2vw;
            line-height : 7.2vw;

        }


        &._or,
        &._bl,
        &._sb{

            margin : 0;

        }


        &._or{

            background : #eba70c;

        }


        &._bl{
            background : #4f5ad8;
        }


        &._sb{
            background : #25b2db;
        }
    }


    &-area{

        background : #fff;
        padding    : 20px;

    }


    &-since{

        font-size : 1.2rem;
        color     : #707070;
        margin    : 0;

        @include break-point(640){

            font-size     : 2.93vw;
            margin-bottom : 6.33%;

        }

    }


    &-name{

        font-size : 2.2rem;
        color     : #464646;
        margin    : 12px 0;
        position  : relative;

        @include break-point(640){

            font-size : 5.87vw;

        }


        span{

            margin-left : 4.05%;
            position    : relative;
            bottom      : 0;
            font-size   : 1rem;
            color       : #25B2DB;

            @include break-point(640){

                font-size   : 3.2vw;
                margin-left : 4.05%;

            }

        }

    }


    &-br{

        display : none;

        @include break-point(640){

            display : none;

        }

    }


    &-job{

        font-size : 1.6rem;
        color     : #707070;
        margin    : 0;

        @include break-point(640){

            font-size : 4vw;

        }

    }


    .headstyle06{

        margin-bottom : 2.86%;

        @include break-point(640){

            margin-bottom : 6.24vw;

        }

    }

}

.work{

    color   : #464646;
    padding : 5.21% 0 12.5% 0;

    @include break-point(640){

        padding : 21.33% 0;

    }


    .headstyle06{

        margin-bottom : 5.21%;

        @include break-point(640){

            margin-bottom : 16.53vw;

        }

    }


    &-head{

        font-size  : 2.14vw;
        text-align : center;

        @include break-point(640){

            font-size : 5.87vw;

        }

    }

}

.welfare{

    margin-bottom : 6.77vw;

    @include break-point(640){

        margin-bottom : 32vw;

    }


    .work-head{

        margin-bottom : 3.125%;

        @include break-point(640){

            margin : 0 auto 70px;

        }

    }

}

.welfare-list{

    display         : flex;
    justify-content : space-between;
    align-items     : center;
    flex-wrap       : wrap;
    width           : 1000px;
    margin          : 0 auto;

    @include break-point(1000){

        width : 100%;

    }

    @include break-point(640){

        flex-direction  : column;
        justify-content : center;

    }

}

.welfare-item{

    display         : flex;
    justify-content : space-between;
    align-items     : center;
    flex-wrap       : wrap;
    width           : 45%;
    margin-bottom   : 3.5vw;

    @include break-point(640){

        display       : block;
        width         : 100%;
        margin-bottom : 60px;

    }


    &:last-of-type{

        @include break-point(640){

            margin-bottom : 0;

        }

    }

}

.welfare-img{

    width         : 100px;
    height        : auto;
    border-radius : 50%;
    margin-right  : 30px;

    @include break-point(640){

        margin  : auto auto 25px auto;
        width   : 200px;
        display : block;

    }

}

.welfare-txtWrap{

    flex : 1;

    @include break-point(640){

        width      : 100%;
        padding    : 0 15px;
        text-align : center;

    }

}

.welfare-ttl{

    font-size     : 1.15vw;
    font-weight   : bold;
    margin-bottom : 0.83vw;
    color         : #464646;

    @include break-point(640){

        font-size     : 2.1rem;
        margin-bottom : 4.8vw;
        text-align    : center;

    }

}

.welfare-txt{

    color       : #464646;
    font-size   : 1.6rem;
    line-height : 1.6;

    @include break-point(640){

        font-size     : 1.4rem;
        margin-bottom : 0;

    }

}

.office{

    text-align : center;


    &-txt{

        width       : 60%;
        margin      : 0 auto 2.5vw;
        font-size   : 1vw;
        line-height : 2.5vw;
        color       : #464646;

        @include break-point(640){

            margin-bottom : 20px;
            width         : 90%;
            font-size     : 1.4rem;
            line-height   : 5.5vw;

        }

    }


    &-slider{
        width          : 100%;
        overflow       : hidden;
        padding-bottom : 100px;
    }


    &-item{

        width : 40%;


        img{
            width  : 100%;
            height : atuo;
        }

    }


    .work-head{

        margin-bottom : 1.875vw;

        @include break-point(640){

            margin-bottom : 8vw;

        }

    }


    .slick-dots{

        bottom   : -75px;
        position : relative;

        @include break-point(640){

            display : block;

        }


        li{
            margin : 0 2px;


            button::before{
                font-size : 16px;
            }
        }


        .slick-active button::before{
            color   : #F37321;
            opacity : 1;
        }
    }


    .iner{

        margin-bottom : 60px;

    }

}

.jobs-top{
    background : rgba(37, 178, 219, 0.1);
    padding    : 4.17% 0;
}

.jobs-first{
    margin-top    : 36px;
    font-size     : 1.6rem;
    color         : #464646;
    margin-bottom : 100px;
}

.jobs-linkList{
    width      : 90%;
    max-width  : 900px;
    height     : 52px;
    padding    : 0 30px;
    background : #ffffff;
    margin     : 0 auto;
}

.jobs-linkTtl{
    float       : left;
    font-size   : 1.6rem;
    font-weight : bold;
    line-height : 5.2rem;
    color       : #464646;
}

.jobs-link{
    float       : right;
    font-size   : 12px;
    line-height : 5.2rem;
    color       : #25B2DB;
    margin-left : 60px;
    font-weight : 600;


    &:hover{
        opacity : 0.5;
    }
}

.jobs-listArea{
    width         : 90%;
    max-width     : 900px;
    margin        : 0 auto;
    margin-bottom : 24px;


    &:nth-of-type(2){
        margin-top : 130px;
    }


    &:last-of-type{
        margin-bottom : 63px;
    }
}

.jobs-ttl{

    font-size     : 3rem;
    font-weight   : bold;
    color         : #25B2DB;
    margin-bottom : 25px;

    @include break-point(640){

        margin-bottom : 30px;

    }

}

.jobs-list{

    display         : flex;
    justify-content : space-between;
    flex-wrap       : wrap;

}

.jobs-item{
    width         : 46%;
    margin-bottom : 30px;
}

.jobs-img{

    width  : 100%;
    height : auto;

}

.jobs-ttl2{
    font-size     : 2.2rem;
    color         : #25B2DB;
    margin-bottom : 18px;
}

.jobs-detail{
    font-size   : 1.6rem;
    line-height : 3rem;
    color       : #464646;
}

.jobs .btn01{
    width      : 500px;
    background : #25B2DB;


    &::after{
        -webkit-transform : none;
        transform         : none;
    }
}

@media screen and (max-width : 600px){
    .jobs-top{
        padding : 5.33vw 0;
    }

    .jobs-first{
        margin-top    : 4.8vw;
        font-size     : 3.47vw;
        margin-bottom : 10.67vw;
        font-weight   : 300;
    }

    .jobs-linkList{
        width   : 100%;
        height  : 10vw;
        padding : 0 5vw;
    }

    .jobs-linkTtl{
        font-size   : 3.47vw;
        line-height : 10vw;
    }

    .jobs-link{
        font-size   : 2.933vw;
        line-height : 10vw;
        margin-left : 3.47vw;
    }

    .jobs-listArea{
        width         : 90%;
        margin-bottom : 12vw;


        &:nth-of-type(2){
            margin-top : 16vw;
        }


        &:last-of-type{
            margin-bottom : 10.46vw;
        }
    }

    .jobs-item{
        width         : 100%;
        margin-bottom : 10.67vw;
    }

    .jobs-img{
        width         : 100%;
        margin-bottom : 5.87vw;
    }

    .jobs-ttl2{
        font-size     : 4.27vw;
        margin-bottom : 3.47vw;
    }

    .jobs-detail{
        font-size   : 3.2vw;
        line-height : 5vw;
    }

    .jobs .btn01{
        width     : 80%;
        font-size : 3.47vw;
        height    : 12vw;


        &::after{
            font-size : 4.2vw;
        }
    }
}

.interviewWrap .pc_none03{
    display : none;
}

.interviewWrap-top{
    position : relative;


    img{
        position : relative;
        width    : 100%;
        top      : 0;
        left     : 0;
    }
}

.interviewWrap-infoWrap{
    display      : inline-block;
    position     : absolute;
    font-weight  : bold;
    height       : 20.16vw;
    background   : rgba(4, 20, 66, 0.76);
    top          : 23.96vw;
    left         : 10.84vw;
    padding-left : 4.5vw;


    &::after{
        content    : '';
        position   : absolute;
        width      : 5vw;
        height     : 20.16vw;
        background : linear-gradient(-256deg, rgba(4, 20, 66, 0.76), rgba(4, 20, 66, 0.76) 50%, transparent 50%, transparent);
        top        : 0;
        right      : -5vw;
    }


    &._even{
        right         : 0;
        left          : auto;
        padding-left  : 2vw;
        padding-right : 4.5vw;


        &::after{
            background : linear-gradient(256deg, rgba(4, 20, 66, 0.76), rgba(4, 20, 66, 0.76) 50%, transparent 50%, transparent);
            left       : -5vw;
        }
    }
}

.interviewWrap-info{
    display           : inline-block;
    position          : relative;
    top               : 50%;
    -webkit-transform : translateY(-50%);
    transform         : translateY(-50%);
}

.interviewWrap-since{
    font-size     : 1.15vw;
    color         : #ffffff;
    margin-bottom : 1.67vw;
}

.interviewWrap-name{
    font-size     : 2.86vw;
    font-weight   : bold;
    color         : #ffffff;
    margin-bottom : 2.08vw;
    position      : relative;


    span{
        position  : relative;
        bottom    : 0;
        font-size : 1.15vw;
        color     : #25B2DB;
    }
}

.interviewWrap-job{
    font-size : 1.3vw;
    color     : #ffffff;
}

.interviewWrap-hr{
    display : none;
}

.interviewWrap-bottom{

    background : #F0F0F0;
    padding    : 5.21vw 0;


    .btn01{

        width      : 500px;
        background : #25B2DB;


        &::after{

            transform : none;

        }

    }

}

@media screen and (max-width : 960px){
    .interviewWrap{
        .pc_none03{
            display : block;
        }


        .sp_none03{
            display : none;
        }
    }

    .interviewWrap-infoWrap{
        height       : 30.13vw;
        top          : 26.67vw;
        left         : 5.33vw;
        padding-left : 5.33vw;


        &::after{
            content  : '';
            position : absolute;
            width    : 8vw;
            height   : 30.13vw;
            top      : 0;
            right    : -8vw;
        }


        &._even{
            right         : 5.33vw;
            padding-left  : 2vw;
            padding-right : 5.33vw;


            &::after{
                left : -8vw;
            }
        }
    }

    .interviewWrap-since{
        font-size     : 2.93vw;
        margin-bottom : 3.2vw;
    }

    .interviewWrap-name{
        text-align    : right;
        font-size     : 5.33vw;
        margin-bottom : 1.5vw;
        position      : relative;
        line-height   : 6vw;


        span{
            padding-right : 3vw;
            display       : inline-block;
            position      : relative;
            line-height   : normal;
            margin-top    : 2.13vw;
            right         : 0;
            font-size     : 2.67vw;
        }
    }

    .interviewWrap-job{
        font-size : 3.47vw;
    }

    .interviewWrap-hr{
        display    : block;
        width      : 90%;
        height     : 1px;
        background : #E6E6E6;
        margin     : 9.6vw auto;


        &:last-of-type{
            margin : 16vw auto;
        }
    }

    .interviewWrap-bottom{

        padding    : 8.53vw 0;
        margin-top : 40px;


        .btn01{

            display : none;

        }

    }

}

.interviewContent-question{

    display      : flex;
    background   : rgba(37, 178, 219, 0.1);

    color        : $baseColor;
    font-size    : 2.6vw;
    line-height  : 16.67vw;
    font-weight  : bold;

    height       : 16.67vw;
    padding-left : 13.5vw;


    span{

        color         : #25B2DB;
        font-size     : 4.69vw;
        padding-right : 60px;

    }

}

.interviewContent-answer{
    padding           : 8vw 0 10vw 0;
    display           : -webkit-box;
    display           : flex;
    flex-wrap         : wrap;
    -webkit-box-align : center;
    align-items       : center;
    -webkit-box-pack  : justify;
    justify-content   : space-between;


    img{
        width : 46vw;
    }


    &._even{
        -webkit-box-orient    : horizontal;
        -webkit-box-direction : reverse;
        flex-direction        : row-reverse;
    }
}

.interviewContent-aTxt{
    font-size    : 1.15vw;
    line-height  : 2vw;
    color        : #464646;
    display      : inline-block;
    padding-left : 13.5vw;


    &._even{
        padding-left  : 0;
        padding-right : 13.5vw;
    }
}

.interviewNav{
    width             : 50%;
    margin            : auto;
    margin-bottom     : 3.125vw;
    display           : -webkit-box;
    display           : flex;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    -webkit-box-align : center;
    align-items       : center;
}

.interviewNav-next{
    width : 35%;


    a{
        display           : -webkit-box;
        display           : flex;
        -webkit-box-pack  : justify;
        justify-content   : space-between;
        -webkit-box-align : center;
        align-items       : center;
        width             : 100%;
    }


    &._even a{
        -webkit-box-orient    : horizontal;
        -webkit-box-direction : reverse;
        flex-direction        : row-reverse;
    }


    img{
        width  : 40%;
        height : auto;
    }
}

.interviewNav-info{
    width      : 55%;
    text-align : left;


    &._even{
        text-align : right;
    }
}

.interviewNav-name{
    color         : #464646;
    font-weight   : bold;
    font-size     : 1.41vw;
    margin-bottom : 1.04vw;
}

.interviewNav-work{
    color     : #707070;
    font-size : 0.83vw;
}

.interviewNav-center{
    width      : 30%;
    text-align : center;


    img{
        width         : 14%;
        height        : auto;
        margin-bottom : 0.937vw;
    }
}

.interviewNav-txt{
    color       : #464646;
    width       : 100%;
    font-weight : bold;
    font-size   : 0.83vw;


    span{
        color     : #25B2DB;
        font-size : 1.25vw;
        padding   : 0 10%;
        position  : relative;
        top       : 0.1vw;
    }
}

.bellows{

    @include break-point(640){

        margin : 0;

    }


    &.bellows-skin-blue-material .bellows-nav{

        .bellows-item-level-0{

            &.bellows-current-menu-item > .bellows-target
            &.bellows-current-menu-ancestor > .bellows-target{

                background-color : transparent !important;
                color            : #fff !important;
                font-size        : 1.4rem !important;

            }

        }


        .bellows-submenu{
            background-color : transparent !important;
            color            : #fff !important;
            font-size        : 1.4rem !important;
        }


        .bellows-item-level-0 >{
            .bellows-target, .bellows-custom-content{
                background-color : transparent !important;
                color            : #fff !important;
                font-size        : 1.4rem !important;
            }
        }


        .bellows-submenu{
            .bellows-target{
                &:hover{
                    background-color : transparent !important;
                    color            : #fff !important;
                    font-size        : 1.4rem !important;
                }


                background-color : transparent !important;
                color            : #fff !important;
                font-size        : 1.4rem !important;
            }


            .bellows-custom-content, .bellows-widget .textwidget{
                background-color : transparent !important;
                color            : #fff !important;
                font-size        : 1.4rem !important;
            }
        }
    }

}

.bellows-nav{

    .bellows-menu-item{

        &.bellows-menu-item-86,
        &.bellows-menu-item-207{

            background-color : #2d2d2d !important;

        }

    }


    .responsive-menu-open &{

        @include break-point(1000){

            padding-top : 33px !important;

        }

        @include break-point(640){

            padding-top : 47px !important;

        }

    }


    .bellows-target > .bellows-target-title{
        /*background-image: url("../img/cog.png")!important;*/
        background-repeat   : no-repeat !important;
        background-size     : 30px !important;
        background-position : 2rem 1.2rem;
        padding-left        : 2rem !important;
    }


    .bellows-submenu .bellows-target > .bellows-target-title{
        background-image : none !important;
        margin-left      : 2rem;
        padding-left     : 2rem !important;
    }

}

@media screen and (max-width : 960px){
    .interviewContent-question{
        -webkit-box-orient    : vertical;
        -webkit-box-direction : normal;
        flex-direction        : column;
        -webkit-box-pack      : center;
        justify-content       : center;
        -webkit-box-align     : center;
        align-items           : center;
        text-align            : center;
        font-size             : 4.8vw;
        font-weight           : bold;
        line-height           : 8vw;
        height                : 37.33vw;
        padding-left          : 0;


        span{
            font-size     : 9.33vw;
            font-weight   : normal;
            padding-right : 0;
            line-height   : normal;
            margin-bottom : 3.733vw;
        }
    }

    .interviewContent-answer{
        padding               : 0;
        -webkit-box-orient    : vertical;
        -webkit-box-direction : reverse;
        flex-direction        : column-reverse;


        &._even{
            -webkit-box-orient    : vertical;
            -webkit-box-direction : reverse;
            flex-direction        : column-reverse;
        }


        img{
            width         : 100vw;
            margin-bottom : 5.73vw;
        }
    }

    .interviewContent-aTxt{
        width         : 90%;
        font-size     : 3.1vw;
        line-height   : 5vw;
        padding-left  : 0;
        margin        : 0 auto;
        margin-bottom : 12.8vw;


        &._even{
            padding-right : 0;
        }


        &._last{
            margin-bottom : 0;
        }
    }

    .interviewNav{
        width         : 90%;
        margin-bottom : 0;
    }

    .interviewNav-next{
        width : 30%;


        a, &._even a{
            -webkit-box-orient    : vertical;
            -webkit-box-direction : normal;
            flex-direction        : column;
        }


        img{
            width         : 75%;
            margin-bottom : 3.2vw;
        }
    }

    .interviewNav-info{
        width      : 100%;
        text-align : center;


        &._even{
            text-align : center;
        }
    }

    .interviewNav-name{
        font-size     : 4.27vw;
        margin-bottom : 3vw;
    }

    .interviewNav-work{
        font-size : 2.93vw;
    }

    .interviewNav-center img{
        width         : 20%;
        margin-top    : 6vw;
        margin-bottom : 2.67vw;
    }

    .interviewNav-txt{
        font-size : 3.2vw;


        span{
            font-size : 5.07vw;
            top       : 0.5vw;
        }
    }
}

@media screen and (max-width : 600px){
    .sp_none02{
        display : none;
    }
}
