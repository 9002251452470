$experimental-support-for-mozilla   : false;
$experimental-support-for-microsoft : false;
$experimental-support-for-opera     : false;

$imgPath                            : "./images";
$pdfPath                            : "./pdf";

$baseWidth                          : 1170px;
$baseColor                          : #041442;
$baseColor2                         : #25b2db;
$hoverColor                         : #7a8402;
$accentColor                        : #f37321;
$btnColor                           : #FFA500;
$btnHoverColor                      : #ffa76e;
$fontFamily                         : 'Roboto', 'Noto Sans JP', "游ゴシック体", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "sans-serif";
$fontColor                          : #333;
