@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

body {
  font-size: 1em;
  font-family: "Roboto", "Noto Sans JP", "游ゴシック体", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "sans-serif";
  color: #333;
  letter-spacing: -0.001em;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pwid";
  overflow-x: hidden; }
  @media screen and (min-width: 1200px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 1200px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 960px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 770px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 600px) {
    body {
      font-size: 1.4rem;
      width: 100%; } }
  @media screen and (max-width: 544px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 414px) {
    body {
      width: 100%; } }
  @media screen and (max-width: 380px) {
    body {
      width: 100%;
      font-size: 14px;
      font-size: 1.4rem; } }
  @media screen and (max-width: 360px) {
    body {
      width: 100%; } }
  html[lang="ko"] body {
    font-family: "Helvetica Neue", Helvetica, 'Noto Sans KR', Arial, sans-serif; }
  html[lang="zh"] body {
    font-family: "Helvetica Neue", Helvetica, 'Noto Sans SC', Arial, sans-serif; }

svg:not(:root) {
  overflow: hidden; }

.subscription form {
  text-align: center;
  position: relative;
  margin: 30px auto 40px; }

input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button; }

input[type="submit"] {
  border-radius: 0;
  outline: none; }

input[type="number"] {
  width: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #454545 inset;
  -webkit-text-fill-color: white !important; }

button {
  cursor: pointer; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

img {
  max-width: 100%;
  vertical-align: bottom;
  height: auto; }
  .ie8 img {
    width: auto; }

table {
  text-align: left;
  table-layout: fixed;
  width: 100%; }

* {
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
  *:before, *:after {
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1; }

a {
  color: #041442;
  text-decoration: none;
  transition: all 0.2s 0s ease; }
  a:hover {
    color: #7a8402;
    text-decoration: none; }
  a:active, a:hover {
    outline: 0; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

dd {
  margin: 0; }

.clearfix {
  *zoom: 1; }
  .clearfix::after {
    content: '';
    display: block;
    clear: both; }

.border-box {
  box-sizing: border-box; }

/*============================================================

全ページ共有

============================================================*/
.btn01 {
  color: #fff;
  font-size: 2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #FFA500;
  width: 380px;
  height: 60px;
  margin: 0 auto;
  padding: 0 10px;
  transition: all 0.3s ease;
  outline: none; }
  .btn01:hover {
    background-color: #ffa76e; }
  .btn01::after {
    content: ">";
    font-size: 2.5rem;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 25px;
    height: 25px;
    margin: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  @media screen and (max-width: 600px) {
    .btn01 {
      font-size: 1.8rem;
      width: 80%;
      height: 60px; } }

.btn02 {
  border-radius: 5px;
  background-color: #FFA500;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 1.6rem;
  width: 300px;
  margin: 0 auto;
  display: block;
  border: none;
  transition: all 0.3s ease;
  outline: none; }
  .btn02:hover {
    border-radius: 5px;
    background-color: #ffa76e; }
  @media screen and (max-width: 960px) {
    .btn02 {
      margin-bottom: 60px; } }

.btn03 {
  border-radius: 5px;
  border: none;
  background-color: #041442;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 2rem;
  width: 560px;
  margin: 0 auto;
  display: block;
  transition: all 0.3s ease;
  outline: none; }
  @media screen and (max-width: 600px) {
    .btn03 {
      width: 100%; } }
  .btn03:hover {
    background-color: #ffa76e; }

p,
time {
  color: #505050; }

section {
  padding: 60px 0 80px; }
  section.strength {
    padding-bottom: 0; }

@media screen and (max-width: 600px) {
  section {
    padding: 40px 0; } }

.pc_inner, .inner {
  width: 900px;
  margin: 0 auto; }

@media screen and (min-width: 961px) and (max-width: 1400px) {
  .pc_inner, .inner {
    width: 900px;
    margin: 0 auto; } }

.inner02, .inner03 {
  width: 900px;
  margin: 0 auto;
  padding-top: 60px; }

.inner04 {
  width: 800px;
  margin: 0 auto; }

.inner05 {
  width: 900px;
  margin: 0 auto; }

@media screen and (min-width: 961px) {
  .pc_none {
    display: none; } }

@media screen and (min-width: 601px) {
  .pc_none02 {
    display: none; } }

@media screen and (max-width: 960px) {
  .sp_none {
    display: none; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold; }

.headstyle01 {
  text-align: center; }

.headstyle01_inner {
  display: inline-block;
  font-size: 3rem;
  color: #041442;
  padding: 0 20px 0 20px;
  border-bottom: 4px #041442 solid;
  margin-bottom: 3.125vw; }

@media screen and (max-width: 960px) {
  .headstyle01_inner {
    display: inline-block;
    font-size: 3rem;
    padding: 0 20px 0 20px;
    margin-bottom: 6.25vw; } }

@media screen and (max-width: 600px) {
  .headstyle01_inner {
    font-size: 2.2rem;
    padding: 0 10px 0 10px;
    border-bottom: 2px #041442 solid;
    margin-bottom: 8vw; } }

.headstyle02 {
  text-align: center; }

.headstyle02_inner {
  display: inline-block;
  font-size: 40px;
  color: #041442;
  padding: 0 20px 0 20px;
  margin-bottom: 80px; }

.headstyle03 {
  color: #041442;
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 40px; }

.headstyle04 {
  color: #041442;
  text-align: left;
  font-size: 4rem;
  font-weight: bold;
  line-height: 6.8rem;
  padding-left: 28px;
  margin-bottom: 24px;
  border-left: 17px solid #041442; }

@media screen and (max-width: 1400px) {
  .headstyle04 {
    font-size: 3rem;
    line-height: 5.1rem;
    padding-left: 20px;
    margin-bottom: 20px;
    border-left: 14px solid #041442; } }

@media screen and (max-width: 600px) {
  .headstyle04 {
    font-size: 5.067vw;
    line-height: 8vw;
    padding-left: 3.2vw;
    margin-bottom: 2.4vw;
    border-left: 2.9333vw solid #041442; } }

.headstyle05 {
  color: #464646;
  text-align: left;
  font-size: 3rem;
  font-weight: 500;
  line-height: 5.1rem;
  padding-left: 24px;
  margin-bottom: 28px;
  border-left: 12px solid #041442; }

@media screen and (max-width: 960px) {
  .headstyle05 {
    font-size: 2.4rem;
    line-height: 4rem;
    padding-left: 16px;
    margin-bottom: 24px;
    border-left: 10px solid #041442; } }

@media screen and (max-width: 600px) {
  .headstyle05 {
    font-size: 4vw;
    line-height: 6.8vw;
    padding-left: 2.4vw;
    margin-bottom: 2.4vw;
    border-left: 2.1333vw solid #041442; } }

.table {
  display: table; }

.fs0 {
  font-size: 0; }

.flex {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px; }

.item {
  display: -webkit-box;
  display: flex;
  text-align: center;
  padding: 20px;
  width: 45%; }

.container {
  padding-top: 99px; }

#contact input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #505050 !important; }

.container02 {
  padding-top: 180px; }

.container03 {
  padding-top: 100px; }

.color_orange01 {
  color: #041442; }

.color_orange02 {
  color: #B16D30; }

.color_grey01 {
  color: #F0F0F0; }

.bgcolor_grey01 {
  background-color: #f5f5f5;
  width: 100%; }

.bgcolor_grey02 {
  background-color: #f5f5f5;
  width: 100%;
  padding: 100px 0; }

.bgcolor_grey03 {
  background-color: #f5f5f5; }

.center {
  width: 100%;
  margin: 0 auto;
  display: block; }

.center02 {
  text-align: center; }

/*マージン・パディング*/
.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt80 {
  margin-top: 80px; }

.mt100 {
  margin-top: 100px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr15 {
  margin-right: 15px; }

.mr20 {
  margin-right: 20px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb40 {
  margin-bottom: 40px; }

.mb54 {
  margin-bottom: 54px !important; }

.mb70 {
  margin-bottom: 70px; }

.mb80 {
  margin-bottom: 80px; }

.mb95 {
  margin-bottom: 95px; }

.mb60 {
  margin-bottom: 60px; }

.mb120 {
  margin-bottom: 120px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 20px; }

.ml25 {
  margin-left: 25px; }

.pt5 {
  padding-top: 5px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pt20 {
  padding-top: 20px; }

.pt40 {
  padding-top: 40px; }

.pt72 {
  padding-top: 72px; }

.pt80 {
  padding-top: 80px; }

.pt100 {
  padding-top: 100px; }

.pt120 {
  padding-top: 120px; }

.pt178 {
  padding-top: 178px; }

@media screen and (min-width: 961px) and (max-width: 1400px) {
  .pt178 {
    padding-top: 261px; } }

.pr5 {
  padding-right: 5px; }

.pr10 {
  padding-right: 10px; }

.pr15 {
  padding-right: 15px; }

.pr20 {
  padding-right: 20px; }

.pb5 {
  padding-bottom: 5px; }

.pb10 {
  padding-bottom: 10px; }

.pb15 {
  padding-bottom: 15px; }

.pb20, .pb40 {
  padding-bottom: 20px; }

.pb80 {
  padding-bottom: 80px; }

.pl5 {
  padding-left: 5px; }

.pl10 {
  padding-left: 10px; }

.pl15 {
  padding-left: 15px; }

.pl20 {
  padding-left: 20px; }

/*フォントサイズ*/
.txt01 {
  font-size: 2.2rem;
  line-height: 3rem;
  text-align: justify;
  color: #505050;
  font-weight: 500; }

/*============================================================

ヘッダー

============================================================*/
#header {
  width: 100%;
  background: #041442;
  z-index: 99;
  position: fixed;
  z-index: 99999; }

@media screen and (max-width: 1400px) {
  #header-nav li {
    font-size: 1.5rem; } }

.main_topImg {
  margin-bottom: -1px;
  position: relative; }
  .main_topImg img {
    width: 100%; }
  @media screen and (max-width: 600px) {
    .main_topImg ._big {
      letter-spacing: 3vw;
      font-size: 20vw; } }
  @media screen and (max-width: 600px) {
    .main_topImg p {
      font-size: 4.5vw; } }

.main_topImg_text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  .main_topImg_text ._big {
    color: #fff;
    letter-spacing: 1.8vw;
    font-family: "Renner*", sans-serif;
    font-size: 9.4vw;
    font-weight: bold;
    line-height: 1; }
  .main_topImg_text p {
    color: #fff;
    font-size: 1.9vw; }
  .main_topImg_text span {
    font-size: 2.4rem;
    margin-top: 37px;
    letter-spacing: 4px;
    display: block; }
    @media screen and (max-width: 600px) {
      .main_topImg_text span {
        margin-top: 28px;
        font-size: 3.7vw; } }

.main_img {
  margin-bottom: -1px;
  position: relative; }
  @media screen and (max-width: 600px) {
    .main_img {
      display: none; } }
  .main_img img {
    width: 100%; }

.main_img_text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Open Sans", sans-serif; }
  .main_img_text ._big {
    color: #fff;
    font-size: 4.2vw;
    font-weight: 800;
    font-style: italic;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .main_img_text ._big {
        font-size: 10.47vw; } }
  .main_img_text h1 {
    color: #fff;
    font-size: 1.1vw;
    font-weight: bold; }
    @media screen and (max-width: 600px) {
      .main_img_text h1 {
        font-size: 3.73vw; } }

/*  グローバルメニュー- */
#header-inner {
  display: inline-block; }

#header-nav {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 30px; }
  @media screen and (max-width: 1200px) {
    #header-nav {
      padding: 0 20px; } }
  @media screen and (max-width: 960px) {
    #header-nav {
      display: none; } }
  #header-nav #logo {
    width: 450px;
    position: relative; }
    @media screen and (max-width: 960px) {
      #header-nav #logo {
        height: 65px;
        width: 240px;
        border-bottom: none; } }
    #header-nav #logo a {
      width: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center; }
      #header-nav #logo a:visited {
        color: #ffffff; }
      #header-nav #logo a img {
        z-index: 1;
        width: 100%; }
        @media screen and (max-width: 600px) {
          #header-nav #logo a img {
            width: 100%;
            padding-top: 15px; } }
    #header-nav #logo + ul {
      display: flex;
      align-items: center;
      text-align: center; }
      @media screen and (max-width: 960px) {
        #header-nav #logo + ul {
          display: none; } }
      #header-nav #logo + ul > li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        transition: all 0.3s ease;
        position: relative; }
        #header-nav #logo + ul > li:hover {
          background-color: #000; }
          #header-nav #logo + ul > li:hover ul {
            display: block; }
    @media screen and (max-width: 960px) {
      #header-nav #logo img {
        width: 30%;
        display: block;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 0;
        position: relative;
        top: 0;
        left: 0; } }
  #header-nav a {
    color: #fff; }
  #header-nav li {
    text-align: center;
    cursor: pointer;
    font-size: 1vw;
    width: 110px; }
    @media screen and (max-width: 1200px) {
      #header-nav li {
        width: 100px; } }
    #header-nav li:hover {
      cursor: pointer; }
  #header-nav .menu-item-has-children {
    position: relative; }
    #header-nav .menu-item-has-children ul {
      display: none;
      height: 100%; }
  #header-nav .subtitle {
    font-size: 0.55vw;
    display: inline-block;
    width: 100%;
    color: #fff; }
  #header-nav .sub-menu {
    padding: 0;
    position: absolute;
    bottom: -100px;
    left: 0; }
    #header-nav .sub-menu a {
      color: #04003b;
      font-size: 1.2rem;
      display: block;
      padding: 10px 5px; }
      #header-nav .sub-menu a:hover {
        text-decoration: none; }
    #header-nav .sub-menu li {
      background-color: #fff;
      border: 1px solid #f1f1f1;
      border-top: none; }
      #header-nav .sub-menu li:hover {
        border-bottom: 1px solid #04003b; }

.single-product #menu-item-900 {
  border-bottom: 3px solid #041442; }

#menu-item-1453 {
  display: none !important; }

.page-glossary_eng #menu-item-100, .page-glossary_eng #menu-item-832 {
  border-bottom: 3px solid #041442; }

.icon_before {
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .icon_before:hover {
    color: #041442; }

/*  ナビ検索バー */
.searchform {
  position: relative; }

.searchfield {
  font-size: 1em;
  width: 175px;
  height: 30px;
  border: 0px;
  border-radius: 15px;
  background-color: #A3A3A3;
  padding: 5px 10px;
  outline: none;
  color: #fff;
  box-shadow: none; }
  @media screen and (max-width: 960px) {
    .searchfield {
      font-size: 1.6rem;
      width: 100%;
      height: auto;
      border: 0px;
      border-radius: 15px;
      background-color: #A3A3A3; } }
  .searchfield::placeholder {
    color: #fff; }

.searchsubmit {
  color: #fff;
  font-family: FontAwesome;
  font-size: 1.3em;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  background: transparent;
  position: absolute;
  top: 0px;
  right: 3px;
  left: auto;
  bottom: 0;
  cursor: pointer;
  box-shadow: none; }
  @media screen and (max-width: 960px) {
    .searchsubmit {
      right: 18px; } }
  .searchsubmit::-webkit-input-placeholder, .searchsubmit::-moz-placeholder, .searchsubmit::-ms-input-placeholder, .searchsubmit::-ms-input-placeholder, .searchsubmit::placeholder {
    color: #fff;
    padding-left: 0px;
    font-size: 0.9em; }
  .searchsubmit:hover {
    opacity: .6; }

#search h1 {
  color: #041442;
  padding: 40px;
  text-align: center; }

#search .search_not {
  margin-bottom: 70px; }
  #search .search_not h1 {
    color: #041442;
    margin-top: 40px;
    padding-bottom: 60px;
    text-align: center; }
    @media screen and (max-width: 960px) {
      #search .search_not h1 {
        color: #F37321;
        margin-top: 0px;
        width: 100%;
        padding: 60px 0;
        text-align: center;
        font-size: 1.8rem; } }
  #search .search_not .txt01 {
    text-align: center;
    margin-bottom: 40px; }

#search .product_list {
  margin-bottom: 100px; }

#search .btn03 {
  width: 350px; }

/*============================================================

下層ページナビ

============================================================*/
.about_nav,
.support_nav,
.product_nav,
.solution_nav {
  width: 100%;
  background-color: #5D5D5D; }

.about_nav ul, .support_nav ul, .product_nav ul {
  display: table;
  table-layout: fixed;
  text-align: center;
  margin: 0 auto; }

.solution_nav ul {
  display: table;
  table-layout: fixed;
  text-align: center;
  width: 900px;
  margin: 0 auto; }

.product_nav_inner {
  text-align: center;
  line-height: 0px; }

.product_nav ul {
  width: 680px;
  display: inline-table; }

.product_nav_searchbox {
  display: inline-table;
  width: 220px;
  height: 65px;
  text-align: right;
  font-size: 1.3rem;
  color: #fff;
  vertical-align: top;
  padding-top: 18px; }

.solution_nav .current-menu-item {
  border-bottom: 3px solid #5D5D5D; }
  .solution_nav .current-menu-item:hover {
    border-bottom: 3px solid #FFA500; }

.about_nav ul li,
.support_nav ul li,
.product_nav ul li,
.solution_nav ul li {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 225px;
  height: 65px;
  font-size: 1.4rem;
  color: #fff;
  font-weight: bold;
  line-height: 2rem;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.support_nav ul li {
  width: 440px; }

.product_nav ul li {
  width: 110px;
  border-bottom: 3px solid #5D5D5D; }
  .product_nav ul li.current-menu-item, .product_nav ul li:hover {
    border-bottom: 3px solid #FFA500; }

.about_nav ul li:hover,
.support_nav ul li:hover,
.product_nav ul li:hover,
.solution_nav ul li:hover {
  background-color: #464646; }

.about_nav ul li a, .support_nav ul li a, .product_nav ul li a, .solution_nav ul li a {
  display: block;
  color: #fff;
  line-height: 65px; }

.about_nav .current_page_item, .support_nav .current_page_item, .product_nav .current_page_item, .solution_nav .current_page_item, .about_nav .current_page_item:hover, .support_nav .current_page_item:hover, .product_nav .current_page_item:hover {
  border-bottom: 3px solid #FFA500;
  background: #484848; }

.solution_nav .current_page_item:hover, .solution_nav .current_menu_item:hover {
  border-bottom: 3px solid #041442; }

.tax-video_surveillance_category #menu-item-385, .tax-video_surveillance_category #menu-item-402 {
  border-bottom: 3px solid #041442; }

.tax-peripherals_category #menu-item-385, .tax-peripherals_category #menu-item-401 {
  border-bottom: 3px solid #041442; }

.tax-accessory_category #menu-item-385, .tax-accessory_category #menu-item-400 {
  border-bottom: 3px solid #041442; }

.tax-consumer_category #menu-item-399, .tax-consumer_category #menu-item-385 {
  border-bottom: 3px solid #041442; }

.tax-video_surveillance_category #menu-item-900, .tax-peripherals_category #menu-item-900, .tax-accessory_category #menu-item-900, .tax-consumer_category #menu-item-900, .current_camera #menu-item-799, .current_video #menu-item-402, .current_storage_device #menu-item-403, .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399 {
  border-bottom: 3px solid #041442; }

.camera #menu-item-799, .camera #menu-item-900 {
  border-bottom: 3px solid #041442; }

.tax-storage_device_category #menu-item-900, .tax-storage_device_category #menu-item-403 {
  border-bottom: 3px solid #041442; }

.search #menu-item-900 {
  border-bottom: 3px solid #041442; }

/*============================================================

ソート共通部分

============================================================*/
.select_area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #f0f0f0; }
  .select_area a {
    color: #505050; }
  .select_area ul {
    text-align: center;
    width: 100%;
    position: relative; }
  .select_area li {
    font-size: 1.5rem;
    font-weight: bold;
    display: inline-block;
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid; }
    .select_area li:nth-last-child(1) {
      padding-right: 0;
      margin-right: 0;
      border-right: none; }
  .select_area .inactive {
    background-color: #f0f0f0;
    padding: 8px 10px;
    display: inline-block;
    border-radius: 15px; }
  .select_area .active {
    background-color: #fff;
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-block; }
  .select_area .all {
    background-color: #fff !important;
    padding: 8px 10px;
    border-radius: 15px;
    display: inline-block; }
  #archive_video .select_area,
  #archive_product .select_area,
  #archive_accessory .select_area,
  #archive_peripherals .select_area {
    margin-bottom: 60px; }

.select_area_inner {
  margin: 0 auto; }

#archive_case .select_area ul li:not(:first-child),
#archive_case #single_case .select_area ul li:not(:first-child),
#archive_case #archive_video .select_area ul li:not(:first-child),
#archive_case #archive_product .select_area ul li:not(:first-child),
#archive_case #archive_accessory .select_area ul li:not(:first-child),
#archive_case #archive_peripherals .select_area ul li:not(:first-child) {
  background-color: #f0f0f0;
  padding: 8px 10px;
  display: inline-block;
  border-radius: 15px; }

#archive_case .select_area ul .current-cat,
#archive_case #single_case .select_area ul .current-cat,
#archive_case #archive_video .select_area ul .current-cat,
#archive_case #archive_product .select_area ul .current-cat,
#archive_case #archive_peripherals .select_area ul .current-cat,
#archive_case #archive_accessory .select_area ul .current-cat {
  background-color: #fff !important; }

#archive_case .select_area ul li:first-child a,
#archive_case #single_case .select_area ul li:first-child a,
#archive_case #archive_video .select_area ul li:first-child a,
#archive_case #archive_product .select_area ul li:first-child a,
#archive_case #archive_peripherals .select_area ul li:first-child a,
#archive_case #archive_accessory .select_area ul li:first-child a {
  background-color: #f0f0f0;
  padding: 8px 10px;
  display: inline-block;
  border-radius: 15px; }

#archive_video .select_area_inner li:nth-of-type(2),
#archive_peripherals .select_area_inner li:nth-of-type(2),
.tax-video_surveillance_category .select_area_inner li:nth-of-type(2),
.tax-peripherals_category .select_area_inner li:nth-of-type(2),
.tax-accessory_category .select_area_inner li:nth-of-type(2),
.tax-consumer_category .select_area_inner li:nth-of-type(2) {
  display: none !important; }

#tax1 li a:hover,
#tax2 li a:hover,
#tax3 li a:hover,
#tax4 li a:hover {
  color: #041442; }

.camera_nav {
  font-size: 0; }

#tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a {
  font-size: 1.3rem;
  color: #505050;
  line-height: 2.5rem; }

#tax1 {
  display: inline-block;
  width: 286px;
  vertical-align: top;
  margin-right: 17px; }

#tax2 {
  display: inline-block;
  width: 130px;
  vertical-align: top;
  margin-right: 27px; }

#tax3 {
  display: inline-block;
  vertical-align: top;
  width: 286px;
  margin-right: 17px; }

#tax4 {
  display: inline-block;
  width: 130px;
  vertical-align: top; }

#tax1 li,
#tax3 li {
  display: inline-block;
  width: 50%;
  vertical-align: top; }

.camera_nav li .current {
  color: #041442 !important; }

.sp_net_typewrap, .analog_wrap, .net_wrap {
  display: inline-block; }

.select_strage {
  width: 100%;
  background-color: #f0f0f0;
  margin-bottom: 60px;
  padding: 30px 0; }

.strage_title {
  font-size: 0;
  margin-bottom: 20px; }
  .strage_title li:nth-of-type(3) {
    margin-right: 0px; }

.strage_title02 {
  font-size: 0; }
  .strage_title02 li:nth-of-type(3) {
    margin-right: 0px; }

.strage_list_wrap {
  font-size: 0; }
  .strage_list_wrap a:hover {
    color: #041442; }

.nvr_wrap,
.dvr_wrap {
  display: inline-block;
  width: 280px;
  vertical-align: top;
  margin-right: 30px;
  font-size: 0; }

.mobile_wrap {
  display: inline-block;
  width: 280px;
  vertical-align: top;
  margin-right: 30px;
  font-size: 0;
  margin-right: 0; }

.nvr_wrap li,
.dvr_wrap li,
.mobile_wrap li {
  width: 50%;
  display: inline-block; }

.nvr_wrap li a,
.dvr_wrap li a,
.mobile_wrap li a {
  font-size: 1.2rem;
  color: #505050;
  line-height: 2.5rem; }

.strage_list_wrap .current-cat a {
  color: #041442; }

/*============================================================

TOPページ

============================================================*/
/*新着ニュース------------------------------------------------*/
.top_news {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #393939;
  padding: 25px 0; }
  @media screen and (max-width: 600px) {
    .top_news {
      display: block;
      padding: 20px 15px; } }
  .top_news article {
    display: block;
    margin-bottom: 10px; }
    .top_news article:nth-last-child(1) {
      margin-bottom: 0; }
  .top_news h2 {
    color: #fff;
    font-size: 1.6rem;
    text-align: right;
    margin: 0; }
  .top_news h3 {
    font-size: 1.5rem;
    font-weight: normal;
    display: inline-block;
    margin: 0; }
    .top_news h3 a {
      color: #fff; }
      .top_news h3 a:hover {
        text-decoration: underline; }
  .top_news time {
    color: #fff;
    font-size: 1.5rem;
    display: inline-block;
    margin-right: 20px; }
    @media screen and (max-width: 600px) {
      .top_news time {
        width: 80px;
        margin-right: 10px; } }
  .top_news_left {
    width: 200px;
    margin-right: 30px; }
    @media screen and (max-width: 600px) {
      .top_news_left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #6f6e6e; } }
    .top_news_left p {
      text-align: right;
      margin: 10px 0 0 0; }
      @media screen and (max-width: 600px) {
        .top_news_left p {
          margin: 0; } }
      .top_news_left p a {
        color: #fff;
        font-size: 1.3rem;
        display: inline-block; }
        .top_news_left p a:hover {
          text-decoration: underline; }
  .top_news_right {
    width: 600px; }
    @media screen and (max-width: 960px) {
      .top_news_right {
        flex: 1;
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .top_news_right {
        display: block; } }

/*製品一覧-------------------------------------------*/
@media screen and (max-width: 414px) {
  #top .slick-list {
    width: 330px; } }

@media screen and (max-width: 360px) {
  #top .slick-list {
    width: 230px; } }

.tab_product {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 600px) {
    .tab_product {
      height: 40px;
      margin-top: 5px; } }
  .tab_product li {
    cursor: pointer;
    width: 49%;
    height: 80px;
    font-size: 2.7rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464646;
    border-bottom: 4px solid #464646; }
    @media screen and (max-width: 770px) {
      .tab_product li {
        height: 60px;
        font-size: 2.1rem; } }
    @media screen and (max-width: 600px) {
      .tab_product li {
        height: 50px;
        font-size: 1.5rem;
        border-bottom-width: 1px; } }
    .tab_product li.ChangeElem_Btn.is-active {
      background-color: #f3f3f3; }
    .tab_product li.tabActive {
      background: #f0f0f0; }

.is-show {
  display: flex !important; }
  @media screen and (max-width: 600px) {
    .is-show {
      display: block !important; } }

.product_box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between; }

@media screen and (max-width: 414px) {
  .product_list h2 {
    font-size: 1.2rem;
    margin-bottom: 10px; } }

@media screen and (max-width: 414px) {
  .product_list li span {
    padding: 10px; } }

#product h3 {
  font-size: 2.2rem;
  color: #041442; }

/*ソリューション----------------------------------------------*/
.top_solution_wrap {
  background: #f0f0f0; }

.solution_box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center; }
  .solution_box_meta {
    padding: 20px; }

.solution_box_item {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 48%;
  position: relative;
  z-index: 1; }
  .solution_box_item:nth-of-type(1) {
    margin-right: 2%; }
  .solution_box_item:nth-of-type(2) {
    margin-left: 2%; }
  .solution_box_item img {
    width: 100%; }
  .solution_box_item .txt01 {
    color: #464646;
    margin: 0; }
  .solution_box_item .txt02 {
    color: #505050;
    font-size: 1.3rem;
    line-height: 1.6;
    margin: 10px 0 0 0; }
    @media screen and (max-width: 600px) {
      .solution_box_item .txt02 {
        color: #777;
        line-height: 1.7; } }
  .solution_box_item a {
    transition: all 3s 0s ease; }
    .solution_box_item a:hover {
      background-color: #FFF;
      filter: alpha(opacity=50);
      opacity: 0.5; }

/*お問い合わせ------------------------------------------------*/
#contact input,
#contact textarea {
  padding: 6px;
  border-radius: 0.5rem;
  width: 100%;
  border: 1px solid #aaa; }

#contact input::placeholder {
  color: #bbb; }

#contact input[type="radio"], #contact input[type="checkbox"] {
  width: auto; }

#contact textarea {
  height: 100px; }

#contact .form__row-left,
#contact .form__row-right {
  font-size: 1.5rem; }

#contact .form__row-left {
  width: 220px; }
  @media screen and (max-width: 600px) {
    #contact .form__row-left {
      width: 100%;
      margin-bottom: 6px; } }

#contact .form__row-right {
  flex: 1; }

#contact .formtitle {
  display: flex;
  align-items: center; }
  p + #contact .formtitle {
    display: none; }

#contact .wpcf7-form__row {
  display: flex;
  margin-bottom: 12px; }
  @media screen and (max-width: 600px) {
    #contact .wpcf7-form__row {
      display: block;
      margin-bottom: 20px; } }

#contact .formprivacy {
  clear: both;
  text-align: center;
  font-size: 1.4rem; }
  @media screen and (max-width: 770px) {
    #contact .formprivacy {
      font-size: 1.2rem; } }

#contact .privasylink {
  font-size: 1.4rem;
  text-align: center;
  margin: 12px 0 30px; }

#contact input[type="submit"] {
  position: relative;
  display: -webkit-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #FFA500;
  color: #fff;
  margin: 2rem auto;
  transition: all 0.3s ease;
  outline: none;
  width: 30%;
  height: 3.7vw;
  font-size: 1.145vw; }
  @media screen and (max-width: 770px) {
    #contact input[type="submit"] {
      width: 80%;
      height: 5rem;
      font-size: 1.5rem; } }

@media screen and (max-width: 960px) {
  #contact .wpcf7-list-item {
    height: 0rem;
    width: 100%; } }

@media screen and (max-width: 600px) {
  #contact .wpcf7-list-item {
    display: inline-block;
    margin: 15px 0 15px 6px; } }

#contact .form_company,
#contact .name, #contact .address {
  border: 1px solid #C8C8C8;
  border-radius: 5px;
  height: 3rem;
  width: 50%;
  font-size: 1.4rem;
  padding: 5px; }

#contact .phone_number {
  border: 1px solid #C8C8C8;
  border-radius: 5px;
  height: 3rem;
  width: 10%;
  font-size: 1.4rem;
  padding: 5px; }

#contact .form_area tr {
  width: 100%;
  font-size: 1.6rem;
  display: block;
  margin-bottom: 20px; }

#contact .form_area th {
  width: 200px;
  text-align: left;
  font-weight: normal;
  vertical-align: top;
  color: #505050; }

#contact .form_area td {
  width: 650px; }

#contact .form_area .required {
  color: #fff;
  background-color: #F37321;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 1rem;
  vertical-align: 2px;
  margin-right: 5px; }

#contact .form_area label {
  display: inline-block;
  margin-bottom: 10px; }

#contact .content {
  border: 1px solid #C8C8C8;
  border-radius: 5px;
  height: 20rem;
  width: 50%;
  font-size: 1.4rem;
  padding: 5px; }

#contact .phone .tell {
  font-size: 5rem;
  color: #505050;
  font-weight: bold;
  text-align: center;
  margin: 40px 0 20px; }
  @media screen and (max-width: 600px) {
    #contact .phone .tell {
      margin: 40px 0 10px; } }

#contact .phone,
#contact .form_area {
  padding: 60px 0; }

#contact .phone .txt01 {
  margin-bottom: 20px; }

#contact .txt01 {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
  margin: 0; }
  @media screen and (max-width: 600px) {
    #contact .txt01 {
      font-size: 1.4rem; } }
  @media screen and (max-width: 960px) {
    #contact .txt01:nth-of-type(1) {
      margin-bottom: 15px; } }

#contact .inner02,
#contact .inner03 {
  padding: 50px 0 40px; }
  @media screen and (max-width: 960px) {
    #contact .inner02,
    #contact .inner03 {
      padding: 30px 0 40px; } }

.top_contact p {
  color: #464646;
  font-size: 1.7rem;
  text-align: center;
  margin: 40px 0; }
  @media screen and (max-width: 600px) {
    .top_contact p {
      margin: 0 0 35px; } }

.top_contact_wrap {
  padding: 50px 0 80px 0; }
  @media screen and (max-width: 600px) {
    .top_contact_wrap {
      padding: 40px 0 40px 0; } }

.headstyle01_inner {
  margin-bottom: 60px;
  padding: 0 0 10px 0;
  border-bottom: 3px #041442 solid; }
  @media screen and (max-width: 600px) {
    .headstyle01_inner {
      display: inline-block;
      font-size: 2.4rem;
      margin-bottom: 30px;
      padding: 0 0 10px 0; } }
  .top_contact_wrap .headstyle01_inner,
  .history .headstyle01_inner {
    margin-bottom: 0; }
    @media screen and (max-width: 600px) {
      .top_contact_wrap .headstyle01_inner,
      .history .headstyle01_inner {
        margin-bottom: 30px; } }
  #contact .headstyle01_inner {
    margin-bottom: 40px; }
    @media screen and (max-width: 600px) {
      #contact .headstyle01_inner {
        margin-bottom: 30px; } }

/*============================================================

product

============================================================*/
/*共通-------------------------------------------------------*/
.notpost {
  font-size: 2.4rem;
  font-weight: bold;
  margin: 100px 0; }

#archive_camera .notpost {
  margin: 40px 0 100px; }

.product_select_wrap {
  width: 100%;
  background-color: #f0f0f0;
  padding: 30px 0; }

.product_select_inner {
  width: 900px;
  margin: 0 auto; }
  .product_select_inner .product_2ndnavi {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px; }

.product_2ndnavi .two_item {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 440px; }

.product_2ndnavi .three_item {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 285px; }

.product_2ndnavi .two_item span,
.product_2ndnavi .three_item span {
  display: inline-block;
  text-align: center;
  font-size: 1.4rem;
  flex-direction: column;
  background-color: #fff;
  border-radius: 25px;
  line-height: 1;
  font-weight: bold;
  color: #505050;
  width: 100%;
  border: solid 1px #F0F0F0;
  padding: 14px 10px; }

.camera_select_area02 .left_area {
  width: 440px;
  float: left; }

.camera_select_area02 .right_area {
  width: 440px;
  float: right; }

.left_area_inner ul:nth-of-type(1) {
  width: 160px;
  display: inline-block;
  margin-right: 20px; }

.left_area_inner ul:nth-of-type(2) {
  width: 70px;
  display: inline-block;
  margin-right: 70px;
  vertical-align: top; }

.left_area_inner ul:nth-of-type(3) {
  width: 100px;
  display: inline-block;
  vertical-align: top; }

.right_area_inner ul:nth-of-type(1) {
  width: 160px;
  display: inline-block;
  margin-right: 20px; }

.right_area_inner ul:nth-of-type(2) {
  width: 100px;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top; }

.right_area_inner ul:nth-of-type(3) {
  width: 100px;
  display: inline-block;
  vertical-align: top; }

.left_area_inner ul li a, .right_area_inner ul li a {
  font-size: 1.4rem;
  color: #505050;
  line-height: 2.5rem; }

.left_area_inner ul li a:hover, .right_area_inner ul li a:hover {
  color: #041442; }

.subtitle_selectarea {
  margin-bottom: 10px; }
  .subtitle_selectarea li {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    color: #505050;
    border-bottom: 1px solid #A3A3A3;
    padding-bottom: 5px;
    position: relative; }
    .subtitle_selectarea li span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.2rem; }
      .subtitle_selectarea li span a {
        color: #A3A3A3;
        right: 0;
        top: 0px;
        font-size: 1.2rem;
        color: #A3A3A3; }
        .subtitle_selectarea li span a:hover {
          color: #041442; }
    .subtitle_selectarea li:nth-of-type(1) {
      display: inline-block;
      width: 286px;
      margin-right: 15px; }
    .subtitle_selectarea li:nth-of-type(2) {
      display: inline-block;
      width: 130px; }

.product_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px 0 30px; }
  @media screen and (max-width: 960px) {
    .product_list {
      margin: 40px 0 0; } }
  .product_list li {
    width: 215px;
    height: auto;
    background-color: #fff;
    position: relative;
    border-radius: 5px;
    transition: 0.3s; }
    @media screen and (max-width: 600px) {
      .product_list li {
        width: 48%;
        height: auto; } }
    .product_list li span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -o-box-sizing: border-box;
      -ms-box-sizing: border-box; }
    .product_list li a:hover {
      background-color: #FFF;
      filter: alpha(opacity=50);
      opacity: 0.5; }
    .product_list li .thumb_inner {
      height: 60%;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center; }
  .product_list::before {
    content: "";
    display: block;
    width: 210px;
    -webkit-box-ordinal-group: 2;
    order: 1; }
  .product_list::after {
    content: "";
    display: block;
    width: 210px; }
  .product_list img {
    text-align: center;
    display: block; }
  .product_list h2 {
    color: #707070;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px; }
  #archive_consumer .product_list {
    margin-top: 60px; }

.archive_subtitle01 {
  color: #a7a7a7;
  text-align: center;
  font-size: 1.1rem; }

.product_new {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 25px;
  width: 40px;
  text-align: center;
  background-color: #041442;
  color: #ffffff;
  padding-top: 5px;
  font-size: 1em;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px; }

/*ここから製品詳細ページ--------------------------------------*/
/*============================================================

SOLUTION

============================================================*/
.product_detail {
  margin-top: 60px;
  margin-bottom: 60px; }
  .product_detail h1, .product_detail h3 {
    color: #04003b;
    margin: 0; }
  .product_detail h1 {
    font-size: 3rem;
    border-left: 10px solid #04003b;
    margin-bottom: 30px;
    padding: 7px 10px 7px 16px; }
    @media screen and (max-width: 960px) {
      .product_detail h1 {
        margin-bottom: 0; } }
  .product_detail h3 {
    font-size: 2rem;
    border-left: 9px solid #04003b;
    margin-bottom: 20px;
    padding: 7px 10px 7px 12px; }
    @media screen and (max-width: 600px) {
      .product_detail h3 {
        margin-bottom: 12px; } }
  .product_detail table {
    width: 100%;
    text-align: left; }
  .product_detail tr {
    border-top: 1px solid #e4e4e4; }
  .product_detail th, .product_detail td {
    padding: 20px 15px;
    font-size: 1.5em; }
    @media screen and (max-width: 600px) {
      .product_detail th, .product_detail td {
        font-size: 0.9em;
        padding: 12px; } }
  .product_detail th {
    background-color: #ebebeb; }
  .product_detail td {
    background-color: #fff; }
  .product_detail p {
    clear: both;
    font-size: 1.2rem;
    margin: 12px 0;
    line-height: 1.4rem; }
  .product_detail img {
    width: 100%;
    margin: 0; }
  .product_detail dl, .product_detail ul, .product_detail ol {
    font-size: 1.6rem; }
  .product_detail dt {
    font-weight: 600; }
  .product_detail_row {
    margin-bottom: 40px; }
    @media screen and (max-width: 600px) {
      .product_detail_row {
        margin-bottom: 30px; } }
  @media screen and (max-width: 960px) {
    .product_detail_left {
      width: 60%;
      text-align: center;
      float: none;
      margin: 0 auto; } }
  @media screen and (max-width: 960px) {
    .product_detail_right {
      width: 100%;
      text-align: center;
      float: none;
      margin-top: 30px; } }
  @media screen and (max-width: 960px) {
    .product_detail .main img {
      width: 100%; } }
  @media screen and (max-width: 960px) {
    .product_detail .main .item2,
    .product_detail .main .item3,
    .product_detail .main .item4 {
      display: none; } }
  .product_detail .catalog,
  .product_detail .btn01 {
    width: 320px;
    font-size: 1.145vw;
    padding: 20px 10px 19px; }
  .product_detail .catalog {
    background-color: #F5F5F5;
    margin: 0 auto 20px;
    position: relative;
    border-radius: 5px;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #555;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center; }
  .product_detail .table-left,
  .product_detail .table-right {
    width: 45%;
    float: left; }
  .product_detail .table-left {
    margin-right: 5%; }
  .product_detail .top_slider_dots {
    display: flex !important;
    justify-content: center;
    margin-top: 25px; }
    @media screen and (max-width: 600px) {
      .product_detail .top_slider_dots {
        display: none !important; } }
    .product_detail .top_slider_dots button {
      font-size: 0;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: none;
      background-color: #aaa; }
      .product_detail .top_slider_dots button:focus {
        outline: 0; }
    .product_detail .top_slider_dots li {
      margin-right: 10px; }
  .product_detail .slick-active button {
    background-color: #f37321; }
  .product_detail .btn-area {
    margin-top: 90px; }
    @media screen and (max-width: 600px) {
      .product_detail .btn-area {
        margin: 40px 0; } }

/*選ばれる理由------------------------------------------------*/
#reason .headstyle03 {
  font-size: 2.8rem;
  text-align: left;
  margin-bottom: 10px; }

#reason h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #041442; }

#reason .txt01 {
  margin-bottom: 40px; }

#reason .block01 {
  margin-bottom: 80px; }

#reason .block02 {
  margin-bottom: 60px; }

.reason_box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px; }

.reason_box_item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-bottom: 18px;
  color: #fff;
  width: 100px;
  margin-bottom: 40px; }
  .reason_box_item img {
    margin-bottom: 10px; }
  .reason_box_item span {
    color: #041442;
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center; }

/*製品情報一覧-----------------------------*/
@media screen and (max-width: 600px) {
  .top_product.inner {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .top_product_wrap {
    overflow: hidden;
    padding-top: 40px; } }

.top_product_wrap .btn01 {
  margin-top: 40px; }

.page_product_box {
  display: none;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px; }
  @media screen and (max-width: 960px) {
    .page_product_box {
      margin-bottom: 0px; } }
  .page_product_box.pageActive {
    display: -webkit-box;
    display: flex; }
  .page_product_box_meta {
    padding: 14px 20px 20px; }
    @media screen and (max-width: 600px) {
      .page_product_box_meta .txt01 {
        margin: 0; } }
    @media screen and (max-width: 600px) {
      .page_product_box_meta .txt02 {
        margin: 6px 0 0px; } }
  #product .page_product_box {
    margin-bottom: 40px; }
  .page_product_box_item {
    background-color: #f5f5f5;
    width: 32%;
    margin-bottom: 20px;
    position: relative; }
    .page_product_box_item img {
      width: 100%; }
      @media screen and (max-width: 600px) {
        .page_product_box_item img {
          object-fit: cover;
          height: 120px; } }
    .page_product_box_item a:hover {
      background-color: #FFF;
      filter: alpha(opacity=50);
      opacity: 0.5; }
  .page_product_box .txt01 {
    color: #464646;
    font-size: 1.9rem;
    line-height: 1.5;
    margin: 0 0 10px 0; }
  .page_product_box .txt02 {
    color: #505050;
    font-size: 1.3rem;
    margin: 0; }
  .page_product_box::after {
    content: "";
    display: block;
    width: 32%; }

.top_product_pc {
  display: block; }

.top_product_sp {
  display: none; }

#x_series,
#p_series,
#q_series,
#t_series,
#for_home {
  margin-top: -120px;
  padding-top: 120px; }

#product .eye_catch {
  display: block;
  width: 900px;
  margin: 0 auto;
  margin-bottom: 80px; }

#product h3 {
  color: #041442;
  font-weight: bold;
  vertical-align: top;
  text-align: left;
  margin-bottom: 20px; }

#product h4 {
  font-size: 2rem;
  color: #707070;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px; }

.series_detail p {
  font-size: 1.6rem;
  color: #505050;
  text-align: justify;
  line-height: 3rem; }

.series_block00 {
  margin-bottom: 40px; }

.series_block01,
.series_block03,
.series_block05,
.series_block06 {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px; }

#t_series .series_block01 {
  margin-bottom: 0; }

.series_block05, .series_block06 {
  margin-bottom: 20px; }

.series_block01_item, .series_block05_item {
  width: 47%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column; }

.series_block01_item p, .series_block05_item p {
  margin-bottom: 60px; }

.series_block03_item p {
  margin-bottom: 20px; }

.under_img, .img_wrap {
  margin-top: auto;
  margin-bottom: 0px !important; }

.series_block06_item {
  width: 30%; }

.series_block03_item:nth-of-type(1) {
  width: 25%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column; }

.series_block03_item:nth-of-type(2) {
  width: 70%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column; }

.series_block02 {
  font-size: 0;
  margin-bottom: 60px; }
  .series_block02 .lb {
    display: inline-block;
    width: 35%;
    margin-right: 5%;
    font-size: 1.8rem; }
  .series_block02 .rb {
    display: inline-block;
    width: 60%;
    vertical-align: top; }
    .series_block02 .rb img {
      padding-top: 5px; }

.series_block04 {
  font-size: 0; }
  .series_block04 .lb {
    display: inline-block;
    width: 28%;
    margin-right: 5%;
    font-size: 1.8rem; }
  .series_block04 .rb {
    display: inline-block;
    width: 67%;
    vertical-align: top; }
    .series_block04 .rb img {
      padding-top: 5px; }

.detail_link {
  padding: 60px 0 80px 0; }

#company .detail_link {
  padding: 40px 0 120px 0; }

#t_series .detail_link {
  margin-bottom: 100px; }

.img100 {
  width: 100%;
  margin-bottom: 40px; }

/*プロジェクト事例---------------------------------------------*/
#archive_case h1 {
  margin-top: 40px;
  margin-bottom: 60px; }

#archive_case h2 {
  color: #041442;
  font-size: 2.2rem;
  margin-bottom: 5px; }
  #archive_case h2 a {
    color: #041442;
    font-size: 2.2rem; }

.project_list li {
  margin-bottom: 20px; }
  .project_list li img {
    width: 240px;
    height: 180px;
    display: inline-block; }

.case_item {
  overflow: hidden;
  background-color: #f0f0f0; }

.case_lb {
  width: 240px;
  float: left; }
  .case_lb img {
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .case_lb img:hover {
      opacity: 0.8; }

.case_rb {
  width: 660px;
  float: right;
  height: 180px;
  padding: 20px; }
  .case_rb a:hover {
    text-decoration: underline; }

.case_term {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px; }
  .case_term a {
    color: #707070;
    font-size: 1.6rem; }

.case_place {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #707070;
  font-size: 1.6rem; }

.case_time {
  display: inline-block;
  margin-bottom: 10px;
  color: #707070;
  font-size: 1.6rem; }

.read_txt {
  font-size: 1.8rem; }

#single_case h1 {
  font-size: 2.6rem;
  color: #041442;
  margin-top: 40px;
  margin-bottom: 15px; }

#single_case .main {
  display: block;
  width: 100%;
  margin-bottom: 20px; }
  #single_case .main .item2, #single_case .main .item3, #single_case .main .item4 {
    display: none; }
  #single_case .main img {
    width: 100%;
    max-width: 100%;
    max-height: auto; }

#single_case .thumb {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 60px; }
  #single_case .thumb li {
    width: 23.5%;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-right: 2%; }
    #single_case .thumb li:nth-of-type(4) {
      margin-right: 0%; }
    #single_case .thumb li:hover {
      opacity: 0.7; }

#single_case .read_txt {
  margin-bottom: 80px; }

#single_case .detail {
  margin-bottom: 40px; }
  #single_case .detail h2 {
    font-size: 2.4rem;
    color: #041442;
    margin-bottom: 40px; }
  #single_case .detail p {
    font-size: 1.8rem;
    margin-bottom: 80px; }

#single_case .delivery_products_wrap {
  background-color: #f0f0f0;
  padding: 80px 0;
  padding-bottom: 120px; }

.delivery_products_wrap h2, .download_wrap h2, #single_product .movie_wrap h2 {
  font-size: 3rem;
  text-align: center;
  color: #041442;
  margin-bottom: 60px; }

/*カルーセル---------------------------------------------*/
.slider {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: none; }
  .slider.slick-initialized {
    display: block; }
  .slider img {
    width: 100%;
    height: auto;
    height: 120px;
    width: auto;
    margin: 0 auto;
    display: block; }

.relation_p {
  text-align: center;
  padding: 20px 0;
  color: #707070;
  font-size: 1.4rem;
  font-weight: bold; }

.slider .slick-slide a {
  display: block;
  margin: 0 10px;
  background-color: #fff;
  padding: 20px;
  height: 217px; }

.slider .slick-prev,
.slider .slick-next {
  z-index: 1;
  width: 32px;
  height: 214px;
  background-color: #E5E5E5;
  color: white;
  transition: all 0.3s ease; }

.slider .slick-prev:hover,
.slider .slick-next:hover {
  background-color: #9a9a9a; }

.slider .slick-prev:before {
  font-size: 30px;
  color: #fff; }

.slider .slick-next {
  right: -32px; }
  .slider .slick-next:before {
    font-size: 30px;
    color: #fff; }

.slider .slick-prev {
  left: -32px; }
  .slider .slick-prev::before {
    content: '';
    /*何も入れない*/
    display: inline-block;
    width: 15px;
    /*画像の幅*/
    height: 75px;
    /*画像の高さ*/
    background-image: url(img/arrow_right.svg);
    background-size: contain;
    vertical-align: middle;
    transform: scale(-1, 1); }

.slider .slick-next:before {
  content: '';
  /*何も入れない*/
  display: inline-block;
  width: 15px;
  /*画像の幅*/
  height: 75px;
  /*画像の高さ*/
  background-image: url(img/arrow_right.svg);
  background-size: contain;
  vertical-align: middle; }

#single_case .slick-dots {
  bottom: -45px !important; }

/*============================================================

ニュース

============================================================*/
/*一覧ページ--------------------------------------------------*/
#archive_news {
  margin-bottom: 80px; }
  #archive_news h1 {
    color: #04003b;
    font-size: 3.1rem;
    text-align: center;
    margin: 60px 0 40px; }
    @media screen and (max-width: 960px) {
      #archive_news h1.headstyle01_inner {
        margin: 50px 0;
        padding: 0 0 10px 0; } }
  #archive_news h2 {
    margin-top: 5px;
    margin-bottom: 0; }
    @media screen and (max-width: 960px) {
      #archive_news h2 {
        margin-top: 7px; } }
    #archive_news h2 a {
      color: #555;
      line-height: 1.6; }
      @media screen and (max-width: 960px) {
        #archive_news h2 a {
          color: #B16D30; } }
  #archive_news article {
    border-top: 1px #F0F0F0 solid;
    padding: 12px 0; }
    #archive_news article:nth-last-child(1) {
      border-bottom: 1px #F0F0F0 solid; }
  #archive_news .news_type_text {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 10px;
    margin: 0;
    color: #505050; }
  #archive_news time,
  #archive_news .news_term {
    display: inline-block;
    margin: 0; }
  #archive_news time {
    font-size: 1.4rem;
    margin-right: 6px; }
  #archive_news .news_term a {
    color: #555; }

.wp-pagenavi {
  text-align: center;
  margin: 60px 0 80px;
  transition: all 0.3s ease;
  position: relative; }
  .wp-pagenavi a, .wp-pagenavi span {
    text-decoration: none;
    border: none;
    border-radius: 7px;
    padding: 8px 10px;
    margin: 2px;
    font-size: 1.6rem;
    color: #04003b;
    transition: all 0.3s ease; }
  .wp-pagenavi a:hover,
  .wp-pagenavi span.current {
    background-color: #041442;
    color: #fff; }
  .wp-pagenavi .first, .wp-pagenavi .last, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink {
    color: #505050;
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .wp-pagenavi .first {
    position: absolute;
    left: 0;
    bottom: -10px; }
  .wp-pagenavi .last {
    position: absolute;
    right: 0;
    bottom: -10px; }
  .wp-pagenavi .first:hover, .wp-pagenavi .last:hover, .wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover {
    color: #041442;
    background-color: #fff; }

/*投稿ページ--------------------------------------------------*/
#single_news h1 {
  font-size: 2.4rem;
  margin: 0 0 30px 0; }

#single_news h2 {
  font-size: 2.1rem; }

#single_news h1, #single_news h2 {
  margin: 0 0 20px 0; }

#single_news article {
  margin: 60px 0; }

#single_news time {
  display: inline-block;
  font-size: 1.8rem;
  text-align: center; }

#single_news p {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.75;
  margin: 0 0 30px 0; }

#single_news .news_term {
  font-size: 1.2rem;
  margin: 0 0 30px 0; }
  #single_news .news_term a {
    color: #505050; }
    #single_news .news_term a:hover {
      text-decoration: underline; }

#single_news .btn02 {
  margin-bottom: 90px; }
  @media screen and (max-width: 960px) {
    #single_news .btn02 {
      margin-bottom: 60px; } }

#single_news .another_link p {
  font-size: 1.8rem;
  text-decoration: underline;
  margin-bottom: 10px;
  margin: 0; }
  #single_news .another_link p:last-child {
    margin-bottom: 30px; }
  #single_news .another_link p a {
    color: #505050;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    #single_news .another_link p a:hover {
      color: #041442; }

/*============================================================

SUPPORTページ

============================================================*/
/*サポート---------------------------------------------------*/
#support .headstyle01_inner {
  margin-bottom: 60px; }

.support_box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px; }

.support_box_item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  color: #fff;
  background-color: #f0f0f0;
  width: 48%;
  margin-bottom: 40px;
  padding-bottom: 20px; }
  .support_box_item h2 {
    width: 100%;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    height: 160px;
    line-height: 158px;
    margin-bottom: 20px;
    background-image: url("../img/support/support/support1.png");
    background-size: cover; }
  .support_box_item:nth-of-type(2) h2 {
    background-image: url("../img/support/support/support2.png"); }

.support_box .txt01 {
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-weight: normal;
  color: #505050;
  padding: 0px 20px 20px 20px;
  text-align: left; }

.support_box .link01 {
  display: block;
  width: 80%;
  text-align: center;
  background-color: #041442;
  font-size: 1.6rem;
  color: #fff;
  padding: 15px 20px 15px 20px;
  margin: 0 auto;
  margin-top: auto;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .support_box .link01:hover {
    background-color: #fdac63; }

#support .btn01 {
  margin-bottom: 120px;
  width: 360px; }

#support .tell {
  font-size: 4rem;
  color: #505050;
  font-weight: bold;
  text-align: center; }

#support .time {
  font-size: 1.6rem;
  padding: 10px 0;
  color: #505050;
  text-align: center; }

/*FAQ---------------------------------------------------*/
#faq .txt01 {
  margin-bottom: 80px;
  text-align: center; }

.faq_box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px; }

.faq_box_item {
  margin-bottom: 18px;
  color: #fff;
  background-color: #f0f0f0;
  width: 23.5%;
  height: 120px;
  z-index: 1;
  position: relative; }

.faq_box img {
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  width: 50px; }

.faq_box .txt01 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #505050;
  padding: 0px 15px 10px 15px; }

.faq_box_item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: -999px;
  z-index: 2;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .faq_box_item a:hover {
    background-color: #FFF;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5; }

.faq_content {
  margin-bottom: 120px; }
  .faq_content h2 {
    color: #041442;
    font-size: 3rem;
    padding-bottom: 30px;
    border-bottom: 1px #707070 solid; }

.switchbox {
  padding-top: 9px;
  width: 100%; }
  .switchbox label {
    display: block;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.8rem;
    color: #041442;
    padding-left: 1.7em;
    text-indent: -1.7em;
    padding-top: 11px; }
  .switchbox .answer {
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: #505050;
    padding-left: 29px;
    padding-top: 6px; }
  .switchbox input {
    display: none; }
  .switchbox div {
    height: 0;
    overflow-y: hidden;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    opacity: 0; }
  .switchbox input:checked + label + div {
    height: auto;
    opacity: 1; }
  .switchbox label::before {
    content: "\f067";
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    padding-right: 8px; }
  .switchbox input:checked + label::before {
    content: '\f068'; }
  .switchbox::after {
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    margin-top: 16px;
    border-top: 1px solid #505050; }

.faq_second {
  margin-top: 60px; }

#faq01 {
  margin-top: -120px;
  padding-top: 120px; }

/*無料ソフトウェア--------------------------------------------*/
#free .flex {
  margin-bottom: 120px; }
  #free .flex .item:nth-of-type(1) {
    height: 200px;
    font-weight: bold;
    width: 30%;
    vertical-align: middle;
    background: #041442;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(#041442), to(#e2996b));
    background: linear-gradient(to bottom, #041442 0%, #e2996b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$mainColor', endColorstr='#e2996b', GradientType=0);
    /* IE6-9 */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    /* 子要素をflexboxにより縦方向に揃える */
    -webkit-box-pack: center;
    justify-content: center;
    /* 子要素をflexboxにより中央に配置する */
    -webkit-box-align: center;
    align-items: center;
    /* 子要素をflexboxにより中央に配置する */ }
  #free .flex .item:nth-of-type(2) {
    width: 65%;
    text-align: left;
    font-size: 1.6rem;
    color: #505050;
    padding: 0;
    line-height: 3rem; }
  #free .flex .item:nth-of-type(1) p {
    color: #fff;
    font-size: 2.4rem;
    width: 100%; }

#free .flow img {
  margin-bottom: 120px; }

#free .flow_inner {
  width: 750px;
  margin: 0 auto;
  font-size: 0; }
  #free .flow_inner dl {
    position: relative; }
  #free .flow_inner dt {
    width: 60px;
    height: 60px;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
    background-color: #FF9857;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0; }
  #free .flow_inner dl:nth-of-type(3) dt, #free .flow_inner dl:nth-of-type(4) dt {
    position: absolute;
    top: -13px;
    left: 0; }
  #free .flow_inner dd {
    width: 650px;
    display: inline-block;
    text-align: left;
    padding-bottom: 30px;
    color: #505050;
    font-size: 1.6rem;
    line-height: 3rem;
    border-bottom: #707070 1px solid;
    margin-bottom: 30px;
    margin-left: 100px; }
  #free .flow_inner dl:nth-of-type(2) dd {
    margin-bottom: 45px; }
  #free .flow_inner dl:nth-of-type(3) dd {
    margin-bottom: 45px;
    padding-bottom: 45px; }
  #free .flow_inner dl:nth-of-type(4) dd {
    padding-bottom: 45px; }

#free .center {
  padding-top: 40px;
  padding-bottom: 80px; }

/*用語集-----------------------------------------------------*/
#glossary .txt01 {
  margin-bottom: 60px;
  text-align: center; }

#glossary h2 {
  color: #041442;
  padding: 10px 0;
  font-size: 2rem;
  text-align: left;
  border-bottom: 1px #041442 solid;
  margin-bottom: 20px; }

.kashira_link {
  font-size: 0;
  margin-right: -10.5px;
  overflow: hidden;
  margin-bottom: 80px; }

.kashira01 {
  margin-bottom: 20px; }

.kashira_link a {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  line-height: 5rem;
  color: #555555;
  border-radius: 5px;
  background-color: #efefef;
  display: inline-block;
  width: 50px;
  margin-right: 10.5px;
  margin-bottom: 10px; }
  .kashira_link a:hover {
    background-color: #041442;
    color: #fff; }

.kashira_wrap {
  margin-top: 40px; }

.kashira {
  display: inline;
  text-align: center;
  border-bottom: 3px solid #505050;
  font-weight: bold;
  color: #505050;
  font-size: 2.4rem;
  padding-top: 120px; }

#glossary th {
  text-align: left;
  font-size: 1.4rem;
  padding: 10px 0;
  width: 25%;
  color: #041442;
  padding-right: 25px; }

#glossary td {
  font-size: 1.4rem;
  padding: 10px 0;
  color: #505050;
  text-align: justify; }

#glossary table {
  margin-bottom: 60px; }

.PageTopBtn {
  position: fixed;
  bottom: 0px;
  right: 20px;
  font-size: 1.1rem;
  margin-bottom: 20px;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.pageTopBtn-in {
  opacity: 1;
  pointer-events: auto; }

.pageTopBtn-out {
  right: -999px; }

.PageTopBtn a {
  display: block;
  text-decoration: none;
  color: #fff;
  background: #cecece;
  text-align: center;
  border-radius: 15px;
  outline: none;
  width: 80px;
  height: 80px;
  padding: 15px 0;
  font-size: 1.2rem;
  font-weight: bold;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .PageTopBtn a:hover {
    text-decoration: none;
    background: #4b4b4b; }

#sakuin {
  margin-top: -120px;
  padding-top: 120px; }

.fa-bookmark {
  font-size: 3rem !important;
  padding-bottom: 5px; }

/*販売パートナー様募集-----------------------------------------*/
#partner .txt01 {
  color: #505050;
  font-size: 1.4em;
  line-height: 3rem;
  text-align: left; }

#partner .small {
  color: #505050;
  font-size: 1.4rem;
  text-align: right;
  margin-bottom: 60px; }

#partner .merit {
  margin-bottom: 60px; }
  #partner .merit li {
    width: 48.5%;
    background-color: #f5f5f5;
    font-size: 0;
    margin-bottom: 20px; }
    #partner .merit li .merit_img {
      display: block;
      width: 100%;
      color: #fff;
      line-height: 170px;
      margin: 0 auto;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      background-image: url("../img/support/partner/01.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      height: 180px; }
    #partner .merit li:nth-of-type(2) .merit_img {
      background-image: url("../img/support/partner/02.jpg"); }
    #partner .merit li:nth-of-type(3) .merit_img {
      background-image: url("../img/support/partner/03.jpg"); }
    #partner .merit li:nth-of-type(4) .merit_img {
      background-image: url("../img/support/partner/04.jpg"); }
    #partner .merit li .merit_txt {
      display: block;
      width: 100%;
      padding: 30px;
      font-size: 1.6rem; }

#partner .btn03 {
  margin-bottom: 120px; }

#partner .btn02 {
  width: 400px;
  margin: 20px auto; }

#partner .partner_flow_box {
  padding: 30px;
  border: #A3A3A3 1px solid;
  font-size: 0; }

.partner_flow {
  margin-bottom: 40px; }

.partner_left_box {
  display: block;
  width: 100%;
  margin-right: 0px;
  margin: 0 auto; }

#partner .number {
  color: #fff;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  padding-left: 0px;
  padding-top: 4px;
  font-size: 2.6rem;
  display: inline-block;
  background-color: #FF9857;
  border-radius: 50%;
  font-weight: normal; }

#partner .arrow {
  width: 100%;
  text-align: center;
  padding: 20px; }
  #partner .arrow img {
    width: 50px; }

.partner_right_box {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: #505050;
  text-align: center; }

#partner .mail {
  color: #505050;
  font-weight: bold;
  font-size: 1.3em;
  margin-left: 20px;
  text-decoration: underline; }

.partner_left_box p:nth-of-type(1) {
  color: #041442;
  width: 100%;
  height: auto;
  padding-left: 0px;
  padding-right: 30px;
  padding-top: 0px;
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  background-color: #FFF;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center; }

.partner_notice {
  font-size: 1.4rem;
  line-height: 1.5em;
  margin-bottom: 50px; }

/*ダウンロード------------------------------------------------*/
#download h2 {
  color: #041442; }

#download table {
  margin-bottom: 120px;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-all; }

.app_area {
  width: 100%;
  margin-bottom: 60px; }

.app_img {
  float: left;
  width: 20%;
  border: 1px #f0f0f0 solid;
  border-radius: 10px; }

.app_link {
  float: right;
  width: 75%; }
  .app_link h2 {
    margin-top: 5px;
    margin-bottom: 15px;
    color: #505050 !important; }
  .app_link p {
    margin-bottom: 5px;
    font-size: 1.4rem; }
  .app_link a {
    color: #041442;
    font-size: 1.4rem; }
    .app_link a:hover {
      text-decoration: underline; }

.app_left {
  float: left;
  width: 48%; }

.app_right {
  float: right;
  width: 48%; }

/*============================================================

CONTACTページ

============================================================*/
.fa-headset {
  margin-right: 10px; }

.transmission_btn {
  display: block; }

#contact .transmission {
  display: block;
  width: 280px;
  height: 50px;
  margin: 0 auto;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: #041442;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  #contact .transmission:hover {
    background-color: #ffa76e; }

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0px !important;
  margin-right: 0px; }

.mwform-checkbox-field input, .mwform-radio-field input {
  margin-right: 0px !important; }

.privacy_check {
  text-align: center;
  font-size: 1.4rem; }

.policy_link {
  display: block;
  width: 150px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 40px;
  color: #041442; }
  .policy_link:hover {
    text-decoration: underline; }

.mwform-checkbox-field-text {
  margin-left: 0px;
  margin-right: 10px; }

/*確認画面---------------------------------------------------*/
#confilm .policy_link, #confilm .privacy_check, #confilm .required {
  display: none; }

#confilm th {
  width: 200px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.4rem;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top; }

#confilm tbody {
  margin-bottom: 60px;
  display: block; }

#confilm td {
  width: 640px;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.4rem;
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top; }

#confilm .back {
  display: block;
  width: 280px;
  height: 50px;
  margin: 0 auto;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: #041442;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 100px; }

#confilm .transmission {
  display: block;
  width: 280px;
  height: 50px;
  margin: 0 auto;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  background-color: #041442;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 20px; }
  #confilm .transmission:hover {
    background-color: #ffa76e; }

#confilm .back:hover {
  background-color: #ffa76e; }

/*完了画面---------------------------------------------------*/
#complete p {
  font-size: 1.6rem;
  line-height: 3rem;
  margin-bottom: 20px;
  text-align: center; }

#complete .btn03 {
  width: 240px;
  margin-bottom: 100px;
  margin-top: 60px; }

/*============================================================

404ページ

============================================================*/
#error .txt01 {
  text-align: center;
  margin-bottom: 40px; }

#error .btn03 {
  margin-bottom: 80px; }

/*============================================================

フッター

============================================================*/
#footer {
  width: 100%;
  background-color: #041442;
  color: #fff;
  text-align: left; }
  #footer .footer_inner {
    width: 900px;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 40px; }

#footer_nav a {
  color: #fff; }

#footer_nav > ul > li {
  width: calc(15% - 20px);
  float: left;
  margin-right: 10px;
  font-size: 1.1rem;
  border-left: 2px #fff solid;
  padding-left: 14px; }
  @media screen and (max-width: 960px) {
    #footer_nav > ul > li {
      border-left: 1px #1b2c5b solid;
      width: 47%;
      margin-right: 5%;
      margin-bottom: 20px;
      padding-left: 10px; } }
  #footer_nav > ul > li > a:first-child {
    font-size: 1.9rem;
    padding-bottom: 0px;
    margin-bottom: 8px;
    display: block; }
  #footer_nav > ul > li:last-child {
    border-right: 2px #fff solid;
    padding-right: 20px;
    margin-right: 0; }

#footer_nav .sub-menu li {
  padding-bottom: 8px; }

.sub-menu a:hover {
  text-decoration: underline; }

.footer_logo {
  width: 100%;
  font-size: 3rem;
  margin-bottom: 40px; }

.footer_nav02 p {
  width: 690px;
  display: inline-block;
  font-size: 1.1rem;
  margin-top: 30px;
  color: #fff; }

.footer_nav02 ul {
  width: 200px;
  display: inline-block;
  text-align: right; }
  .footer_nav02 ul li {
    display: inline-block;
    font-size: 1.1rem;
    padding-left: 10px; }

.footer_nav02 a {
  color: #fff; }
  .footer_nav02 a:hover {
    text-decoration: underline; }

#footer p:last-child {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  background-color: #252525;
  margin: 0;
  padding: 10px 0; }

/*フッタープロダクトナビ------------------------------------------------*/
.footer_product_nav_wrap {
  width: 100%;
  background-color: #393939;
  padding: 30px 0;
  font-size: 0px;
  line-height: 1.6rem; }
  .footer_product_nav_wrap a {
    color: #fff;
    border-bottom: 1px solid #fff;
    width: 100%; }
  .footer_product_nav_wrap p {
    font-size: 3.1rem;
    color: #fff;
    margin-bottom: 30px; }
  .footer_product_nav_wrap .sub-menu a {
    color: #fff;
    font-size: 1.3rem;
    padding: 7px 0;
    border-bottom: none;
    background-image: none;
    display: block; }
    .footer_product_nav_wrap .sub-menu a:hover {
      color: #fff;
      opacity: 0.8;
      text-decoration: none; }
  .footer_product_nav_wrap .menu-item-has-children > a {
    font-size: 2rem;
    display: block;
    margin-bottom: 20px;
    padding: 0.8rem 0.8rem 14px 3.8rem;
    background-image: url("../img/cog.png");
    background-repeat: no-repeat;
    background-size: 30px; }
  .footer_product_nav_wrap .fp_nav_title {
    font-size: 1.6rem;
    color: #fff;
    border-bottom: solid 1px #777777;
    padding-bottom: 13px;
    padding-left: 32px;
    /*  background-image: url("../img/product/list/camera.svg");*/
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 0 0px;
    margin-bottom: 15px;
    padding-top: 3px; }
  .footer_product_nav_wrap #menu-item-829 > ul > li,
  .footer_product_nav_wrap #menu-item-855 > ul > li {
    display: inline-block;
    width: 140px;
    vertical-align: top;
    margin-bottom: 10px; }
  .footer_product_nav_wrap #menu-item-873 .sub-menu li,
  .footer_product_nav_wrap #menu-item-876 .sub-menu li,
  .footer_product_nav_wrap #menu-item-884 .sub-menu li,
  .footer_product_nav_wrap #menu-item-889 .sub-menu li {
    width: 140px;
    display: inline-block; }
  .footer_product_nav_wrap #menu-item-95 li {
    width: 50%;
    float: left; }

#footer_product_nav #menu-item-833 > a, #footer_product_nav #menu-item-834 > a, #footer_product_nav #menu-item-854 > a, #footer_product_nav #menu-item-864 > a, #footer_product_nav #menu-item-869 > a {
  color: #A3A3A3;
  display: block;
  font-size: 1.2rem;
  padding-bottom: 10px; }

#footer_product_nav > ul > li {
  display: inline-block;
  width: 418px;
  vertical-align: top;
  margin-bottom: 20px; }
  #footer_product_nav > ul > li:nth-of-type(4), #footer_product_nav > ul > li:nth-of-type(5), #footer_product_nav > ul > li:nth-of-type(6) {
    margin-bottom: 0px; }
  #footer_product_nav > ul > li:nth-of-type(1), #footer_product_nav > ul > li:nth-of-type(2), #footer_product_nav > ul > li:nth-of-type(4), #footer_product_nav > ul > li:nth-of-type(5) {
    margin-right: 30px; }

.footer_product_nav_wrap_sp {
  display: none; }
  @media screen and (max-width: 960px) {
    .footer_product_nav_wrap_sp {
      width: 100%;
      background-color: #393939; } }

/*============================================================

プライバシーポリシー,免責事項

============================================================*/
#privacy-policy h2 {
  color: #B16D30;
  font-size: 2rem;
  margin-bottom: 30px; }

#disclaimer h2 {
  color: #B16D30;
  font-size: 2rem;
  margin-bottom: 30px; }

#disclaimer .txt01 {
  margin-bottom: 60px; }

#privacy-policy p, #privacy-policy li {
  font-size: 1.6rem;
  line-height: 3rem;
  color: #505050; }

#disclaimer p, #disclaimer li {
  font-size: 1.6rem;
  line-height: 3rem;
  color: #505050; }

#privacy-policy li, #disclaimer li {
  text-indent: -1.6rem;
  padding-left: 1.6rem; }

#privacy-policy span {
  display: block; }

#privacy-policy ul {
  margin: 20px 0; }

#disclaimer ul {
  margin: 20px 0; }
  #disclaimer ul li ul {
    margin: 5px 0; }
    #disclaimer ul li ul li {
      text-indent: 0rem;
      padding-left: 0rem; }

#disclaimer .enactment {
  text-align: right;
  margin: 40px 0 100px 0; }

#single_product .strong {
  margin-top: 20px; }
  #single_product .strong p {
    font-size: 1.6rem;
    margin-bottom: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    background-color: #707070; }
  #single_product .strong li {
    font-size: 1.4rem;
    color: #707070;
    line-height: 2.8rem; }

.notcamera {
  margin-bottom: -80px !important; }

.sp {
  display: none !important; }

.pc {
  display: block !important; }

@media screen and (max-width: 1000px) {
  #main_img img {
    width: 100%;
    height: 383px;
    -o-object-fit: cover;
    object-fit: cover; } }

@media screen and (max-width: 960px) {
  .pc_inner, .inner {
    width: 90%; }
  .pc {
    display: none !important; }
  .sp {
    display: block !important; }
  .inner02, .inner03 {
    width: 90%;
    padding-top: 40px; }
  .inner04, .inner05 {
    width: 90%; }
  .container {
    padding-top: 65px; }
  .mb120 {
    margin-bottom: 60px; }
  .mb80 {
    margin-bottom: 40px; }
  /*============================================================

  ヘッダー

  ============================================================*/
  /*グローバルメニュー----------------------------------------------*/
  #responsive-menu-container {
    margin-top: 65px !important;
    background-color: rgba(0, 0, 0, 0.7) !important; }
  #responsive-menu .subtitle {
    display: none; }
  #responsive-menu li {
    background-color: #A3A3A3 !important; }
  .responsive-menu-item-link {
    cursor: pointer;
    top: auto !important; }
  #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow {
    line-height: 63px !important;
    padding-right: 35px; }
  .responsive-menu-item-link .title {
    font-size: 2rem; }
  button#responsive-menu-button {
    width: 61px !important;
    height: 61px !important;
    top: 0px !important;
    right: 0px !important; }
  #logo .inner {
    width: 100%; }
  .logo_wrap, .partner_link_tb {
    display: none; }
  #responsive-menu-container #responsive-menu li.responsive-menu-current-item > .responsive-menu-item-link, #responsive-menu-container #responsive-menu li.responsive-menu-item a {
    border-color: #505050 !important;
    vertical-align: top; }
  #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item .responsive-menu-item-link {
    font-size: 16px;
    background-color: #2f2f2f; }
  #responsive-menu-container li.responsive-menu-item a {
    padding-left: 25px !important; }
  #responsive-menu-container #responsive-menu-item-1453 a {
    padding-left: 65px !important;
    background-color: #3B3B3B !important;
    font-size: 1.4rem !important;
    background-image: url("../img/shake_hands.svg");
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: 18px 12px; }
  #footer_nav > ul > li:last-child {
    border-right: none;
    padding-right: 20px;
    margin-right: 100%; }
  /*============================================================

  ナビ検索バー

  ============================================================*/
  .product_nav_searchbox {
    display: inline-table;
    width: 90%;
    height: auto;
    font-size: 1.3rem;
    color: #fff;
    vertical-align: top;
    padding: 15px;
    position: relative; }
  /*============================================================

  下層ページナビ

  ============================================================*/
  .about_nav, .solution_nav, .product_nav {
    width: 100%;
    background-color: #5D5D5D;
    position: relative; }
  .about_nav ul, .solution_nav ul, .product_nav ul {
    width: 100%;
    margin: 0; }
  .about_nav ul li {
    border-right: solid 1px #707070 !important;
    display: inline-block;
    vertical-align: middle;
    border: none;
    width: 50%;
    height: 60px;
    font-size: 1.3rem;
    color: #fff;
    font-weight: bold;
    line-height: 2rem;
    overflow: hidden;
    border-bottom: 2px solid #5D5D5D; }
    .about_nav ul li:nth-of-type(2n) {
      border-right: none; }
    .about_nav ul li:nth-of-type(1), .about_nav ul li:nth-of-type(2) {
      border-bottom: solid 1px #707070 !important; }
  .solution_nav ul li, .product_nav ul li {
    display: inline-block;
    vertical-align: middle;
    border: none;
    width: 50%;
    height: 60px;
    font-size: 1.3rem;
    color: #fff;
    font-weight: bold;
    line-height: 2rem;
    overflow: hidden;
    border-bottom: 2px solid #5D5D5D; }
  .about_nav ul li:hover,
  .solution_nav ul li:hover {
    background-color: #464646; }
  .product_nav ul li {
    width: 33.3%; }
    .product_nav ul li:hover {
      background-color: #464646; }
    .product_nav ul li:nth-of-type(1), .product_nav ul li:nth-of-type(2), .product_nav ul li:nth-of-type(3), .product_nav ul li:nth-of-type(4), .product_nav ul li:nth-of-type(5), .product_nav ul li:nth-of-type(6) {
      border-bottom: solid 1px #707070; }
    .product_nav ul li:nth-of-type(1), .product_nav ul li:nth-of-type(2), .product_nav ul li:nth-of-type(4), .product_nav ul li:nth-of-type(5) {
      border-right: solid 1px #707070; }
    .product_nav ul li:nth-of-type(7) {
      border-bottom: none; }
  /*  .product_nav ul li:nth-of-type(3) a, .product_nav ul li:nth-of-type(6) a {
  padding-top: 11px;
}*/
  .about_nav ul li a, .solution_nav ul li a, .product_nav ul li a {
    display: block;
    color: #fff;
    line-height: 1.5;
    height: 100%;
    padding-top: 20px;
    /*border-bottom: 2px solid #a0a0a0;*/ }
  .tax-video_surveillance_category #menu-item-385, .tax-video_surveillance_category #menu-item-402 {
    background-color: #a0a0a0;
    border-bottom: none; }
  .tax-peripherals_category #menu-item-385, .tax-peripherals_category #menu-item-401 {
    background-color: #a0a0a0;
    border-bottom: none; }
  .tax-accessory_category #menu-item-385, .tax-accessory_category #menu-item-400 {
    background-color: #a0a0a0;
    border-bottom: none; }
  .tax-consumer_category #menu-item-402, .tax-consumer_category #menu-item-385 {
    background-color: #a0a0a0;
    border-bottom: none; }
  .tax-peripherals_category #menu-item-401, .tax-accessory_category #menu-item-400 {
    border-bottom: 1px solid #707070; }
  .tax-video_surveillance_category #menu-item-385, .tax-video_surveillance_category #menu-item-402, .tax-video_surveillance_category #menu-item-385:hover, .tax-video_surveillance_category #menu-item-402:hover {
    border-bottom: 1px solid #707070; }
  .support_nav {
    width: 100%;
    background-color: #5D5D5D;
    position: relative; }
    .support_nav ul {
      width: 100%;
      margin: 0; }
      .support_nav ul li {
        display: inline-block;
        vertical-align: middle;
        border: none;
        width: 50%;
        height: 60px;
        font-size: 1.3rem;
        color: #fff;
        font-weight: bold;
        line-height: 2rem;
        overflow: hidden; }
        .support_nav ul li:nth-of-type(1), .support_nav ul li:nth-of-type(2), .support_nav ul li:nth-of-type(3), .support_nav ul li:nth-of-type(4) {
          border-bottom: solid 1px #707070; }
        .support_nav ul li:nth-of-type(2n+1) {
          border-right: solid 1px #707070; }
        .support_nav ul li:hover {
          background-color: #a0a0a0;
          border-top: none;
          border-bottom: 1px solid #707070; }
        .support_nav ul li:nth-of-type(5) {
          border-bottom: none;
          width: 100%;
          border-right: none; }
        .support_nav ul li a {
          display: block;
          color: #fff;
          line-height: 1.5;
          height: 100%;
          padding-top: 20px; }
        .support_nav ul li:nth-of-type(4) a {
          padding-top: 18px; }
    .support_nav li:hover {
      background-color: #464646 !important; }
    .support_nav .current_page_item:hover {
      background-color: #464646; }
  #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow {
    right: 0;
    height: 50px;
    line-height: 50px;
    width: 20px;
    color: #ffffff;
    border-left: none !important;
    background-color: #212121; }
  .solution_nav li:nth-of-type(4).current-menu-item {
    border-bottom: none; }
  .solution_nav .current-menu-item {
    border-right: solid 1px #707070; }
    .solution_nav .current-menu-item:hover {
      border-bottom: 1px solid #707070;
      background-color: #464646; }
  .camera #menu-item-799, .tax-storage_device_category #menu-item-403 {
    border-bottom: 1px solid #707070;
    background-color: #a0a0a0; }
  .current_camera #menu-item-799, .current_video #menu-item-402, .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399, .current_storage_device #menu-item-403 {
    border-bottom: none;
    background-color: #a0a0a0; }
  .current_peripherals #menu-item-401, .current_accessory #menu-item-400, .current_consumer #menu-item-399 {
    border-bottom: 1px solid #707070; }
  /*============================================================

  ソート共通部分

  ============================================================*/
  #archive_news section p,
  #single_news section p,
  #archive_case section p,
  #single_case section p,
  #archive_video section p, #archive_product section p, #archive_peripherals section p, #archive_accessory section p {
    background-color: #f5f5f5;
    padding: 15px 20px;
    color: #505050;
    font-weight: bold;
    font-size: 1.4rem;
    cursor: pointer;
    position: relative;
    height: 50px; }
  .trigger01, .trigger02 {
    position: relative; }
  #archive_news section p:before, #single_news section p:before, #archive_case section p:before, #single_case section p:before, #archive_video section p:before, #archive_product section p:before, #archive_peripherals section p:before, #archive_accessory section p:before, .trigger01:before, .trigger02:before {
    display: block;
    content: "";
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    margin-top: -8px;
    background: #505050; }
  #archive_news section p:after, #single_news section p:after, #archive_case section p:after, #single_case section p:after, #archive_video section p:after, #archive_product section p:after, #archive_peripherals section p:after, #archive_accessory section p:after, .trigger01:after, .trigger02:after {
    display: block;
    content: "";
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    margin-top: -13px;
    background-color: #f5f5f5; }
  .trigger01:after, .trigger02:after {
    background-color: #dedede; }
  #archive_news section p.select_active:before, #single_news section p.select_active:before, #archive_case section p.select_active:before, #single_case section p.select_active:before, #archive_video section p.select_active:before, #archive_product section p.select_active:before, #archive_peripherals section p.select_active:before, #archive_accessory section p.select_active:before, .sp_net_typewrap p.select_active:before {
    margin-top: -2px; }
  #archive_news section p.select_active:after, #single_news section p.select_active:after, #archive_case section p.select_active:after, #single_case section p.select_active:after, #archive_video section p.select_active:after, #archive_product section p.select_active:after, #archive_peripherals section p.select_active:after, #archive_accessory section p.select_active:after, .sp_net_typewrap p.select_active:after {
    margin-top: 3px; }
  .accoedion_inner {
    font-size: 0;
    background-color: #f5f5f5; }
  #archive_case .accoedion_inner, #single_case .accoedion_inner, #archive_product .accoedion_inner, #single_product .accoedion_inner, #archive_video .accoedion_inner {
    padding-bottom: 10px;
    font-size: 0;
    width: 95%;
    margin: 0 auto; }
  #archive_case .select_area a, #single_case .select_area a, #archive_video .select_area a, #archive_peripherals .select_area a, #archive_accessory .select_area a, #archive_product .select_area a {
    font-size: 1.3rem;
    display: block;
    width: 100%;
    padding: 15px 0; }
  #archive_case .select_area li:nth-of-type(1), #single_case .select_area li:nth-of-type(1), #archive_video .select_area li:nth-of-type(1), #archive_product .select_area li:nth-of-type(1), #archive_peripherals .select_area li:nth-of-type(1), #archive_accessory .select_area li:nth-of-type(1) {
    margin-right: 0px;
    border-right: none; }
  #archive_news section li, #single_news section li, #archive_video section li, #archive_product section li, #archive_peripherals section li, #archive_accessory section li {
    background-color: #f5f5f5;
    padding: 8px 24px;
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    border: none;
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold; }
  #archive_case section li, #single_case section li {
    background-color: #f5f5f5;
    padding: 8px 24px;
    display: inline-block;
    width: 33.333%;
    box-sizing: border-box;
    border: none;
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold; }
  #archive_news section li:nth-of-type(1), #single_news section li:nth-of-type(1), #archive_case section li:nth-of-type(1), #single_case section li:nth-of-type(1), #archive_video section li:nth-of-type(1), #archive_product section li:nth-of-type(1), #archive_peripherals section li:nth-of-type(1), #archive_accessory section li:nth-of-type(1) {
    width: 100%; }
  .accordion_ul .inactive {
    background-color: #f5f5f5;
    padding: 8px 10px;
    display: inline-block;
    border-radius: 15px;
    display: inline-block;
    color: #505050; }
    .accordion_ul .inactive a {
      color: #505050; }
  .accordion_ul .active, .accordion_ul .current-cat, .accordion_ul .all {
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 15px;
    display: inline-block;
    color: #505050; }
  #archive_case .select_area, #single_case .select_area, #archive_video .select_area, #archive_peripherals .select_area, #archive_accessory .select_area, #archive_product .select_area {
    height: auto; }
  #archive_case .select_area ul, #single_case .select_area ul, #archive_video .select_area ul, #archive_product .select_area ul, #archive_peripherals .select_area ul, #archive_accessory .select_area ul {
    padding-top: 0; }
  #archive_case .select_area ul li:not(:first-child), #single_case .select_area ul li:not(:first-child), #archive_video .select_area ul li:not(:first-child), #archive_peripherals .select_area ul li:not(:first-child), #archive_accessory .select_area ul li:not(:first-child), #archive_product .select_area ul li:not(:first-child) {
    padding: 0px 0px; }
  #archive_video .select_area, #archive_product .select_area, #archive_peripherals .select_area, #archive_accessory .select_area {
    margin-bottom: 20px; }
  /*============================================================

  TOPページ

  ============================================================*/
  /*利用シーンから探す-------------------------------------------*/
  .product_box {
    margin-bottom: 30px; }
  .product_box_item {
    width: 48.5%;
    height: 325px; }
  .product_box img {
    width: 100%; }
  .product_box .txt01 {
    text-align: center; }
  .product_box .txt02 {
    text-align: center;
    padding: 0 10px; }
  /*お問い合わせ------------------------------------------------*/
  /*============================================================

  ABOUTページ

  ============================================================*/
  /*会社概要----------------------------------------------------*/
  .rbox01 {
    float: none;
    width: 100%; }
  .lbox01 {
    float: none;
    width: 100%;
    margin-bottom: 40px; }
  .message img {
    max-width: 250px;
    height: auto;
    display: block;
    margin: 0 auto; }
  /*沿革------------------------------------------------------*/
  #history .inner02 {
    width: 420px;
    padding-top: 40px; }
  #history .txt01 {
    text-align: left; }
  .flow_box:before {
    content: "";
    position: absolute;
    top: 54px;
    left: 0px;
    background-repeat: repeat-y;
    width: 0px;
    height: 0px;
    background-image: none;
    background-repeat: none;
    background-size: none; }
  .flow_box_left:before {
    content: "";
    position: absolute;
    top: 54px;
    left: 0px;
    background-repeat: repeat-y;
    width: 48px;
    height: 2154px;
    background-image: url(img/about/line.png);
    background-repeat: repeat-y;
    background-size: 50%; }
  .flow_box_item:after {
    content: "";
    position: absolute;
    top: 54px;
    left: -18%;
    background-repeat: repeat-y;
    width: 48px;
    height: 85%;
    background-image: url("../img/about/flow_icon_sp.png");
    background-repeat: no-repeat;
    background-size: 50%; }
  .flow_box img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    height: 160px;
    -o-object-fit: cover;
    object-fit: cover; }
  .flow_box_left {
    float: left;
    width: 15%;
    margin-top: 0px; }
  .flow_box_right {
    float: right;
    width: 85%; }
  .flow_box_item {
    width: 100%;
    height: 280px;
    background-color: #f5f5f5;
    margin-bottom: 41px;
    display: inline-block;
    position: relative; }
  /*受賞歴-----------------------------------------------------*/
  #awards h2 {
    font-size: 2rem;
    color: #505050;
    margin-bottom: 40px;
    text-align: center; }
  #awards .lbox01 {
    display: inline-block;
    width: 100%;
    margin-right: 0px; }
  #awards .rbox01 {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px; }
  #awards .lbox01 img, #awards .rbox01 img {
    width: 100%; }
  #awards .mb80 {
    margin-bottom: 40px; }
  .awards_h {
    color: #505050;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center; }
  .awards_txt {
    color: #505050;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 40px; }
  .awards_detail {
    padding-bottom: 40px; }
  .awards_list ul li {
    display: table;
    width: 100%;
    border-bottom: 1px solid #707070;
    padding: 20px 0; }
    .awards_list ul li p {
      display: table-cell;
      font-size: 1.2rem;
      font-weight: bold;
      color: #505050;
      width: 100%;
      vertical-align: middle; }
    .awards_list ul li:nth-of-type(2n+1) p, .awards_list ul li:nth-of-type(6) p {
      vertical-align: middle;
      padding: 0 0;
      width: 70%;
      margin-right: 2%; }
    .awards_list ul li img {
      display: table-cell;
      width: 100%; }
  /*ハンファーグループについて-------------------------------------*/
  #group .headstyle01_inner {
    font-size: 2rem; }
  #group h3 {
    font-size: 2rem;
    text-align: center; }
  #group .rbox {
    display: block;
    font-size: 1.4rem;
    color: #4C4C4E;
    width: 100%;
    line-height: 2.2rem;
    padding-left: 0px; }
  #group .lbox {
    display: inline-block;
    font-size: 1.4rem;
    color: #4C4C4E;
    width: 100%;
    vertical-align: top;
    line-height: 2.2rem;
    padding-left: 0px;
    margin-top: 40px; }
    #group .lbox img {
      display: block;
      margin: 0 auto; }
  .sales_box {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%; }
  .sales_box_item {
    color: #fff;
    width: 32%;
    height: auto;
    z-index: 1; }
    .sales_box_item p {
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
      text-align: center;
      padding-top: 50px; }
    .sales_box_item span.big {
      font-size: 5rem;
      font-weight: bold; }
    .sales_box_item span.small {
      font-size: 1.4rem;
      display: block;
      margin-top: 5px;
      line-height: 2.5rem; }
  .techwin img {
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-bottom: 40px; }
  #group .bgcolor_grey02 {
    padding: 50px 0; }
  #group .pt100 {
    padding-top: 50px; }
  #group .group_history_term .year {
    width: 150px;
    padding: 10px 3px;
    text-align: center;
    background-color: #F37321;
    font-weight: bold;
    border-radius: 20px;
    font-size: 1.4rem;
    color: #fff;
    margin: 0 auto; }
  #group .group_history_term h4 {
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    font-size: 2.2rem;
    color: #505050; }
  #group .group_history_term p {
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.6rem;
    margin-bottom: 40px; }
  #group_flow {
    margin-bottom: 60px; }
    #group_flow .group_flow01 .flow_box_left:before {
      height: 324px;
      left: -1px; }
    #group_flow .group_flow01 .flow_box:before {
      height: 183px; }
    #group_flow .group_flow02 .flow_box_left:before {
      height: 650px;
      left: -1px; }
    #group_flow .group_flow02 .flow_box:before {
      height: 504px; }
    #group_flow .group_flow03 .flow_box_left:before {
      height: 1290px;
      left: -1px; }
    #group_flow .group_flow03 .flow_box:before {
      height: 704px; }
    #group_flow .group_flow04 .flow_box_left:before {
      height: 5460px;
      left: -1px; }
    #group_flow .group_flow04 .flow_box:before {
      height: 704px; }
  #group .flow_box {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-right: 5%; }
  .flow_box_item p:nth-of-type(2), .flow_box_right p:nth-of-type(2) {
    font-size: 1.4rem;
    color: #505050;
    padding: 0px 15px; }
  /*============================================================

  PLODUCT

  ============================================================*/
  /*共通-------------------------------------------------------*/
  .product_select_wrap {
    width: 100%;
    background-color: #FFF;
    padding: 0 0;
    margin-bottom: 20px; }
  .product_select_inner {
    width: 90%;
    margin: 0 auto; }
  .analog_wrap {
    margin-bottom: 20px; }
  .sp_net_typewrap {
    width: 100%;
    cursor: pointer; }
  #tax1, #tax2, #tax3, #tax4, .nvr_wrap {
    display: none;
    width: 100%;
    margin-right: 0px;
    font-size: 0;
    background-color: #f5f5f5; }
  #tax1 li, #tax2 li, #tax3 li, #tax4 li, .nvr_wrap li {
    display: inline-block;
    width: 50%;
    margin-right: 0px;
    text-align: center;
    border-top: 1px solid #fff; }
  #tax1 li:nth-of-type(6), #tax3 li:nth-of-type(8) {
    border-bottom: 1px solid #fff; }
  #tax1 li:nth-of-type(2n+1), #tax2 li:nth-of-type(2n+1), #tax3 li:nth-of-type(2n+1), #tax4 li:nth-of-type(2n+1), .nvr_wrap li:nth-of-type(2n+1) {
    border-right: 1px solid #fff; }
  .sp_net_typewrap:nth-of-type(2) li:nth-of-type(4), #tax3 li:nth-of-type(6), #tax4 li:nth-of-type(2) {
    border-bottom: 1px solid #fff; }
  #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a, .nvr_wrap li a {
    display: block;
    padding: 10px; }
  #tax1 li a.current, #tax2 li a.current, #tax3 li a.current, #tax4 li a.current, .nvr_wrap .current-cat a {
    background-color: #F37321;
    color: #fff !important; }
  .analog_wrap, .net_wrap {
    display: block;
    width: 100%; }
  .trigger02 {
    border-top: 1px solid #fff; }
  .camera_sp_head {
    width: 100%;
    background-color: #A3A3A3;
    color: #FFFFFF;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    margin-top: 20px; }
  .sp_net_typewrap > p {
    background-color: #DEDEDE;
    font-size: 1.4rem;
    padding: 15px 20px;
    color: #505050; }
  .product_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .product_list li {
      width: 32%;
      height: auto; }
    .product_list::before {
      content: "";
      display: none;
      width: 32%;
      -webkit-box-ordinal-group: 2;
      order: 1; }
    .product_list::after {
      content: "";
      display: block;
      width: 32%; }
    .product_list h2 {
      font-size: 1.5em; }
  .archive_subtitle01 {
    font-size: 1.3em; }
  .strage_list_wrap {
    margin-bottom: 20px; }
  .wp-pagenavi .first, .wp-pagenavi .last {
    bottom: -7px; }
  /*ここから製品詳細ページ--------------------------------------*/
  .spec_a li {
    width: 21%;
    margin-bottom: 5%;
    height: 0;
    padding-bottom: 21%;
    background-color: #f5f5f5;
    border-radius: 10px;
    text-align: center; }
  .spec_a::after {
    content: "";
    display: block;
    width: 47%; }
  #single_product .subtitle {
    font-size: 1.2rem; }
  .spec_b li {
    width: 48%;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #A3A3A3;
    font-weight: bold;
    font-size: 1.7rem;
    text-align: center;
    padding: 10px 0; }
  #spec_b_on {
    background-color: #ff8c00;
    color: #ffffff; }
  #single_product .delivery_products_wrap {
    padding: 60px 0;
    padding-bottom: 80px; }
  #single_product .slick-dots {
    bottom: -45px !important; }
  #single_product .download_wrap table {
    width: 100%;
    color: #505050;
    font-size: 1.4rem; }
  #single_product .download_wrap tbody {
    border: 1px solid #CCCCCC; }
  #single_product .download_wrap table th {
    font-weight: normal;
    padding: 20px 10px;
    border-right: 1px solid #B1B1B1;
    vertical-align: middle; }
    #single_product .download_wrap table th:nth-of-type(1) {
      width: 1%; }
    #single_product .download_wrap table th:nth-of-type(2), #single_product .download_wrap table th:nth-of-type(4) {
      width: 15%; }
    #single_product .download_wrap table th:nth-of-type(5) {
      width: 17%; }
  #single_product .download_wrap table td {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    vertical-align: middle; }
  #single_product tbody tr:nth-of-type(1) {
    padding: 10px;
    background-color: #C7C6C6;
    background-color: #C7C6C6; }
  #single_product .slider {
    width: 100%; }
  #single_product .slick-list {
    width: 90%;
    margin: 0 auto; }
  .spec_a {
    margin-top: 35px; }
  .consumer_table_wrap table {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-bottom: 60px;
    margin-right: 2%; }
    .consumer_table_wrap table th {
      width: 15%; }
    .consumer_table_wrap table:nth-of-type(1) {
      margin-bottom: 10px; }
    .consumer_table_wrap table td {
      background-color: #fff; }
  /*============================================================

  SOLUTION

  ============================================================*/
  /*選ばれる理由------------------------------------------------*/
  .reason_box {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px; }
  .reason_box_item {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-bottom: 18px;
    color: #fff;
    width: 30%;
    margin-bottom: 40px; }
    .reason_box_item img {
      margin: 0 auto;
      margin-bottom: 10px;
      max-width: 100px; }
    .reason_box_item span {
      color: #F37321;
      font-weight: bold;
      font-size: 1.6rem;
      text-align: center; }
  /*製品情報一覧-----------------------------*/
  #product .bgcolor_grey01 {
    background-color: #fff;
    width: 100%; }
  #product .mb70 {
    margin-bottom: 0; }
  #x_series, #p_series, #q_series, #t_series, #for_home {
    margin-top: -65px;
    padding-top: 65px; }
  #product .eye_catch {
    width: 100%;
    margin-bottom: 40px; }
  #product h3 {
    font-size: 2rem;
    vertical-align: top; }
  #product h4 {
    font-size: 2rem;
    color: #707070;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px; }
  .series_detail p {
    font-size: 1.4rem;
    color: #505050;
    text-align: justify;
    line-height: 2.5rem; }
  .series_block00 {
    margin-bottom: 40px; }
  .series_block01, .series_block03, .series_block05, .series_block06 {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 60px; }
  .series_block05, .series_block06, .series_block01_item p, .series_block05_item p {
    margin-bottom: 20px; }
  .series_block06_item {
    width: 30%; }
  .series_block02 {
    font-size: 0;
    margin-bottom: 40px; }
    .series_block02 .lb {
      display: inline-block;
      width: 35%;
      margin-right: 5%;
      font-size: 1.8rem; }
    .series_block02 .rb {
      display: inline-block;
      width: 60%;
      vertical-align: top; }
      .series_block02 .rb img {
        padding-top: 5px; }
  .series_block04 {
    font-size: 0; }
    .series_block04 .lb {
      display: inline-block;
      width: 28%;
      margin-right: 5%;
      font-size: 1.8rem; }
    .series_block04 .rb {
      display: inline-block;
      width: 67%;
      vertical-align: top; }
      .series_block04 .rb img {
        padding-top: 5px; }
  #p_series .detail_link {
    padding-bottom: 0px; }
  #t_series .detail_link {
    padding-top: 0px;
    margin-bottom: 45px;
    padding-bottom: 20px; }
  .img100 {
    width: 100%;
    margin-bottom: 40px; }
  .series_block05 {
    margin-bottom: 0; }
  /*無料ソフトウェア--------------------------------------------*/
  #free .flex {
    margin-bottom: 60px; }
  #free .pt80 {
    padding-top: 40px; }
  #free .flex .item:nth-of-type(1) {
    height: 100px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 20px; }
  #free .flex .item:nth-of-type(2) {
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
    color: #505050;
    padding: 0;
    line-height: 3rem; }
  #free .flex .item:nth-of-type(1) p {
    color: #fff;
    font-size: 1.8rem;
    width: 100%; }
  #free .flow img {
    margin-bottom: 40px; }
  #free .flow_sp dl {
    width: 100%; }
  #free .flow_sp dt {
    width: 100%;
    height: auto;
    display: inline-block;
    text-align: center;
    padding: 5px 0;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    background-color: #FF9857;
    border-radius: 5px;
    margin-bottom: 10px; }
  #free .flow_sp dd {
    text-align: center;
    width: 100%;
    padding-bottom: 20px 0;
    color: #505050;
    font-size: 1.4rem;
    line-height: 3rem;
    margin-bottom: 30px; }
  #free .center {
    padding-top: 20px;
    padding-bottom: 50px; }
  /*プロジェクト事例---------------------------------------------*/
  #archive_case h2 {
    font-size: 1.8rem;
    margin-bottom: 5px; }
    #archive_case h2 a {
      font-size: 1.8rem; }
  .project_list {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    .project_list li:nth-of-type(2n+1), .project_list li:nth-of-type(2n) {
      margin-bottom: 40px;
      width: 47%;
      display: -webkit-box;
      display: flex; }
    .project_list li img {
      width: 100%;
      height: auto;
      display: inline-block; }
  .case_item {
    overflow: hidden;
    background-color: #f5f5f5;
    position: relative; }
  .link_whole {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -999px;
    z-index: 2;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer; }
    .link_whole:hover {
      background-color: #fff;
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      opacity: 0.5; }
  .case_lb {
    width: 100%;
    float: none; }
  .case_rb {
    width: 100%;
    float: none;
    height: auto;
    padding: 20px; }
  .case_term a {
    color: #707070;
    font-size: 1.4rem; }
  .case_place, .case_time {
    font-size: 1.4rem; }
  #archive_case .read_txt {
    font-size: 1.4rem;
    text-align: justify; }
  .slider .slick-slide a {
    margin: 0px 0px; }
  /*============================================================

  ニュース

  ============================================================*/
  /*投稿ページ--------------------------------------------------*/
  #single_news time {
    display: inline-block;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-right: 10px; }
  #single_news .news_term {
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 20px; }
  #single_news p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 0px; }
  #single_news h1, #single_news h2 {
    font-size: 1.4rem;
    color: #B16D30;
    margin-bottom: 20px; }
  #single_news .another_link p {
    font-size: 1.8rem;
    text-decoration: underline;
    margin-bottom: 10px;
    margin: 0; }
    #single_news .another_link p:last-child {
      margin-bottom: 30px; }
    #single_news .another_link p a {
      font-size: 1.4rem; }
      #single_news .another_link p a:hover {
        color: #F37321; }
  #single_news .news_content {
    margin-bottom: 40px; }
  /*============================================================

  SUPPORT

  ============================================================*/
  /*サポート---------------------------------------------------*/
  /*FAQ---------------------------------------------------*/
  #faq .faq_box .txt01 {
    font-size: 1.1rem; }
  .faq_box_item {
    margin-bottom: 18px;
    width: 47%;
    height: 90px; }
  .faq_box img {
    width: 30px; }
  /*用語集-----------------------------------------------------*/
  #glossary .txt01 {
    margin-bottom: 60px; }
  #glossary h2 {
    color: #F37321;
    padding: 10px 0;
    font-size: 2rem;
    text-align: center;
    border-bottom: 1px #F37321 solid;
    margin-bottom: 20px; }
  .kashira_link {
    font-size: 0;
    margin-right: -2%;
    overflow: hidden;
    margin-bottom: 80px; }
  .kashira01 {
    margin-bottom: 20px; }
  .kashira_link a {
    font-size: 1.6rem;
    width: 18%;
    margin-right: 2%;
    margin-bottom: 10px; }
    .kashira_link a:hover {
      background-color: #F37321;
      color: #fff; }
  .kashira_wrap {
    margin-top: 0px; }
  .kashira {
    display: inline;
    font-size: 2rem;
    padding-top: 80px; }
  #glossary tr {
    width: 100%; }
  #glossary th {
    display: block;
    text-align: left;
    font-size: 1.4rem;
    padding: 5px 0;
    color: #D87E2E;
    padding-right: 20px;
    width: 100%; }
  #glossary td {
    display: block;
    font-size: 1.4rem;
    padding: 5px 0;
    color: #505050;
    width: 100%;
    margin-bottom: 10px; }
  #glossary table {
    margin-bottom: 60px;
    width: 100%;
    table-layout: fixed; }
  #glossary tbody {
    width: 100%; }
  /*============================================================

  CONTACT

  ============================================================*/
  #contact .phone {
    padding: 40px 0; }
    #contact .phone .txt01 {
      font-size: 1.4rem;
      line-height: 2.7rem;
      text-align: center;
      margin-bottom: 20px; }
    #contact .phone .tell {
      font-size: 3rem; }
  #contact .headstyle03 {
    margin-bottom: 20px; }
  #contact .form_area {
    padding: 40px 0; }
    #contact .form_area th {
      width: 100%;
      text-align: left;
      font-weight: normal;
      display: block;
      margin-bottom: 10px; }
  #contact .form_company, #contact .name, #contact .address, #contact .content {
    width: 100%;
    font-size: 1.4rem; }
  #contact .phone_number {
    width: 20%; }
  /*確認画面---------------------------------------------------*/
  #confilm .policy_link, #confilm .privacy_check, #confilm .required {
    display: none; }
  #confilm tbody {
    margin-bottom: 60px;
    display: table-row-group; }
  #confilm th {
    width: 20%;
    text-align: left;
    margin-right: 10%;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.4rem;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top; }
  #confilm td {
    width: 70%;
    text-align: left;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 30px;
    display: inline-block;
    vertical-align: top; }
  #confilm .back {
    display: block;
    width: 280px;
    height: 50px;
    margin: 0 auto;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    background-color: #F37321;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 100px; }
  #confilm .transmission {
    display: block;
    width: 280px;
    height: 50px;
    margin: 0 auto;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    background-color: #F37321;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 20px; }
    #confilm .transmission:hover {
      background-color: #ffa76e; }
  #confilm .back:hover {
    background-color: #ffa76e; }
  #confilm .mailform-tbl {
    width: 100%; }
  /*============================================================

  フッター

  ============================================================*/
  #footer .footer_inner {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 30px; }
  .footer_logo {
    /*width: 120px;*/
    margin-bottom: 20px;
    font-size: 2rem; }
  .footer .fa {
    font-size: initial; }
  #footer_nav > ul > li:nth-child(even) {
    margin-right: 0%; }
  .footer_nav02 {
    text-align: center; }
    .footer_nav02 p {
      width: 100%;
      display: inline-block;
      font-size: 1.1rem;
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center; }
    .footer_nav02 ul {
      width: auto; }
  .footer_product_nav_wrap {
    display: none; }
  #bellows-main-239 {
    display: block; }
  .footer_product_nav_wrap_sp {
    display: block; }
    .footer_product_nav_wrap_sp p {
      text-align: center;
      font-size: 2rem;
      color: #fff;
      padding-top: 20px; }
  .bellows.bellows-skin-blue-material {
    background-color: #4C4C4E !important;
    box-shadow: none !important; }
  .fp_nav_title {
    font-size: 1.6rem; }
  .bellows .bellows-nav .bellows-target:nth-of-type(1) {
    border-top: 1px solid #4c4c4c; }
  .bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target {
    border-bottom: 1px solid #818181 !important; }
  #footer .bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target {
    border-bottom: none !important; }
  .bellows-submenu .bellows-target {
    font-size: 1.6rem !important;
    color: #fff; }
  #bellows-main-239 > ul > li:nth-of-type(6) {
    border-bottom: 1px solid #A3A3A3; }
  .bellows-nav > li {
    /*    background-image: url("../img/product/list/camera.svg") !important;*/
    background-repeat: no-repeat !important;
    background-size: 35px !important;
    background-position: 0 12px !important;
    background-color: #393939 !important; }
  .bellows .bellows-nav .bellows-menu-item-has-children > .bellows-target {
    padding-right: 90px;
    padding-left: 50px; }
  .bellows .bellows-nav .bellows-subtoggle {
    height: 100%;
    width: 100% !important; }
  .fa-chevron-down:before, .fa-chevron-up:before {
    font-size: 1.6rem;
    color: #CCCCCC; }
  .bellows .bellows-nav .bellows-subtoggle .fa {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 15px !important;
    left: auto !important;
    width: 16px !important;
    margin-top: -7px !important; }
  .bellows .bellows-nav .bellows-submenu .bellows-subtoggle .fa {
    margin-top: -10px !important; }
  .bellows .bellows-nav .bellows-submenu .bellows-item-level-2 .bellows-target {
    padding-left: 0px !important; }
  a.bellows-target {
    height: 60px !important; }
  .bellows-custom-content.bellows-custom-content-padded {
    padding: 0px !important; }
  .bellows .bellows-nav .bellows-target {
    padding: 0px !important; }
    .bellows .bellows-nav .bellows-target.bellows-target-w-image > .bellows-image.bellows-image-padded, .bellows .bellows-nav .bellows-target.bellows-target-w-image > .bellows-target-text {
      padding: 0px !important; }
    .bellows .bellows-nav .bellows-target > .bellows-target-text {
      padding: 19px 0 !important;
      padding-left: 50px !important; }
  /*============================================================

  プライバシーポリシー,免責事項

  ============================================================*/
  #privacy-policy h2, #disclaimer h2 {
    color: #B16D30;
    font-size: 2rem;
    margin-bottom: 30px; }
  #privacy-policy p, #privacy-policy li {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #505050; }
  #disclaimer p, #disclaimer li {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #505050; }
  #privacy-policy li, #disclaimer li {
    text-indent: -1.4rem;
    padding-left: 1.4rem; }
  #privacy-policy ul {
    margin: 20px 0; }
  #privacy-policy .mb60 {
    margin-bottom: 30px; }
  #disclaimer .mb60 {
    margin-bottom: 30px; }
  #disclaimer .mb120 {
    margin-bottom: 30px;
    margin-bottom: 50px; }
  #single_product .strong {
    margin-top: 20px;
    text-align: left; }
    #single_product .strong p {
      font-size: 1.6rem;
      margin-bottom: 20px;
      display: inline-block;
      padding: 5px 10px;
      color: #fff;
      font-weight: bold;
      background-color: #707070; }
    #single_product .strong li {
      font-size: 1.2rem;
      text-align: left;
      color: #707070;
      line-height: 2.4rem; }
  .notcamera {
    margin-bottom: -60px !important; } }

@media screen and (max-width: 770px) {
  .product_detail table.table-left {
    width: 100%; }
  .product_detail table.table-right {
    width: 100%; }
    .product_detail table.table-right th:first-of-type, .product_detail table.table-right td:first-of-type {
      border-top: 0; }
  .product_detail .catalog, .product_detail .btn01 {
    width: 80%;
    height: 5rem;
    font-size: 1.5rem; } }

@media screen and (max-width: 640px) {
  .top_news h2 {
    font-size: 1.7rem;
    font-weight: normal; }
  .product_list li img .thumb_inner {
    width: 50%;
    padding-top: 0px; }
  .product_list h2 {
    margin-top: 5px; }
  .archive_subtitle01 {
    margin-bottom: 5px; }
  .product_new {
    height: 25px;
    width: 40px;
    font-size: 0.9em; } }

@media screen and (max-width: 360px) {
  .product_list li .thumb_inner {
    height: 50%;
    padding-top: 0px;
    margin-bottom: 10px; }
  .product_list h2 {
    margin-bottom: 0px; } }

.product_new {
  height: 22px;
  width: 32px;
  font-size: 0.9em;
  padding-top: 3px; }

@media screen and (max-width: 600px) {
  .mb80 {
    margin-bottom: 40px; }
  #top .mb80 {
    margin-bottom: 60px; }
  .mb40 {
    margin-bottom: 20px; }
  .pb80 {
    padding-bottom: 0px; }
  .headstyle03 {
    font-size: 2rem; }
  .txt01 {
    font-size: 1.4rem;
    line-height: 2.4rem; }
  #awards .txt02 {
    font-size: 1.2rem;
    line-height: 2.4rem; }
  .inner05 {
    width: 100%; }
  /*============================================================

  ヘッダー

  ============================================================*/
  /*============================================================

  下層ページナビ

  ============================================================*/
  /*============================================================

  TOPページ

  ============================================================*/
  /*新着ニュース------------------------------------------------*/
  .top_news_right_container {
    display: block;
    overflow: auto;
    padding-bottom: 10px; }
  .top_slider_dots {
    display: none !important; }
  /*製品情報一覧-------------------------------------------*/
  #top .page_product_box_slider {
    margin-top: 20px;
    margin-bottom: 40px; }
  #top .ChangeElem_Panel {
    visibility: hidden;
    position: absolute;
    overflow: hidden; }
    #top .ChangeElem_Panel.is-show {
      visibility: visible;
      position: relative;
      overflow: visible; }
  #top .slick-list {
    width: 400px;
    margin: auto; }
  .slick-prev {
    left: 0 !important; }
  .slick-next {
    right: 0 !important; }
  .slick-prev, .slick-next {
    position: absolute !important;
    display: block !important;
    width: 35px !important;
    height: auto !important;
    transform: translate(0, 0) !important;
    background: #d9d9d9 !important;
    top: 0 !important;
    bottom: 0 !important;
    z-index: 100; }
  .top_product_pc {
    display: none; }
  .top_product_sp {
    display: block; }
  /*ソリューション-------------------------------------------*/
  .top_solution_wrap {
    padding: 40px 0; }
  .solution_box_item {
    width: 100%; }
    .solution_box_item:nth-of-type(1) {
      margin-right: 0; }
    .solution_box_item .solution_box_item .txt01 {
      font-size: 1.5rem; }
    .solution_box_item .solution_box_item .txt02 {
      font-size: 1.2rem;
      line-height: 1.5rem; }
  .home .solution_box_item .txt01 {
    font-size: 1.7rem; }
  /*============================================================

  ABOUTページ

  ============================================================*/
  /*沿革------------------------------------------------------*/
  #history .inner02 {
    width: 90%;
    margin: 0 auto;
    padding-top: 40px; }
  .flow_box_item p:nth-of-type(2), .flow_box_right p:nth-of-type(2) {
    font-size: 1.4rem; }
  /*受賞歴------------------------------------------------------*/
  #awards .rbox01 {
    width: 100%;
    font-size: 1.2rem; }
  /*ハンファーグループについて-------------------------------------*/
  .sales_box_item p {
    font-size: 2rem;
    padding-top: 20px; }
  .sales_box_item span.big {
    font-size: 3rem; }
  .sales_box_item span.small {
    font-size: 1.4rem;
    display: block;
    margin-top: 0px;
    line-height: 2.5rem;
    padding: 0 5px; }
  .award_sp_parts img {
    width: 80%;
    max-width: none; }
  #group .flow_box {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-right: 0%; }
  /*============================================================

  ソート共通部分

  ============================================================*/
  /*============================================================

  PRODUCT

  ============================================================*/
  #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a {
    font-size: 1.2rem; }
  #tax1 li a, #tax2 li a, #tax3 li a, #tax4 li a, .nvr_wrap li a {
    padding: 10px 5px; }
  /*ここから製品詳細ページ--------------------------------------*/
  .product_detail {
    margin-top: 20px;
    margin-bottom: 20px; }
    .product_detail .main img {
      width: 100%; }
    .product_detail .main .item2, .product_detail .main .item3, .product_detail .main .item4 {
      display: none; }
  .product_detail_left {
    width: 100%;
    text-align: center;
    float: none;
    margin: 0 auto; }
  .product_detail h1 {
    font-size: 2.4rem; }
  .product_detail_right {
    width: 100%; }
  .spec_a li {
    width: 30%;
    margin-bottom: 5%;
    height: 0;
    padding-bottom: 30%;
    background-color: #f5f5f5;
    border-radius: 10px;
    text-align: center; }
  .spec_a::after {
    content: "";
    display: block;
    width: 30%; }
  .spec_b {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 50px; }
    .spec_b li {
      margin-bottom: 10px;
      font-size: 1.4rem;
      text-align: center;
      padding: 7px 0;
      width: 48%; }
  #spec_b_on {
    background-color: #ff8c00;
    color: #ffffff; }
  #single_product .delivery_products_wrap {
    padding: 40px 0;
    padding-bottom: 65px; }
  #single_product .download_wrap h2, #single_product .movie_wrap h2 {
    font-size: 2rem;
    margin-bottom: 40px; }
  #single_product .slick-dots {
    bottom: -45px !important; }
  #single_product .download_wrap {
    margin: 0 0;
    margin-top: 0px; }
    #single_product .download_wrap table {
      width: 100%;
      color: #505050;
      font-size: 1.4rem; }
    #single_product .download_wrap tbody {
      border: 1px solid #CCCCCC; }
    #single_product .download_wrap table th {
      font-weight: normal;
      padding: 5px 5px;
      border-right: 1px solid #B1B1B1;
      font-size: 1rem;
      vertical-align: middle; }
      #single_product .download_wrap table th:nth-of-type(1) {
        width: 1%; }
      #single_product .download_wrap table th:nth-of-type(2), #single_product .download_wrap table th:nth-of-type(4) {
        width: 15%; }
      #single_product .download_wrap table th:nth-of-type(5) {
        width: 17%; }
    #single_product .download_wrap table td {
      text-align: center;
      padding: 5px;
      border-bottom: 1px solid #CCCCCC;
      border-right: 1px solid #CCCCCC;
      font-size: 1rem;
      vertical-align: middle; }
  #single_product tbody tr:nth-of-type(1) {
    padding: 10px;
    background-color: #C7C6C6;
    background-color: #C7C6C6; }
  #single_product .download_wrap table {
    margin-bottom: 20px; }
  button.download_button {
    font-size: 1.3rem;
    color: white;
    display: block;
    padding: 10px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: 15px;
    border-radius: 5px;
    width: 100%;
    margin: 0 auto; }
  #single_product .movie_wrap .video_item {
    width: 100%;
    margin: 0 auto; }
  #single_product .delivery_products_wrap, #single_product .movie_wrap, #single_product .download_wrap {
    padding: 40px 0;
    background-color: none; }
  #single_product .movie_wrap {
    padding-bottom: 30px; }
  #single_product .download_wrap {
    padding-bottom: 80px; }
  #single_product .product_detail02 .product_grey:nth-of-type(odd) {
    background-color: #f0f0f0; }
  .single-product .container {
    margin-bottom: 0px; }
  .consumer_titlebox {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #707070; }
    .consumer_titlebox li {
      font-size: 1.2rem; }
  /*============================================================

  SOLUTION

  ============================================================*/
  /*選ばれる理由------------------------------------------------*/
  .reason_box {
    width: 90%;
    margin: 0 auto; }
  .reason_box_item {
    width: 26%; }
    .reason_box_item span {
      font-size: 1.2rem;
      text-align: center; }
  #reason .headstyle03 {
    font-size: 1.8rem;
    margin-bottom: 10px; }
  #reason h3 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1.4rem; }
  #reason .txt01 {
    margin-bottom: 40px;
    font-size: 1.4rem; }
  #reason .block01, #reason .block02 {
    margin-bottom: 20px; }
  #reason .pt80 {
    padding-top: 40px; }
  #reason .mb95 {
    margin-bottom: 40px; }
  .reason_box_item img {
    margin-bottom: 10px;
    max-width: 100%; }
  /*製品情報一覧-----------------------------*/
  .page_product_box_item {
    width: 80%;
    margin: 0 auto; }
  .series_block01_item, .series_block05_item {
    width: 100%;
    margin-bottom: 40px; }
  .series_block01, .series_block03, .series_block06 {
    margin-bottom: 0px; }
  .series_block02 .lb, .series_block04 .lb {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 20px; }
  .series_block02 .rb, .series_block04 .rb {
    width: 100%; }
  .series_block03_item:nth-of-type(1), .series_block03_item:nth-of-type(2) {
    width: 100%;
    margin-bottom: 40px; }
  #t_series .series_block01 {
    margin-bottom: 20px; }
  #x_series {
    margin-top: -120px; }
  .detail_link {
    padding: 60px 0 30px 0; }
  /*プロジェクト事例---------------------------------------------*/
  .project_list li {
    margin-bottom: 20px;
    width: 100%; }
    .project_list li:nth-of-type(2n+1), .project_list li:nth-of-type(2n) {
      margin-bottom: 20px;
      width: 48%; }
  .read_txt {
    font-size: 1.4rem; }
  #single_case h1 {
    font-size: 2rem;
    color: #F37321;
    margin-top: 30px;
    margin-bottom: 5px; }
  #single_case .case_term {
    text-decoration: underline;
    margin-bottom: 15px; }
  #single_case .thumb {
    margin-bottom: 40px; }
  #single_case .read_txt {
    color: #505050;
    line-height: 2.7rem;
    margin-bottom: 60px; }
  #single_case .detail {
    margin-bottom: 40px; }
    #single_case .detail h2 {
      font-size: 1.6rem;
      color: #D87E2E;
      margin-bottom: 20px; }
    #single_case .detail p {
      font-size: 1.4rem;
      margin-bottom: 40px;
      line-height: 2.7rem;
      color: #505050; }
  #single_case .delivery_products_wrap {
    background-color: #f0f0f0;
    padding: 40px 0 70px 0; }
  .delivery_products_wrap h2 {
    font-size: 2rem;
    text-align: center;
    color: #F37321;
    margin-bottom: 40px; }
  #single_case.container {
    margin-bottom: 0; }
  .case_rb {
    padding: 12px; }
  #archive_case .read_txt {
    font-size: 1.3rem;
    text-align: justify;
    line-height: 2rem; }
  #archive_case h2 {
    margin-bottom: 4px; }
    #archive_case h2 a {
      font-size: 1.4rem;
      margin-bottom: 5px; }
  #archive_case .case_term {
    margin-bottom: 0px; }
    #archive_case .case_term a {
      font-size: 1.3rem; }
  #archive_case .case_place {
    font-size: 1.3rem;
    margin-bottom: 4px; }
  #archive_case .case_time {
    font-size: 1.3rem;
    margin-bottom: 8px; }
  #archive_case .wp-pagenavi {
    margin-top: 15px; }
  /*カルーセル---------------------------------------------*/
  /* slickの初期化完了まで非表示 */
  .slider {
    display: none; }
    .slider.slick-initialized {
      display: block; }
    .slider img {
      width: 100%;
      height: auto; }
  /* カルーセル画像 */
  .slick-dots {
    bottom: -50px; }
  .slider img {
    width: 70%;
    padding: 0 0;
    margin: 0 auto;
    display: block; }
  .relation_p {
    text-align: center;
    padding: 10px 0;
    color: #707070;
    font-size: 1.1rem;
    font-weight: bold; }
  .slider .slick-prev:before, .slider .slick-next:before {
    font-size: 1.4rem;
    color: #fff;
    padding: 0 20%; }
  .slider .slick-slide a, .slider .slick-prev, .slider .slick-next {
    height: 180px; }
  .consumer_table_wrap table th {
    width: 15%;
    font-size: 1.2rem; }
  .consumer_table_wrap table:nth-of-type(1) {
    margin-bottom: 10px; }
  .consumer_table_wrap table td {
    background-color: #fff;
    font-size: 1.2rem; }
  /*============================================================

  ニュース

  ============================================================*/
  /*============================================================

  SUPPORTページ

  ============================================================*/
  /*サポート---------------------------------------------------*/
  #support .headstyle01_inner {
    margin-bottom: 50px; }
  #support .txt01 {
    font-size: 1.4rem; }
  .support_box {
    margin-bottom: 30px; }
  .support_box_item {
    width: 100%;
    margin-bottom: 20px; }
    .support_box_item h2 {
      font-size: 2.2rem;
      height: 120px;
      line-height: 120px; }
  #support .support_box .txt01 {
    font-size: 1.2rem;
    line-height: 2.2rem; }
  .support_box .link01 {
    font-size: 1.4rem; }
  #support .btn01 {
    margin-bottom: 80px; }
  #support .tell {
    font-size: 2.8rem;
    color: #505050;
    font-weight: bold;
    text-align: center; }
  #support .time {
    font-size: 1.2rem;
    padding: 10px 0;
    color: #505050;
    text-align: center; }
  /*FAQ---------------------------------------------------*/
  #faq .txt01 {
    font-size: 1.4rem;
    margin-bottom: 30px; }
  .faq_box {
    margin-bottom: 40px; }
  #faq .faq_box .txt01 {
    font-size: 1.1rem; }
  .faq_content {
    margin-bottom: 60px; }
    .faq_content h2 {
      font-size: 1.8rem; }
  .switchbox {
    padding-top: 9px;
    width: 100%; }
    .switchbox label {
      line-height: 2.4rem;
      font-size: 1.4rem; }
    .switchbox .answer {
      font-size: 1.4rem;
      line-height: 2.4rem;
      padding-left: 25px; }
  #faq01 {
    margin-top: -100px;
    padding-top: 100px; }
  #faq02 {
    margin-top: -40px;
    padding-top: 40px; }
  /*用語集-----------------------------------------*/
  .PageTopBtn a:hover {
    text-decoration: none;
    background-color: #cecece; }
  /*============================================================

  CONTACT

  ============================================================*/
  /*確認画面---------------------------------------------------*/
  #confilm .policy_link, #confilm .privacy_check, #confilm .required {
    display: none; }
  #confilm tbody {
    margin-bottom: 60px;
    display: table-row-group; }
  #confilm th {
    width: 100%;
    text-align: left;
    margin-right: 0%;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2.4rem;
    margin-bottom: 10px; }
  #confilm td {
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 20px; }
  #confilm .back {
    width: 100%;
    color: #fff;
    margin-bottom: 40px; }
  #confilm .transmission {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px; }
    #confilm .transmission:hover {
      background-color: #ffa76e; }
  #confilm .back:hover {
    background-color: #ffa76e; }
  #confilm .mailform-tbl {
    width: 100%; }
  /*完了画面---------------------------------------------------*/
  #complete p {
    font-size: 1.4rem;
    line-height: 3rem;
    margin-bottom: 10px;
    text-align: left; }
  #complete .btn03 {
    width: 240px;
    margin-bottom: 40px;
    margin-top: 60px; }
  /*============================================================

  フッター

  ============================================================*/
  #footer p:last-child {
    line-height: 2rem;
    font-size: 1.2rem; }
  .product_list h2 {
    font-size: 3vw;
    margin-bottom: 15px; }
  .archive_subtitle01 {
    font-size: 2vw; }
  /*============================================================

  プライバシーポリシー,免責事項

  ============================================================*/
  #disclaimer .enactment {
    margin: 40px 0 40px 0; } }

.headstyleCo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100px;
  margin: 0 auto;
  background: #041442; }
  @media screen and (max-width: 600px) {
    .headstyleCo {
      width: 200px;
      height: 80px; } }
  .headstyleCo h2 {
    color: #fff;
    font-weight: 300;
    font-size: 1.6rem;
    margin: 4px 0 0 0; }
  .headstyleCo-wrap {
    width: 100%; }
  .headstyleCo-inner {
    display: block;
    color: #e7e018;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: 0.1rem; }
    @media screen and (max-width: 600px) {
      .headstyleCo-inner {
        font-size: 2rem; } }

@media screen and (max-width: 600px) {
  #company .container {
    padding: 65px 0% 0 0%;
    margin-bottom: 0; } }

.philosophy {
  padding-top: 130px;
  text-align: center; }
  @media screen and (max-width: 600px) {
    .philosophy {
      background: #FAFAFA;
      padding: 35px 6px 60px 6px;
      margin-top: 10vw;
      width: 90%;
      margin: 30px auto 0; } }
  .philosophy-txt {
    font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    color: #04003b;
    font-size: 3.125vw;
    font-weight: bold;
    line-height: 1.7;
    margin-top: 70px; }
    @media screen and (max-width: 600px) {
      .philosophy-txt {
        font-size: 4.6vw;
        line-height: 8vw;
        margin-top: 25px; } }
  .philosophy-list {
    font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    width: 55vw;
    margin: 100px auto 0;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .philosophy-list {
        width: 100%;
        margin-top: 40px;
        padding-bottom: 35px; } }
  .philosophy-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px; }
    @media screen and (max-width: 600px) {
      .philosophy-item {
        display: block;
        margin-bottom: 25px; } }
    .philosophy-item dt {
      position: relative;
      width: 35%;
      color: #505050;
      font-size: 1.8vw;
      font-weight: bold;
      text-align: left; }
      @media screen and (max-width: 600px) {
        .philosophy-item dt {
          position: relative;
          width: 100%;
          font-size: 1.6rem;
          margin-bottom: 7px; } }
      .philosophy-item dt::after {
        content: "";
        width: 95%;
        border-top: 1px solid #e0e0e0;
        position: absolute;
        z-index: 1;
        top: 51%;
        left: 0; }
        @media screen and (max-width: 600px) {
          .philosophy-item dt::after {
            border-top: none; } }
      .philosophy-item dt .mr5 {
        margin-right: 10px; }
        @media screen and (max-width: 600px) {
          .philosophy-item dt .mr5 {
            margin-right: 5px; } }
    .philosophy-item dd {
      width: 65%;
      color: #505050;
      font-size: 1vw;
      font-weight: normal;
      text-align: left; }
      @media screen and (max-width: 600px) {
        .philosophy-item dd {
          width: 100%;
          font-size: 1.5rem;
          margin: 0; } }
  .philosophy-line {
    position: relative;
    z-index: 10;
    background: #fff;
    padding-right: 5%; }
    @media screen and (max-width: 600px) {
      .philosophy-line {
        background: #FAFAFA;
        padding-right: 0; } }
  .philosophy-hr {
    width: 90%;
    height: 1px;
    background: #cccccc; }

.quality {
  background: #FAFAFA;
  text-align: center;
  margin: auto; }
  @media screen and (max-width: 600px) {
    .quality {
      padding: 0 0 40px 0; } }
  @media screen and (max-width: 600px) {
    .quality .inner {
      width: 100%; } }
  .quality-txt {
    color: #041442;
    font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    font-size: 3.125vw;
    font-weight: bold;
    line-height: 6vw;
    margin-top: 50px; }
    @media screen and (max-width: 600px) {
      .quality-txt {
        font-size: 4.6vw;
        line-height: 8vw;
        margin-top: 25px; } }
  .quality-list {
    font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    width: 95%;
    margin: 50px auto 0;
    padding: 60px;
    background: #fff;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .quality-list {
        width: 100%;
        margin-top: 40px;
        padding: 0;
        background: #FAFAFA; } }
  .quality-itemWrap {
    display: inline-block; }
  .quality-item {
    margin-bottom: 32px; }
    @media screen and (max-width: 600px) {
      .quality-item {
        margin-bottom: 24px; } }
    .quality-item:nth-last-child(1) {
      margin-bottom: 0; }
    .quality-item p {
      position: relative;
      color: #464646;
      font-size: 2.1rem;
      font-weight: bold;
      text-align: left;
      margin: 0; }
      @media screen and (max-width: 600px) {
        .quality-item p {
          font-size: 1.4rem;
          font-weight: normal; } }
      .quality-item p .mr5 {
        margin-right: 15px; }
        @media screen and (max-width: 600px) {
          .quality-item p .mr5 {
            margin-right: 5px;
            font-weight: bold; } }

/*会社概要----------------------------------------------------*/
.companyInfo .inner {
  width: 900px;
  padding-top: 0; }
  @media screen and (max-width: 960px) {
    .companyInfo .inner {
      width: 90%; } }

.company_information {
  font-size: 1.6rem;
  color: #505050; }
  .company_information img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 100px; }
  .company_information a {
    color: #505050; }
    .company_information a:hover {
      color: #041442;
      text-decoration: underline; }
  .company_information dl {
    margin-bottom: 20px;
    display: inline-block; }
    .company_information dl dt, .company_information dl dd {
      display: inline-block;
      vertical-align: top; }
    .company_information dl dt {
      color: #464646;
      font-weight: 500;
      width: 140px;
      margin-bottom: 1rem; }
      @media screen and (max-width: 600px) {
        .company_information dl dt {
          width: 25%;
          margin-bottom: 0;
          line-height: 2.4rem;
          font-size: 1.2rem; } }
    .company_information dl dd {
      font-weight: 400;
      width: 78%;
      margin-left: 0;
      margin-bottom: 1rem;
      line-height: 3rem; }
      @media screen and (max-width: 600px) {
        .company_information dl dd {
          width: 72%;
          margin-bottom: 20px;
          line-height: 2.4rem;
          font-size: 1.2rem; } }

@media screen and (max-width: 600px) {
  .message p {
    font-size: 1.4rem;
    line-height: 2.4rem; }
  #company .detail_link {
    padding: 20px 0 20px 0; } }

.access {
  background: #FAFAFA; }
  @media screen and (max-width: 600px) {
    .access {
      padding: 35px 0 70px 0; } }

#map {
  width: 100%;
  height: 400px;
  background-color: grey;
  text-align: center; }
  @media screen and (max-width: 600px) {
    #map {
      height: 150px;
      margin-bottom: 0px; } }
  #map iframe {
    margin-bottom: 25px; }

.history dt {
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 2.5rem 5%;
  background: #E2E2E2;
  border-bottom: 1px solid #C6C6C6; }
  .history dt:nth-of-type(1) {
    border-top: 1px solid #C6C6C6; }

.history dd {
  display: inline-block;
  width: 80%;
  vertical-align: top;
  padding: 2rem 3%;
  border-bottom: 1px solid #C6C6C6; }
  .history dd:nth-of-type(1) {
    border-top: 1px solid #C6C6C6; }

.history-list {
  color: #464646;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6vw; }

@media screen and (max-width: 1400px) {
  .history dt {
    padding: 2rem 3%; } }

@media screen and (max-width: 600px) {
  .history-list {
    font-size: 1.1rem;
    line-height: 2rem; }
  .history dt {
    width: 23%;
    min-height: 35px;
    padding: 0.75rem 1%;
    text-align: center; }
  .history dd {
    width: 77%;
    padding: 0.75rem 3%; } }

.strength-first {
  color: #464646;
  width: 50%;
  margin: 0 auto 5.2vw;
  font-size: 1.145vw;
  line-height: 2.5vw; }
  @media screen and (max-width: 600px) {
    .strength-first {
      margin-bottom: 9.6vw;
      font-size: 2.94vw;
      line-height: 5vw; } }

.strength-list {
  width: 100%; }
  @media screen and (max-width: 600px) {
    .strength-list {
      background: #f5f5f5;
      padding: 30px 5% 10px; } }

.strength-item {
  position: relative;
  width: 100%;
  height: 41.6vw;
  background: #FAFAFA; }
  @media screen and (max-width: 600px) {
    .strength-item {
      position: relative;
      width: 100%;
      height: auto;
      background: #FFFFFF;
      margin-bottom: 13.33vw; } }

.strength-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 41.6vw; }
  @media screen and (max-width: 600px) {
    .strength-img {
      position: relative;
      width: 100%;
      height: auto; } }
  .strength-img._even {
    left: 0; }

.strength-wrap {
  position: relative;
  top: 50%;
  width: 50%;
  text-align: left;
  transform: translateY(-50%); }
  @media screen and (max-width: 600px) {
    .strength-wrap {
      position: relative;
      top: 0;
      width: 100%;
      padding: 5vw 5.5556% 11vw;
      transform: translateY(0%); } }
  .strength-wrap._even {
    text-align: right;
    float: right; }
    @media screen and (max-width: 600px) {
      .strength-wrap._even {
        text-align: left;
        float: none; } }

.strength-head {
  display: inline-block;
  position: relative;
  font-weight: bold;
  color: #04003b;
  font-size: 2.6vw;
  line-height: 5vw;
  background: #E6E6E6;
  margin: 0;
  padding: 4px 10% 0;
  text-align: right; }
  @media screen and (max-width: 600px) {
    .strength-head {
      font-size: 4.8vw;
      line-height: 6vw;
      background: none;
      margin-bottom: 3.2vw;
      padding: 0;
      text-align: left; } }
  .strength-head::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-right: solid 2.5vw #FAFAFA;
    border-bottom: solid 5vw transparent; }
    @media screen and (max-width: 600px) {
      .strength-head::after {
        content: '';
        border: none; } }
  .strength-head + p {
    margin-top: 30px; }
    @media screen and (max-width: 960px) {
      .strength-head + p {
        margin-top: 20px; } }
    @media screen and (max-width: 600px) {
      .strength-head + p {
        margin-top: 0; } }
  .strength-head._even {
    padding-right: 10%;
    padding-left: 5%;
    text-align: left; }
    @media screen and (max-width: 600px) {
      .strength-head._even {
        padding: 0;
        display: inline; } }
    .strength-head._even::after {
      top: 0;
      left: 0;
      border: none;
      border-left: solid 2.5vw #FAFAFA;
      border-bottom: solid 5vw transparent; }
      @media screen and (max-width: 600px) {
        .strength-head._even::after {
          border: none; } }

.strength-txt {
  position: relative;
  width: 80%;
  margin: 30px auto 0;
  color: #464646;
  font-size: 1.145vw;
  line-height: 2.3vw;
  text-align: justify; }
  @media screen and (max-width: 960px) {
    .strength-txt {
      margin-top: 20px; } }
  @media screen and (max-width: 600px) {
    .strength-txt {
      width: 100%;
      margin: 0 auto;
      font-size: 2.9333vw;
      line-height: 5.5vw; } }

@media screen and (max-width: 600px) {
  .strength-num {
    color: rgba(97, 139, 173, 0.2);
    margin: 0;
    position: absolute;
    z-index: -1;
    bottom: -10px;
    right: 5.5556%;
    font-family: "Renner*", sans-serif;
    font-size: 21.33vw;
    font-weight: bold;
    font-style: italic; } }

@media screen and (max-width: 600px) {
  .strength-num._even {
    left: 0; } }

@media screen and (max-width: 600px) {
  .sp_strength-head {
    margin-bottom: 3.2vw; } }

@media screen and (max-width: 960px) {
  .strength-first {
    width: 80%; } }

.achievement-list {
  background: #f5f5f5;
  width: 100%;
  margin-top: 6vw; }

.achievement-item {
  width: 90%;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(198, 198, 198, 0.75); }
  @media screen and (max-width: 600px) {
    .achievement-item {
      padding: 40px 20px; } }
  .achievement-item:nth-last-of-type(1) {
    border-bottom: none; }

.achievement-img {
  width: 25%;
  height: auto;
  margin-right: 5%; }

.achievement-txt {
  color: #707070;
  font-size: 2rem;
  font-weight: bold;
  margin: 0; }
  @media screen and (max-width: 600px) {
    .achievement-txt {
      font-size: 1.6rem; } }

.achievement .headstyle01_inner {
  margin-bottom: 0; }

.solution {
  padding-bottom: 0;
  text-align: center; }

.solution-firstImg {
  width: 70%;
  height: auto;
  margin-bottom: 2.6vw; }
  @media screen and (max-width: 600px) {
    .solution-firstImg {
      width: 90%;
      margin-bottom: 4vw; } }

.solution-first {
  color: #464646;
  font-size: 1.145vw;
  line-height: 2.5vw;
  text-align: justify;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 5.3vw; }
  @media screen and (max-width: 600px) {
    .solution-first {
      width: 90%;
      margin-bottom: 9.6vw;
      font-size: 2.94vw;
      line-height: 5vw; } }
  .solution-first p {
    margin-bottom: 1vw; }

.solution-list {
  width: 100%; }
  @media screen and (max-width: 600px) {
    .solution-list {
      background: #f5f5f5;
      padding: 16vw 5%; } }

.solution-item {
  position: relative;
  width: 100%;
  height: 41.6vw;
  background: #FAFAFA;
  overflow: hidden; }
  @media screen and (max-width: 600px) {
    .solution-item {
      position: relative;
      width: 100%;
      height: auto;
      background: #FFFFFF; } }
  @media screen and (max-width: 600px) {
    .solution-item:nth-of-type(1) {
      margin-bottom: 13.33vw; } }

.solution-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 41.6vw; }
  @media screen and (max-width: 600px) {
    .solution-img {
      position: relative;
      width: 100%;
      height: auto; } }
  .solution-img._even {
    left: 0; }

.solution-wrap {
  position: relative;
  z-index: 9999;
  top: 50%;
  width: 48.5%;
  text-align: left;
  transform: translateY(-50%); }
  @media screen and (max-width: 600px) {
    .solution-wrap {
      overflow: hidden;
      top: 0;
      width: 100%;
      padding: 5vw 5.5556% 9vw;
      transform: translateY(0%); } }
  .solution-wrap._even {
    text-align: right;
    float: right; }
    @media screen and (max-width: 600px) {
      .solution-wrap._even {
        text-align: left;
        float: none; } }

.solution-num {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 1;
  bottom: -4vw;
  right: 0;
  font-family: "Renner*", sans-serif;
  font-size: 11vw;
  font-weight: bold;
  font-style: italic;
  margin: 0; }
  @media screen and (max-width: 600px) {
    .solution-num {
      color: rgba(192, 223, 223, 0.34);
      z-index: -1;
      bottom: -7.7vw;
      right: 0;
      font-size: 21vw; } }
  .solution-num._even {
    left: 0;
    text-align: left; }
    @media screen and (max-width: 600px) {
      .solution-num._even {
        left: 0; } }

.solution-head {
  color: #04003b;
  font-size: 2.6vw;
  font-weight: bold;
  line-height: 5vw;
  text-align: right;
  display: inline-block;
  position: relative;
  background: #E6E6E6;
  margin-bottom: 2.6vw;
  padding-left: 25%;
  padding-right: 8%; }
  @media screen and (max-width: 600px) {
    .solution-head {
      color: #fff;
      font-size: 4vw;
      line-height: 9vw;
      margin: 0;
      padding-left: 5%;
      padding-right: 5%;
      background: rgba(4, 20, 66, 0.9);
      position: absolute;
      top: 0;
      left: 0; } }
  .solution-head::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    border-right: solid 2.5vw #FAFAFA;
    border-bottom: solid 5vw transparent; }
    @media screen and (max-width: 600px) {
      .solution-head::after {
        right: -13%;
        border: none;
        border-left: solid 4.5vw #10234a;
        border-top: solid 9vw transparent; } }
  .solution-head._even {
    padding-right: 25%;
    padding-left: 8%;
    text-align: left; }
    @media screen and (max-width: 600px) {
      .solution-head._even {
        padding: 0; } }
    .solution-head._even::after {
      top: 0;
      left: 0;
      border: none;
      border-left: solid 2.5vw #FAFAFA;
      border-bottom: solid 5vw transparent; }
      @media screen and (max-width: 600px) {
        .solution-head._even::after {
          border: none; } }
  .solution-head.pc_none02 {
    display: none; }
    @media screen and (max-width: 600px) {
      .solution-head.pc_none02 {
        display: block; } }
  @media screen and (max-width: 600px) {
    .solution-head.sp_none02 {
      display: none; } }

.solution-head2,
.solution-txt {
  width: 70%;
  margin: 0 5% 0 25%;
  color: #464646; }

.solution-head2._even, .solution-txt._even {
  margin: 0 25% 0 7%; }

.solution-head2 {
  font-size: 1.5625vw;
  font-weight: bold;
  margin-bottom: 1.56vw; }
  .solution-head2._even {
    margin-bottom: 1.56vw; }

.solution-txt {
  font-size: 1vw;
  line-height: 2.3vw;
  text-align: justify; }
  @media screen and (max-width: 600px) {
    .solution-txt {
      width: 100%;
      margin: 0 auto;
      font-size: 2.9333vw;
      line-height: 5.5vw; } }
  @media screen and (max-width: 600px) {
    .solution-txt br {
      display: none; } }
  @media screen and (max-width: 600px) {
    .solution-txt._even {
      margin-left: 0; } }

.solution .btn01 {
  width: 30%;
  height: 3.7vw;
  font-size: 1.145vw;
  margin: 2.6vw 25% 0 45%; }
  .solution .btn01::after {
    font-size: 1.4vw;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  .solution .btn01._even {
    margin: 2.6vw 45% 0 25%; }

.product {
  color: #464646;
  text-align: justify; }

@media screen and (max-width: 960px) {
  .product_list img {
    width: 100%; } }

@media screen and (max-width: 770px) {
  .product_list img {
    margin-bottom: 10px; } }

@media screen and (max-width: 770px) {
  .product_list span {
    padding: 15px; } }

.product-txt {
  font-size: 1.8rem;
  line-height: 3.2rem;
  margin-bottom: 28px; }
  @media screen and (max-width: 600px) {
    .product-txt {
      font-size: 2.933vw;
      line-height: 4vw;
      margin-bottom: 2.4vw; } }

.product-firstImg {
  width: 100%;
  margin-bottom: 80px; }
  @media screen and (max-width: 600px) {
    .product-firstImg {
      margin-bottom: 7.46vw; } }

.product-feature {
  font-size: 1.8rem;
  margin-bottom: 12px; }
  @media screen and (max-width: 600px) {
    .product-feature {
      font-size: 3.2vw;
      margin-bottom: 2.4vw; } }
  .product-feature:nth-last-of-type(2) {
    margin-bottom: 80px; }
    @media screen and (max-width: 600px) {
      .product-feature:nth-last-of-type(2) {
        margin-bottom: 7.46vw; } }

.product-detail {
  font-size: 1.8rem;
  line-height: 3.2rem;
  margin-bottom: 80px; }
  @media screen and (max-width: 600px) {
    .product-detail {
      font-size: 2.93vw;
      line-height: 5vw;
      margin-bottom: 7.46vw; } }

.product-detailListWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 80px; }
  @media screen and (max-width: 600px) {
    .product-detailListWrap {
      margin-bottom: 7.46vw; } }

.product-detailList {
  width: 47%;
  color: #464646;
  font-size: 2.2rem;
  line-height: 4rem;
  text-align: left;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 600px) {
    .product-detailList {
      width: 100%;
      font-size: 2.93vw;
      line-height: 5vw; } }
  @media screen and (max-width: 600px) {
    .product-detailList:last-of-type dt, .product-detailList:last-of-type dd {
      border-top: none; } }
  .product-detailList dt {
    display: inline-block;
    width: 40%;
    min-height: 90px;
    vertical-align: top;
    padding: 2.5rem 5.5%;
    background: #E2E2E2;
    border-bottom: 1px solid #C6C6C6; }
    @media screen and (max-width: 600px) {
      .product-detailList dt {
        padding: 3vw 5%;
        min-height: fit-content; } }
    .product-detailList dt:nth-of-type(1) {
      border-top: 1px solid #C6C6C6; }
  .product-detailList dd {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    padding: 2rem 3%;
    border-bottom: 1px solid #C6C6C6; }
    @media screen and (max-width: 600px) {
      .product-detailList dd {
        padding: 3vw 3%; } }
    .product-detailList dd:nth-of-type(1) {
      border-top: 1px solid #C6C6C6; }

.product-sliderItem {
  width: 90%; }
  .product-sliderItem img {
    width: 100%;
    height: auto; }

#recruit .main_img {
  display: block; }

.headstyle06-en {
  position: relative;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  color: #25B2DB;
  font-size: 6.4rem;
  font-weight: bold;
  line-height: 9rem;
  margin: 0 0 0 1.4%;
  z-index: 1; }
  @media screen and (max-width: 960px) {
    .headstyle06-en {
      font-size: 6vw;
      line-height: 7.5vw; } }
  @media screen and (max-width: 600px) {
    .headstyle06-en {
      font-size: 11.2vw;
      line-height: 15.2vw; } }
  .headstyle06-en::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5%;
    background: #feec01;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    z-index: -1; }
  .headstyle06-en::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -5%;
    background: #feec01;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    z-index: -1; }
  @media screen and (max-width: 960px) {
    .headstyle06-en::before, .headstyle06-en::after {
      width: 3.4vw;
      height: 3.4vw; } }
  @media screen and (max-width: 600px) {
    .headstyle06-en::before, .headstyle06-en::after {
      width: 7.15vw;
      height: 7.15vw; } }

.headstyle06-jp {
  color: #464646;
  font-weight: normal;
  font-size: 1.6rem;
  margin: 0;
  padding-left: 30px; }

.slogan {
  padding: 110px 0 50px; }
  @media screen and (max-width: 960px) {
    .slogan {
      padding: 60px 0 50px; } }
  @media screen and (max-width: 600px) {
    .slogan {
      padding: 13.6vw 0 13.6vw 0; } }
  .slogan .headstyle06 {
    margin-bottom: 60px; }
    @media screen and (max-width: 600px) {
      .slogan .headstyle06 {
        margin-bottom: 5vw; } }
  .slogan-img {
    width: 100%;
    height: auto;
    margin-bottom: 70px; }
    @media screen and (max-width: 600px) {
      .slogan-img {
        margin-bottom: 2.935vw; } }
  .slogan-txt {
    font-size: 5rem;
    color: #25B2DB;
    background: rgba(37, 178, 219, 0.15);
    line-height: 9rem;
    padding-left: 3%;
    margin-bottom: 30px; }
    @media screen and (max-width: 600px) {
      .slogan-txt {
        font-size: 8vw;
        line-height: 14.13vw;
        margin-bottom: 2.24vw; } }
    .slogan-txt:last-of-type {
      margin-bottom: 0; }

.value {
  background: rgba(37, 178, 219, 0.15);
  padding: 70px 0 80px 0; }
  @media screen and (max-width: 600px) {
    .value {
      padding: 10.67vw 0; } }
  .value-first {
    color: #464646;
    font-size: 1.8rem;
    line-height: 2.4;
    font-weight: 600;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .value-first {
        font-size: 1.9rem;
        line-height: 2;
        margin-bottom: 50px; } }
    .value-first br {
      display: none; }
      .value-first br.pc {
        display: block; }
        @media screen and (max-width: 600px) {
          .value-first br.pc {
            display: none; } }
      @media screen and (max-width: 600px) {
        .value-first br {
          display: block; } }
  .value-item {
    margin-bottom: 80px; }
    @media screen and (max-width: 600px) {
      .value-item {
        margin-bottom: 8.53vw; } }
    .value-item:last-of-type {
      margin-bottom: 0; }
  .value-txt {
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: 6.5rem;
    font-weight: bold;
    color: #25B2DB;
    line-height: 9rem;
    margin-bottom: 12px; }
    @media screen and (max-width: 600px) {
      .value-txt {
        font-size: 5.53vw;
        line-height: 7.47vw;
        margin-bottom: 10px; } }
    .value-txt span {
      margin-right: 2.5%; }
  .value-detail {
    width: 70%;
    color: #464646;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 3rem;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .value-detail {
        width: 100%;
        font-size: 1.4rem; } }

.interview {
  background: #FAFAFA;
  padding: 100px 0; }
  @media screen and (max-width: 600px) {
    .interview {
      height: auto;
      padding: 14.93vw 0 21.33vw 0; } }
  .interview-first {
    font-size: 1.6rem;
    color: #464646;
    line-height: 3rem; }
    @media screen and (max-width: 600px) {
      .interview-first {
        font-size: 1.4rem; } }
    @media screen and (max-width: 600px) {
      .interview-first br {
        display: none; } }
  .interview-list {
    width: 100%;
    padding: 7.14% 0;
    background: linear-gradient(#FAFAFA 50%, rgba(37, 178, 219, 0.1) 50%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 600px) {
      .interview-list {
        background: none;
        padding-bottom: 0; } }
  .interview-item {
    width: 28%;
    position: relative; }
    @media screen and (max-width: 600px) {
      .interview-item {
        width: 100%;
        margin-bottom: 12.8%; } }
    @media screen and (max-width: 600px) {
      .interview-item:last-of-type {
        margin-bottom: 0; } }
  .interview-img {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    left: 0; }
  .interview-work {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    width: 35%;
    line-height: 3.6rem;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (max-width: 600px) {
      .interview-work {
        width: 35%;
        font-size: 3.2vw;
        line-height: 7.2vw; } }
    .interview-work._or, .interview-work._bl, .interview-work._sb {
      margin: 0; }
    .interview-work._or {
      background: #eba70c; }
    .interview-work._bl {
      background: #4f5ad8; }
    .interview-work._sb {
      background: #25b2db; }
  .interview-area {
    background: #fff;
    padding: 20px; }
  .interview-since {
    font-size: 1.2rem;
    color: #707070;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .interview-since {
        font-size: 2.93vw;
        margin-bottom: 6.33%; } }
  .interview-name {
    font-size: 2.2rem;
    color: #464646;
    margin: 12px 0;
    position: relative; }
    @media screen and (max-width: 600px) {
      .interview-name {
        font-size: 5.87vw; } }
    .interview-name span {
      margin-left: 4.05%;
      position: relative;
      bottom: 0;
      font-size: 1rem;
      color: #25B2DB; }
      @media screen and (max-width: 600px) {
        .interview-name span {
          font-size: 3.2vw;
          margin-left: 4.05%; } }
  .interview-br {
    display: none; }
    @media screen and (max-width: 600px) {
      .interview-br {
        display: none; } }
  .interview-job {
    font-size: 1.6rem;
    color: #707070;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .interview-job {
        font-size: 4vw; } }
  .interview .headstyle06 {
    margin-bottom: 2.86%; }
    @media screen and (max-width: 600px) {
      .interview .headstyle06 {
        margin-bottom: 6.24vw; } }

.work {
  color: #464646;
  padding: 5.21% 0 12.5% 0; }
  @media screen and (max-width: 600px) {
    .work {
      padding: 21.33% 0; } }
  .work .headstyle06 {
    margin-bottom: 5.21%; }
    @media screen and (max-width: 600px) {
      .work .headstyle06 {
        margin-bottom: 16.53vw; } }
  .work-head {
    font-size: 2.14vw;
    text-align: center; }
    @media screen and (max-width: 600px) {
      .work-head {
        font-size: 5.87vw; } }

.welfare {
  margin-bottom: 6.77vw; }
  @media screen and (max-width: 600px) {
    .welfare {
      margin-bottom: 32vw; } }
  .welfare .work-head {
    margin-bottom: 3.125%; }
    @media screen and (max-width: 600px) {
      .welfare .work-head {
        margin: 0 auto 70px; } }

.welfare-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 1000px;
  margin: 0 auto; }
  @media screen and (max-width: 960px) {
    .welfare-list {
      width: 100%; } }
  @media screen and (max-width: 600px) {
    .welfare-list {
      flex-direction: column;
      justify-content: center; } }

.welfare-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 45%;
  margin-bottom: 3.5vw; }
  @media screen and (max-width: 600px) {
    .welfare-item {
      display: block;
      width: 100%;
      margin-bottom: 60px; } }
  @media screen and (max-width: 600px) {
    .welfare-item:last-of-type {
      margin-bottom: 0; } }

.welfare-img {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-right: 30px; }
  @media screen and (max-width: 600px) {
    .welfare-img {
      margin: auto auto 25px auto;
      width: 200px;
      display: block; } }

.welfare-txtWrap {
  flex: 1; }
  @media screen and (max-width: 600px) {
    .welfare-txtWrap {
      width: 100%;
      padding: 0 15px;
      text-align: center; } }

.welfare-ttl {
  font-size: 1.15vw;
  font-weight: bold;
  margin-bottom: 0.83vw;
  color: #464646; }
  @media screen and (max-width: 600px) {
    .welfare-ttl {
      font-size: 2.1rem;
      margin-bottom: 4.8vw;
      text-align: center; } }

.welfare-txt {
  color: #464646;
  font-size: 1.6rem;
  line-height: 1.6; }
  @media screen and (max-width: 600px) {
    .welfare-txt {
      font-size: 1.4rem;
      margin-bottom: 0; } }

.office {
  text-align: center; }
  .office-txt {
    width: 60%;
    margin: 0 auto 2.5vw;
    font-size: 1vw;
    line-height: 2.5vw;
    color: #464646; }
    @media screen and (max-width: 600px) {
      .office-txt {
        margin-bottom: 20px;
        width: 90%;
        font-size: 1.4rem;
        line-height: 5.5vw; } }
  .office-slider {
    width: 100%;
    overflow: hidden;
    padding-bottom: 100px; }
  .office-item {
    width: 40%; }
    .office-item img {
      width: 100%;
      height: atuo; }
  .office .work-head {
    margin-bottom: 1.875vw; }
    @media screen and (max-width: 600px) {
      .office .work-head {
        margin-bottom: 8vw; } }
  .office .slick-dots {
    bottom: -75px;
    position: relative; }
    @media screen and (max-width: 600px) {
      .office .slick-dots {
        display: block; } }
    .office .slick-dots li {
      margin: 0 2px; }
      .office .slick-dots li button::before {
        font-size: 16px; }
    .office .slick-dots .slick-active button::before {
      color: #F37321;
      opacity: 1; }
  .office .iner {
    margin-bottom: 60px; }

.jobs-top {
  background: rgba(37, 178, 219, 0.1);
  padding: 4.17% 0; }

.jobs-first {
  margin-top: 36px;
  font-size: 1.6rem;
  color: #464646;
  margin-bottom: 100px; }

.jobs-linkList {
  width: 90%;
  max-width: 900px;
  height: 52px;
  padding: 0 30px;
  background: #ffffff;
  margin: 0 auto; }

.jobs-linkTtl {
  float: left;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 5.2rem;
  color: #464646; }

.jobs-link {
  float: right;
  font-size: 12px;
  line-height: 5.2rem;
  color: #25B2DB;
  margin-left: 60px;
  font-weight: 600; }
  .jobs-link:hover {
    opacity: 0.5; }

.jobs-listArea {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 24px; }
  .jobs-listArea:nth-of-type(2) {
    margin-top: 130px; }
  .jobs-listArea:last-of-type {
    margin-bottom: 63px; }

.jobs-ttl {
  font-size: 3rem;
  font-weight: bold;
  color: #25B2DB;
  margin-bottom: 25px; }
  @media screen and (max-width: 600px) {
    .jobs-ttl {
      margin-bottom: 30px; } }

.jobs-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.jobs-item {
  width: 46%;
  margin-bottom: 30px; }

.jobs-img {
  width: 100%;
  height: auto; }

.jobs-ttl2 {
  font-size: 2.2rem;
  color: #25B2DB;
  margin-bottom: 18px; }

.jobs-detail {
  font-size: 1.6rem;
  line-height: 3rem;
  color: #464646; }

.jobs .btn01 {
  width: 500px;
  background: #25B2DB; }
  .jobs .btn01::after {
    -webkit-transform: none;
    transform: none; }

@media screen and (max-width: 600px) {
  .jobs-top {
    padding: 5.33vw 0; }
  .jobs-first {
    margin-top: 4.8vw;
    font-size: 3.47vw;
    margin-bottom: 10.67vw;
    font-weight: 300; }
  .jobs-linkList {
    width: 100%;
    height: 10vw;
    padding: 0 5vw; }
  .jobs-linkTtl {
    font-size: 3.47vw;
    line-height: 10vw; }
  .jobs-link {
    font-size: 2.933vw;
    line-height: 10vw;
    margin-left: 3.47vw; }
  .jobs-listArea {
    width: 90%;
    margin-bottom: 12vw; }
    .jobs-listArea:nth-of-type(2) {
      margin-top: 16vw; }
    .jobs-listArea:last-of-type {
      margin-bottom: 10.46vw; }
  .jobs-item {
    width: 100%;
    margin-bottom: 10.67vw; }
  .jobs-img {
    width: 100%;
    margin-bottom: 5.87vw; }
  .jobs-ttl2 {
    font-size: 4.27vw;
    margin-bottom: 3.47vw; }
  .jobs-detail {
    font-size: 3.2vw;
    line-height: 5vw; }
  .jobs .btn01 {
    width: 80%;
    font-size: 3.47vw;
    height: 12vw; }
    .jobs .btn01::after {
      font-size: 4.2vw; } }

.interviewWrap .pc_none03 {
  display: none; }

.interviewWrap-top {
  position: relative; }
  .interviewWrap-top img {
    position: relative;
    width: 100%;
    top: 0;
    left: 0; }

.interviewWrap-infoWrap {
  display: inline-block;
  position: absolute;
  font-weight: bold;
  height: 20.16vw;
  background: rgba(4, 20, 66, 0.76);
  top: 23.96vw;
  left: 10.84vw;
  padding-left: 4.5vw; }
  .interviewWrap-infoWrap::after {
    content: '';
    position: absolute;
    width: 5vw;
    height: 20.16vw;
    background: linear-gradient(-256deg, rgba(4, 20, 66, 0.76), rgba(4, 20, 66, 0.76) 50%, transparent 50%, transparent);
    top: 0;
    right: -5vw; }
  .interviewWrap-infoWrap._even {
    right: 0;
    left: auto;
    padding-left: 2vw;
    padding-right: 4.5vw; }
    .interviewWrap-infoWrap._even::after {
      background: linear-gradient(256deg, rgba(4, 20, 66, 0.76), rgba(4, 20, 66, 0.76) 50%, transparent 50%, transparent);
      left: -5vw; }

.interviewWrap-info {
  display: inline-block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.interviewWrap-since {
  font-size: 1.15vw;
  color: #ffffff;
  margin-bottom: 1.67vw; }

.interviewWrap-name {
  font-size: 2.86vw;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2.08vw;
  position: relative; }
  .interviewWrap-name span {
    position: relative;
    bottom: 0;
    font-size: 1.15vw;
    color: #25B2DB; }

.interviewWrap-job {
  font-size: 1.3vw;
  color: #ffffff; }

.interviewWrap-hr {
  display: none; }

.interviewWrap-bottom {
  background: #F0F0F0;
  padding: 5.21vw 0; }
  .interviewWrap-bottom .btn01 {
    width: 500px;
    background: #25B2DB; }
    .interviewWrap-bottom .btn01::after {
      transform: none; }

@media screen and (max-width: 960px) {
  .interviewWrap .pc_none03 {
    display: block; }
  .interviewWrap .sp_none03 {
    display: none; }
  .interviewWrap-infoWrap {
    height: 30.13vw;
    top: 26.67vw;
    left: 5.33vw;
    padding-left: 5.33vw; }
    .interviewWrap-infoWrap::after {
      content: '';
      position: absolute;
      width: 8vw;
      height: 30.13vw;
      top: 0;
      right: -8vw; }
    .interviewWrap-infoWrap._even {
      right: 5.33vw;
      padding-left: 2vw;
      padding-right: 5.33vw; }
      .interviewWrap-infoWrap._even::after {
        left: -8vw; }
  .interviewWrap-since {
    font-size: 2.93vw;
    margin-bottom: 3.2vw; }
  .interviewWrap-name {
    text-align: right;
    font-size: 5.33vw;
    margin-bottom: 1.5vw;
    position: relative;
    line-height: 6vw; }
    .interviewWrap-name span {
      padding-right: 3vw;
      display: inline-block;
      position: relative;
      line-height: normal;
      margin-top: 2.13vw;
      right: 0;
      font-size: 2.67vw; }
  .interviewWrap-job {
    font-size: 3.47vw; }
  .interviewWrap-hr {
    display: block;
    width: 90%;
    height: 1px;
    background: #E6E6E6;
    margin: 9.6vw auto; }
    .interviewWrap-hr:last-of-type {
      margin: 16vw auto; }
  .interviewWrap-bottom {
    padding: 8.53vw 0;
    margin-top: 40px; }
    .interviewWrap-bottom .btn01 {
      display: none; } }

.interviewContent-question {
  display: flex;
  background: rgba(37, 178, 219, 0.1);
  color: #04003b;
  font-size: 2.6vw;
  line-height: 16.67vw;
  font-weight: bold;
  height: 16.67vw;
  padding-left: 13.5vw; }
  .interviewContent-question span {
    color: #25B2DB;
    font-size: 4.69vw;
    padding-right: 60px; }

.interviewContent-answer {
  padding: 8vw 0 10vw 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between; }
  .interviewContent-answer img {
    width: 46vw; }
  .interviewContent-answer._even {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse; }

.interviewContent-aTxt {
  font-size: 1.15vw;
  line-height: 2vw;
  color: #464646;
  display: inline-block;
  padding-left: 13.5vw; }
  .interviewContent-aTxt._even {
    padding-left: 0;
    padding-right: 13.5vw; }

.interviewNav {
  width: 50%;
  margin: auto;
  margin-bottom: 3.125vw;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center; }

.interviewNav-next {
  width: 35%; }
  .interviewNav-next a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%; }
  .interviewNav-next._even a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse; }
  .interviewNav-next img {
    width: 40%;
    height: auto; }

.interviewNav-info {
  width: 55%;
  text-align: left; }
  .interviewNav-info._even {
    text-align: right; }

.interviewNav-name {
  color: #464646;
  font-weight: bold;
  font-size: 1.41vw;
  margin-bottom: 1.04vw; }

.interviewNav-work {
  color: #707070;
  font-size: 0.83vw; }

.interviewNav-center {
  width: 30%;
  text-align: center; }
  .interviewNav-center img {
    width: 14%;
    height: auto;
    margin-bottom: 0.937vw; }

.interviewNav-txt {
  color: #464646;
  width: 100%;
  font-weight: bold;
  font-size: 0.83vw; }
  .interviewNav-txt span {
    color: #25B2DB;
    font-size: 1.25vw;
    padding: 0 10%;
    position: relative;
    top: 0.1vw; }

@media screen and (max-width: 600px) {
  .bellows {
    margin: 0; } }

.bellows.bellows-skin-blue-material .bellows-nav .bellows-item-level-0.bellows-current-menu-item > .bellows-target
.bellows.bellows-skin-blue-material .bellows-nav .bellows-item-level-0.bellows-current-menu-ancestor > .bellows-target {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4rem !important; }

.bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4rem !important; }

.bellows.bellows-skin-blue-material .bellows-nav .bellows-item-level-0 > .bellows-target, .bellows.bellows-skin-blue-material .bellows-nav .bellows-item-level-0 > .bellows-custom-content {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4rem !important; }

.bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4rem !important; }
  .bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-target:hover {
    background-color: transparent !important;
    color: #fff !important;
    font-size: 1.4rem !important; }

.bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-custom-content, .bellows.bellows-skin-blue-material .bellows-nav .bellows-submenu .bellows-widget .textwidget {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4rem !important; }

.bellows-nav .bellows-menu-item.bellows-menu-item-86, .bellows-nav .bellows-menu-item.bellows-menu-item-207 {
  background-color: #2d2d2d !important; }

@media screen and (max-width: 960px) {
  .responsive-menu-open .bellows-nav {
    padding-top: 33px !important; } }

@media screen and (max-width: 600px) {
  .responsive-menu-open .bellows-nav {
    padding-top: 47px !important; } }

.bellows-nav .bellows-target > .bellows-target-title {
  /*background-image: url("../img/cog.png")!important;*/
  background-repeat: no-repeat !important;
  background-size: 30px !important;
  background-position: 2rem 1.2rem;
  padding-left: 2rem !important; }

.bellows-nav .bellows-submenu .bellows-target > .bellows-target-title {
  background-image: none !important;
  margin-left: 2rem;
  padding-left: 2rem !important; }

@media screen and (max-width: 960px) {
  .interviewContent-question {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    font-size: 4.8vw;
    font-weight: bold;
    line-height: 8vw;
    height: 37.33vw;
    padding-left: 0; }
    .interviewContent-question span {
      font-size: 9.33vw;
      font-weight: normal;
      padding-right: 0;
      line-height: normal;
      margin-bottom: 3.733vw; }
  .interviewContent-answer {
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse; }
    .interviewContent-answer._even {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .interviewContent-answer img {
      width: 100vw;
      margin-bottom: 5.73vw; }
  .interviewContent-aTxt {
    width: 90%;
    font-size: 3.1vw;
    line-height: 5vw;
    padding-left: 0;
    margin: 0 auto;
    margin-bottom: 12.8vw; }
    .interviewContent-aTxt._even {
      padding-right: 0; }
    .interviewContent-aTxt._last {
      margin-bottom: 0; }
  .interviewNav {
    width: 90%;
    margin-bottom: 0; }
  .interviewNav-next {
    width: 30%; }
    .interviewNav-next a, .interviewNav-next._even a {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
    .interviewNav-next img {
      width: 75%;
      margin-bottom: 3.2vw; }
  .interviewNav-info {
    width: 100%;
    text-align: center; }
    .interviewNav-info._even {
      text-align: center; }
  .interviewNav-name {
    font-size: 4.27vw;
    margin-bottom: 3vw; }
  .interviewNav-work {
    font-size: 2.93vw; }
  .interviewNav-center img {
    width: 20%;
    margin-top: 6vw;
    margin-bottom: 2.67vw; }
  .interviewNav-txt {
    font-size: 3.2vw; }
    .interviewNav-txt span {
      font-size: 5.07vw;
      top: 0.5vw; } }

@media screen and (max-width: 600px) {
  .sp_none02 {
    display: none; } }
