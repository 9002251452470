@charset "utf-8";

html{

    font-size                : 62.5%;
    -ms-overflow-style       : -ms-autohiding-scrollbar;
    overflow-y               : scroll;
    -webkit-text-size-adjust : 100%;
    -ms-text-size-adjust     : 100%;
    -o-text-size-adjust      : 100%;
    text-size-adjust         : 100%;
    -webkit-font-smoothing   : antialiased;
    -moz-osx-font-smoothing  : grayscale;
    font-smoothing           : antialiased;

}

body{

    font-size                : 1em;
    font-family              : $fontFamily;
    color                    : $fontColor;
    letter-spacing           : -0.001em;
    -webkit-text-size-adjust : 100%;
    font-feature-settings    : "pwid";

    overflow-x               : hidden;

    @include break-point(xl){

        width : 100%;

    }

    @include break-point(1200){

        width : 100%;

    }

    @include break-point(1000){

        width : 100%;

    }

    @include break-point(768){

        width : 100%;

    }

    @include break-point(640){

        font-size : 1.4rem;

        width     : 100%;

    }

    @include break-point(544){

        width : 100%;

    }

    @include break-point(414){

        width : 100%;

    }

    @include break-point(380){

        width     : 100%;

        font-size : 14px;
        font-size : 1.4rem;

    }

    @include break-point(360){

        width     : 100%;

    }


    html[lang="ko"] &{

        font-family : "Helvetica Neue", Helvetica, 'Noto Sans KR', Arial, sans-serif;

    }


    html[lang="zh"] &{

        font-family : "Helvetica Neue", Helvetica, 'Noto Sans SC', Arial, sans-serif;

    }

}

svg:not(:root){

    overflow : hidden;

}

form{

    .subscription &{

        text-align : center;
        position   : relative;
        margin     : 30px auto 40px;

    }

}

input{


    &[type="button"],
    &[type="reset"],
    &[type="submit"]{

        -webkit-appearance : button;

    }


    &[type="submit"]{

        border-radius : 0;
        outline       : none;

    }


    &[type="number"]{

        width : auto;

    }


    &[type="search"]{

        -webkit-appearance : textfield;

    }


    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-decoration{

        -webkit-appearance : none;

    }


    &:-webkit-autofill{

        box-shadow              : 0 0 0px 1000px #454545 inset;
        -webkit-text-fill-color : white !important;

    }

}

button{

    cursor : pointer;

}

select{

    -moz-appearance    : none;
    -webkit-appearance : none;


    &::-ms-expand{

        display : none;

    }


    &::-ms-value{

        color : currentColor;

    }

}

img{

    max-width      : 100%;
    vertical-align : bottom;
    height         : auto;


    .ie8 &{
        width : auto;
    }

}

table{

    text-align   : left;
    table-layout : fixed;
    width        : 100%;

}

*{
    -o-box-sizing  : border-box;
    -ms-box-sizing : border-box;
    box-sizing     : border-box;


    &:before, &:after{
        -o-box-sizing  : border-box;
        -ms-box-sizing : border-box;
        box-sizing     : border-box;
    }
}

h1, h2, h3, h4, h5, h6{

    line-height : 1;

}

a{
    color           : $baseColor;

    text-decoration : none;

    transition      : all 0.2s 0s ease;


    &:hover{

        color           : $hoverColor;
        text-decoration : none;

    }


    &:active,
    &:hover{

        outline : 0;

    }

}

ul,
li{

    margin          : 0;
    padding         : 0;
    list-style-type : none;

}

dd{

    margin : 0;

}

.clearfix{

    *zoom : 1;


    &::after{
        content : '';
        display : block;
        clear   : both;
    }


}

.border-box{

    box-sizing : border-box;

}

/*============================================================

全ページ共有

============================================================*/

.btn01{

    color            : #fff;
    font-size        : 2rem;
    line-height      : 1;

    display          : flex;
    align-items      : center;
    justify-content  : center;

    position         : relative;
    border-radius    : 5px;
    border           : none;
    box-shadow       : 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color : $btnColor;

    width            : 380px;
    height           : 60px;
    margin           : 0 auto;
    padding          : 0 10px;

    transition       : all 0.3s ease;

    outline          : none;


    &:hover{

        background-color : $btnHoverColor;

    }


    &::after{

        content         : ">";
        font-size       : 2.5rem;

        position        : absolute;
        top             : 0;
        right           : 10px;
        bottom          : 0;

        width           : 25px;
        height          : 25px;
        margin          : auto;

        display         : inline-flex;
        align-items     : center;
        justify-content : center;

    }


    @include break-point(640){

        font-size : 1.8rem;
        width     : 80%;
        height    : 60px;

    }

}

.btn02{

    border-radius    : 5px;
    background-color : #FFA500;
    padding          : 20px;
    text-align       : center;
    color            : white;
    font-size        : 1.6rem;
    width            : 300px;
    margin           : 0 auto;
    display          : block;
    border           : none;
    transition       : all 0.3s ease;
    outline          : none;


    &:hover{
        border-radius    : 5px;
        background-color : #ffa76e;
    }


    @include break-point(1000){

        margin-bottom : 60px;

    }

}

.btn03{

    border-radius    : 5px;
    border           : none;
    background-color : #041442;
    padding          : 20px;
    text-align       : center;
    color            : white;
    font-size        : 2rem;
    width            : 560px;
    margin           : 0 auto;
    display          : block;
    transition       : all 0.3s ease;
    outline          : none;

    @include break-point(640){

        width : 100%;

    }


    &:hover{
        background-color : #ffa76e;
    }
}

p,
time{
    color : #505050;
}

section{

    padding : 60px 0 80px;


    &.strength{
        padding-bottom : 0;
    }

}

@media screen and (max-width : 600px){
    section{
        padding : 40px 0;
    }
}

.pc_inner, .inner{
    width  : 900px;
    margin : 0 auto;
}

@media screen and (min-width : 961px) and (max-width : 1400px){
    .pc_inner, .inner{
        width  : 900px;
        margin : 0 auto;
    }
}

.inner02, .inner03{
    width       : 900px;
    margin      : 0 auto;
    padding-top : 60px;
}

.inner04{
    width  : 800px;
    margin : 0 auto;
}

.inner05{
    width  : 900px;
    margin : 0 auto;
}

@media screen and (min-width : 961px){
    .pc_none{
        display : none;
    }
}

@media screen and (min-width : 601px){
    .pc_none02{
        display : none;
    }
}

@media screen and (max-width : 960px){
    .sp_none{
        display : none;
    }
}

h1, h2, h3, h4, h5, h6{
    font-weight : bold;
}

.headstyle01{
    text-align : center;
}

.headstyle01_inner{
    display       : inline-block;
    font-size     : 3rem;
    color         : #041442;
    padding       : 0 20px 0 20px;
    border-bottom : 4px #041442 solid;
    margin-bottom : 3.125vw;
}

@media screen and (max-width : 960px){
    .headstyle01_inner{
        display       : inline-block;
        font-size     : 3rem;
        padding       : 0 20px 0 20px;
        margin-bottom : 6.25vw;
    }
}

@media screen and (max-width : 600px){
    .headstyle01_inner{
        font-size     : 2.2rem;
        padding       : 0 10px 0 10px;
        border-bottom : 2px #041442 solid;
        margin-bottom : 8vw;
    }
}

.headstyle02{
    text-align : center;
}

.headstyle02_inner{
    display       : inline-block;
    font-size     : 40px;
    color         : #041442;
    padding       : 0 20px 0 20px;
    margin-bottom : 80px;
}

.headstyle03{
    color         : #041442;
    text-align    : center;
    font-size     : 2.4rem;
    margin-bottom : 40px;
}

.headstyle04{
    color         : #041442;
    text-align    : left;
    font-size     : 4rem;
    font-weight   : bold;
    line-height   : 6.8rem;
    padding-left  : 28px;
    margin-bottom : 24px;
    border-left   : 17px solid #041442;
}

@media screen and (max-width : 1400px){
    .headstyle04{
        font-size     : 3rem;
        line-height   : 5.1rem;
        padding-left  : 20px;
        margin-bottom : 20px;
        border-left   : 14px solid #041442;
    }
}

@media screen and (max-width : 600px){
    .headstyle04{
        font-size     : 5.067vw;
        line-height   : 8vw;
        padding-left  : 3.2vw;
        margin-bottom : 2.4vw;
        border-left   : 2.9333vw solid #041442;
    }
}

.headstyle05{
    color         : #464646;
    text-align    : left;
    font-size     : 3rem;
    font-weight   : 500;
    line-height   : 5.1rem;
    padding-left  : 24px;
    margin-bottom : 28px;
    border-left   : 12px solid #041442;
}

@media screen and (max-width : 960px){
    .headstyle05{
        font-size     : 2.4rem;
        line-height   : 4rem;
        padding-left  : 16px;
        margin-bottom : 24px;
        border-left   : 10px solid #041442;
    }
}

@media screen and (max-width : 600px){
    .headstyle05{
        font-size     : 4vw;
        line-height   : 6.8vw;
        padding-left  : 2.4vw;
        margin-bottom : 2.4vw;
        border-left   : 2.1333vw solid #041442;
    }
}

.table{
    display : table;
}

.fs0{
    font-size : 0;
}

.flex{
    display           : -webkit-box;
    display           : flex;
    -webkit-box-align : stretch;
    align-items       : stretch;
    flex-wrap         : wrap;
    -webkit-box-pack  : justify;
    justify-content   : space-between;
    margin-bottom     : 40px;
}

.item{
    display    : -webkit-box;
    display    : flex;
    text-align : center;
    padding    : 20px;
    width      : 45%;
}

.container{
    padding-top : 99px;
}

#contact input:-webkit-autofill{
    box-shadow              : 0 0 0px 1000px #fff inset;
    -webkit-text-fill-color : #505050 !important;
}

.container02{
    padding-top : 180px;
}

.container03{
    padding-top : 100px;
}


.color_orange01{
    color : #041442;
}

.color_orange02{
    color : #B16D30;
}

.color_grey01{
    color : #F0F0F0;
}

.bgcolor_grey01{
    background-color : #f5f5f5;
    width            : 100%;
}

.bgcolor_grey02{
    background-color : #f5f5f5;
    width            : 100%;
    padding          : 100px 0;
}

.bgcolor_grey03{
    background-color : #f5f5f5;
}

.center{
    width   : 100%;
    margin  : 0 auto;
    display : block;
}

.center02{
    text-align : center;
}

/*マージン・パディング*/

.mt5{
    margin-top : 5px;
}

.mt10{
    margin-top : 10px;
}

.mt15{
    margin-top : 15px;
}

.mt20{
    margin-top : 20px;
}

.mt80{
    margin-top : 80px;
}

.mt100{
    margin-top : 100px;
}

.mr5{
    margin-right : 5px;
}

.mr10{
    margin-right : 10px;
}

.mr15{
    margin-right : 15px;
}

.mr20{
    margin-right : 20px;
}

.mb5{
    margin-bottom : 5px;
}

.mb10{
    margin-bottom : 10px;
}

.mb15{
    margin-bottom : 15px;
}

.mb20{
    margin-bottom : 20px;
}

.mb30{
    margin-bottom : 30px;
}

.mb40{
    margin-bottom : 40px;
}

.mb54{
    margin-bottom : 54px !important;
}

.mb70{
    margin-bottom : 70px;
}

.mb80{
    margin-bottom : 80px;
}

.mb95{
    margin-bottom : 95px;
}

.mb60{
    margin-bottom : 60px;
}

.mb120{
    margin-bottom : 120px;
}

.ml5{
    margin-left : 5px;
}

.ml10{
    margin-left : 10px;
}

.ml15{
    margin-left : 15px;
}

.ml20{
    margin-left : 20px;
}

.ml25{
    margin-left : 25px;
}

.pt5{
    padding-top : 5px;
}

.pt10{
    padding-top : 10px;
}

.pt15{
    padding-top : 15px;
}

.pt20{
    padding-top : 20px;
}

.pt40{
    padding-top : 40px;
}

.pt72{
    padding-top : 72px;
}

.pt80{
    padding-top : 80px;
}

.pt100{
    padding-top : 100px;
}

.pt120{
    padding-top : 120px;
}

.pt178{
    padding-top : 178px;
}

@media screen and (min-width : 961px) and (max-width : 1400px){
    .pt178{
        padding-top : 261px;
    }
}

.pr5{
    padding-right : 5px;
}

.pr10{
    padding-right : 10px;
}

.pr15{
    padding-right : 15px;
}

.pr20{
    padding-right : 20px;
}

.pb5{
    padding-bottom : 5px;
}

.pb10{
    padding-bottom : 10px;
}

.pb15{
    padding-bottom : 15px;
}

.pb20, .pb40{
    padding-bottom : 20px;
}

.pb80{
    padding-bottom : 80px;
}

.pl5{
    padding-left : 5px;
}

.pl10{
    padding-left : 10px;
}

.pl15{
    padding-left : 15px;
}

.pl20{
    padding-left : 20px;
}

/*フォントサイズ*/

.txt01{
    font-size   : 2.2rem;
    line-height : 3rem;
    text-align  : justify;
    color       : #505050;
    font-weight : 500;
}

/*============================================================

ヘッダー

============================================================*/

#header{
    width      : 100%;
    background : #041442;
    z-index    : 99;
    position   : fixed;
    z-index    : 99999;
}

@media screen and (max-width : 1400px){
    #header-nav li{
        font-size : 1.5rem;
    }
}
